import React from "react";
import Footer from "../../partial/footer";
import NavBar from "../../partial/navbar";

function Blogjan27(){
    return(
        <div>
            <NavBar/>
            <div class="infinite-page-wrapper" id="infinite-page-wrapper">
                <div class="infinite-blog-title-wrap  infinite-style-small">
                    <div class="infinite-header-transparent-substitute"></div>
                    <div class="infinite-blog-title-top-overlay"></div>
                    <div class="infinite-blog-title-overlay"></div>
                    <div class="infinite-blog-title-bottom-overlay"></div>
                    <div class="infinite-blog-title-container infinite-container">
                        <div class="infinite-blog-title-content infinite-item-pdlr">
                            <header class="infinite-single-article-head infinite-single-blog-title-style-1 clearfix">
                                <div class="infinite-single-article-date-wrapper  post-date updated">
                                    <div class="infinite-single-article-date-day">27</div>
                                    <div class="infinite-single-article-date-month">Jan</div>
                                </div>
                                <div class="infinite-single-article-head-right">
                                    <h1 class="infinite-single-article-title">Ever Increasing role of ORM in digital
                                        marketing</h1>
                                    <div class="infinite-blog-info-wrapper">
                                        <div
                                            class="infinite-blog-info infinite-blog-info-font infinite-blog-info-author vcard author post-author ">
                                            <span class="infinite-head"><i class="icon_documents_alt"></i></span><span
                                                class="fn"><a href="" title="Posts by admin"
                                                    rel="author">admin</a></span></div>
                                        <div
                                            class="infinite-blog-info infinite-blog-info-font infinite-blog-info-category ">
                                            <span class="infinite-head"><i class="icon_folder-alt"></i></span><a
                                                href="/digital-marketing" rel="tag">Digital
                                                Media Services</a></div>
                                        <div
                                            class="infinite-blog-info infinite-blog-info-font infinite-blog-info-comment-number ">
                                            <span class="infinite-head"><i class="icon_comment_alt"></i></span>0 </div>
                                    </div>
                                </div>
                            </header>
                        </div>
                    </div>
                </div>
                <div class="infinite-content-container infinite-container">
                    <div class=" infinite-sidebar-wrap clearfix infinite-line-height-0 infinite-sidebar-style-none">
                        <div class=" infinite-sidebar-center infinite-column-60 infinite-line-height">
                            <div class="infinite-content-wrap infinite-item-pdlr clearfix">
                                <div class="infinite-content-area">
                                    <article id="post-181"
                                        class="post-181 post type-post status-publish format-standard hentry category-digital-media-services">
                                        <div class="infinite-single-article clearfix">
                                            <div class="infinite-single-article-content">
                                                <div class="elementor-element elementor-element-ebacfb7 elementor-widget elementor-widget-text-editor nitro-offscreen"
                                                    data-id="ebacfb7" data-element_type="widget"
                                                    data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>With businesses investing rapidly in the digital marketing
                                                            realm, it is critical to understand its advantages and
                                                            disadvantages.</p>
                                                        <blockquote>
                                                            <p><br/>According to <strong><a
                                                                        href="https://313media.in/blog/viewpost.php?id=347">Dentsu
                                                                        reports</a></strong>, the digital advertising
                                                                industry experienced a growth in the market size from Rs
                                                                13,683 crore by 2019 to Rs 15,782 crore by 2020, growing
                                                                by 15.3% from the previous year. Digital media will grow
                                                                at 20% to reach a market size of Rs 18,938 crore by 2021
                                                                and with a CAGR of 22.47% to reach Rs. 23,673 crore by
                                                                2022.</p>
                                                        </blockquote>
                                                        <p>The Internet has plenty of merits and plenty of demerits.</p>
                                                        <p>With an
                                                            incredible increase in digitalisation, the engagement level
                                                            has spiked. It has open doors to both negative and positive
                                                            comments from the audience. It takes a second for one
                                                            comment or a bad review to go up online and take down your
                                                            reputation. It indeed is an arduous task to manage an
                                                            excellent reputation online.</p>
                                                        <blockquote>
                                                            <p>“It takes 20 years to build a reputation and five minutes
                                                                to ruin it. If you think about that, you’ll do things
                                                                differently.”<br />
                                                                <strong>– Warren Buffet</strong>
                                                            </p>
                                                        </blockquote>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-63dfd59 elementor-widget elementor-widget-heading nitro-offscreen"
                                                    data-id="63dfd59" data-element_type="widget"
                                                    data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <h2 id="What_is_the_role_of_ORM_in_Digital_Marketing"
                                                            class="elementor-heading-title elementor-size-default uael-toc-text">
                                                            What is the role of ORM in Digital Marketing?</h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-7b43d5e elementor-widget elementor-widget-text-editor nitro-offscreen"
                                                    data-id="7b43d5e" data-element_type="widget"
                                                    data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>ORM
                                                            is an abbreviation for <strong><br/><a
                                                                    href="https://locobuzz.com/online-reputation-management/"
                                                                    target="_blank" rel="noopener">Online Reputation
                                                                    Management</a></strong>. ORM is a mere combination
                                                            of traditional marketing, search engine marketing, and
                                                            public relations.</p>
                                                        <p><br/>Businesses,
                                                            while creating a business, have a determining motive and
                                                            perspective. When presented online against a mass
                                                            viewership, how do these viewers perceive your business is
                                                            an important question you should ask.</p>
                                                        <p>ORM alludes to a process that incorporates monitoring,
                                                            addressing, or mitigating SERPs (search engine result pages)
                                                            and comments on social media.</p>
                                                        <p>With the tremendous growth in digital marketing, it is now a
                                                            smooth process for businesses to have a decent digital
                                                            presence(Website, social media handles). Regardless, let’s
                                                            not forget the competitors who tarnish the organisation’s
                                                            reputation with negative reviews and criticism.</p>
                                                        <p>Businesses, when invested in ORM, witnessed a growth rate of
                                                            25% in sales. Therefore it is of utmost importance for
                                                            businesses to strive and protect the brand image, which can
                                                            be done by proper ORM platform in place.</p>
                                                        <p>ORM in digital marketing assists businesses in establishing
                                                            and maintaining a positive brand image on the internet. Any
                                                            critical attacks on your image are easily deflected and
                                                            minimised in prominence. If any of your personal information
                                                            is leaked online, ORM helps remove such data from public
                                                            databases, known as “people search” databases.</p>
                                                        <p>Below mentioned are the features of ORM platforms that you
                                                            should consider before investing in one:</p>
                                                        <ul>
                                                            <li>Monitor your Online Reputation in real-time</li>
                                                            <li>Detailed Analysis of your brand perception in the
                                                                community</li>
                                                            <li>Competition benchmarking abilities</li>
                                                            <li>Crisis management</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <section
                                                    class="elementor-section elementor-inner-section elementor-element elementor-element-f47807d elementor-section-boxed elementor-section-height-default nitro-offscreen"
                                                    data-id="f47807d" data-element_type="section"
                                                    data-settings="{&quot;_ha_eqh_enable&quot;:false}">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-bc68c66"
                                                            data-id="bc68c66" data-element_type="column"
                                                            data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                            <div
                                                                class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-e6e1035 elementor-widget elementor-widget-text-editor"
                                                                    data-id="e6e1035" data-element_type="widget"
                                                                    data-widget_type="text-editor.default"></div>
                                                                <div class="elementor-element elementor-element-136d2c9 elementor-grid-eael-col-3 elementor-grid-tablet-eael-col-2 elementor-grid-mobile-eael-col-1 elementor-widget elementor-widget-eael-post-grid"
                                                                    data-id="136d2c9" data-element_type="widget"
                                                                    data-settings="{&quot;eael_post_grid_columns&quot;:&quot;eael-col-3&quot;,&quot;eael_post_grid_columns_tablet&quot;:&quot;eael-col-2&quot;,&quot;eael_post_grid_columns_mobile&quot;:&quot;eael-col-1&quot;}"
                                                                    data-widget_type="eael-post-grid.default">
                                                                    <div class="elementor-widget-container"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <div class="elementor-element elementor-element-29be91b elementor-widget elementor-widget-heading nitro-offscreen"
                                                    data-id="29be91b" data-element_type="widget"
                                                    data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <h2 id="Need_for_ORM_in_digital_marketing_"
                                                            class="elementor-heading-title elementor-size-default uael-toc-text">
                                                            Need for ORM in digital marketing?</h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-13eaede elementor-widget elementor-widget-text-editor nitro-offscreen"
                                                    data-id="13eaede" data-element_type="widget"
                                                    data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>The need for ORM in your business can be narrowed down to 3
                                                            primary phases:</p>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-b8befe7 elementor-widget elementor-widget-heading nitro-offscreen"
                                                    data-id="b8befe7" data-element_type="widget"
                                                    data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <h3 id="1_The_Reputation_Management_phase"
                                                            class="elementor-heading-title elementor-size-default uael-toc-text">
                                                            1. The Reputation Management phase</h3>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-69fccd8 elementor-widget elementor-widget-text-editor nitro-offscreen"
                                                    data-id="69fccd8" data-element_type="widget"
                                                    data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>What happens after your brand is established?<br />
                                                            Though you successfully created a good reputation in the
                                                            overall market, maintaining the same is a progressive task;
                                                            this is where a well-skilled ORM team intervenes.</p>
                                                        <p>The ORM team will be adept at managing cordial relationships
                                                            with your customers and tracking your brand reputation
                                                            across search engines without any hassle.</p>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-90eb068 elementor-widget elementor-widget-heading nitro-offscreen"
                                                    data-id="90eb068" data-element_type="widget"
                                                    data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <h3 id="2_The_recovery_phase-_"
                                                            class="elementor-heading-title elementor-size-default uael-toc-text">
                                                            2. The recovery phase-</h3>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-533bab5 elementor-widget elementor-widget-text-editor nitro-offscreen"
                                                    data-id="533bab5" data-element_type="widget"
                                                    data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>Typically most businesses have encountered an irate customer
                                                            or a competitor trying to demean your business’s well-built
                                                            reputation. This can invite negative comments and reviews,
                                                            calling for an immediate amendment.</p>
                                                        <p>In the recovery phase, the ORM team dedicates their skills to
                                                            redeeming the losses incurred regarding the brand name. They
                                                            align their tasks into looking after the company’s SEOs,
                                                            social media content, and, importantly, website content
                                                            where their brand name has been tampered with.</p>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-887fb29 elementor-widget elementor-widget-heading nitro-offscreen"
                                                    data-id="887fb29" data-element_type="widget"
                                                    data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <h3 id="3_Monitoring_and_controlling_phase"
                                                            class="elementor-heading-title elementor-size-default uael-toc-text">
                                                            3. Monitoring and controlling phase</h3>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-9762309 elementor-widget elementor-widget-text-editor nitro-offscreen"
                                                    data-id="9762309" data-element_type="widget"
                                                    data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>Both large and small businesses have competitors that can
                                                            come up with threatening strategies against your business.
                                                            An ORM platform will maintain good control over threats and
                                                            malicious actions towards your brand name.</p>
                                                        <p>In order to protect your brand from getting overshadowed by
                                                            negative comments and avail many other <strong><a
                                                                    href="https://locobuzz.com/blogs/4-crucial-benefits-of-online-reputation-management/"
                                                                    target="_blank" rel="noopener">benefits ORM
                                                                    tools</a></strong> have to offer that help in
                                                            improving your social presence, customer service and even
                                                            boost sales.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div class="infinite-single-social-share infinite-item-rvpdlr">
                                    <div class="gdlr-core-social-share-item gdlr-core-item-pdb  gdlr-core-center-align gdlr-core-social-share-left-text gdlr-core-item-mglr gdlr-core-style-plain"
                                        style={{
                                            paddingBottom: '0px'
                                        }}><span
                                            class="gdlr-core-social-share-count gdlr-core-skin-title"><span
                                                class="gdlr-core-count">0</span><span
                                                class="gdlr-core-suffix">Shares</span><span
                                                class="gdlr-core-divider gdlr-core-skin-divider"></span></span><span
                                            class="gdlr-core-social-share-wrap"><a
                                                class="gdlr-core-social-share-facebook"
                                                href="https://www.facebook.com/sharer/sharer.php?caption=Ever+Increasing+role+of+ORM+in+digital+marketing&amp;u=https://lagosmart.com/ever-increasing-role-of-orm-in-digital-marketing/"
                                                target="_blank"
                                                onclick="javascript:window.open(this.href,&#039;&#039;, &#039;menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=602,width=555&#039;);return false;"><i
                                                    class="fa fa-facebook"></i></a><a
                                                class="gdlr-core-social-share-linkedin"
                                                href="http://www.linkedin.com/shareArticle?mini=true&amp;url=https://lagosmart.com/ever-increasing-role-of-orm-in-digital-marketing/&amp;title=Ever+Increasing+role+of+ORM+in+digital+marketing"
                                                target="_blank"
                                                onclick="javascript:window.open(this.href,&#039;&#039;, &#039;menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=452,width=550&#039;);return false;"><i
                                                    class="fa fa-linkedin"></i></a><a
                                                class="gdlr-core-social-share-pinterest"
                                                href="http://pinterest.com/pin/create/button/?url=https://lagosmart.com/ever-increasing-role-of-orm-in-digital-marketing/"
                                                target="_blank"
                                                onclick="javascript:window.open(this.href,&#039;&#039;, &#039;menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=553,width=750&#039;);return false;"><i
                                                    class="fa fa-pinterest-p"></i></a><a
                                                class="gdlr-core-social-share-twitter"
                                                href="https://twitter.com/intent/tweet?text=Ever+Increasing+role+of+ORM+in+digital+marketing&amp;url=https://lagosmart.com/ever-increasing-role-of-orm-in-digital-marketing/"
                                                target="_blank"
                                                onclick="javascript:window.open(this.href,&#039;&#039;, &#039;menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=255,width=555&#039;);return false;"><i
                                                    class="fa fa-twitter"></i></a></span></div>
                                </div>
                                
                                <div id="comments" class="infinite-comments-area">
                                    <div id="respond" class="comment-respond">
                                        <h4 id="reply-title" class="comment-reply-title infinite-content-font">Leave a
                                            Reply <small><a rel="nofollow" id="cancel-comment-reply-link"
                                                    href="indexe871.html?ertthndxbcvs=yes#respond"
                                                    style={{
                                                        display:'none'
                                                    }}>Cancel Reply</a></small></h4>
                                        <form action="https://lagosmart.com/wp-comments-post.php" method="post"
                                            id="commentform" class="comment-form" novalidate>
                                            <div class="comment-form-comment"><textarea id="comment" name="comment"
                                                    cols="45" rows="8" aria-required="true"
                                                    placeholder="Comment*"></textarea></div>
                                            <div class="infinite-comment-form-author"><input id="author" name="author"
                                                    type="text" value="" placeholder="Name*" size="30"
                                                    aria-required='true' /></div>
                                            <div class="infinite-comment-form-email"><input id="email" name="email"
                                                    type="text" value="" placeholder="Email*" size="30"
                                                    aria-required='true' /></div>
                                            <div class="infinite-comment-form-url"><input id="url" name="url"
                                                    type="text" value="" placeholder="Website" size="30" /></div>
                                            <div class="clear"></div>
                                            <p class="comment-form-cookies-consent"><input
                                                    id="wp-comment-cookies-consent" name="wp-comment-cookies-consent"
                                                    type="checkbox" value="yes" /><label
                                                    for="wp-comment-cookies-consent">Save my name, email, and website in
                                                    this browser for the next time I comment.</label></p>
                                            <p class="form-submit"><input name="submit" type="submit" id="submit"
                                                    class="submit" value="Post Comment" /> <input type='hidden'
                                                    name='comment_post_ID' value='181' id='comment_post_ID' />
                                                <input type='hidden' name='comment_parent' id='comment_parent'
                                                    value='0' />
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>

        </div>
    )
}

export default Blogjan27;