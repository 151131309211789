import React from "react";
import Footer from "../partial/footer";
import NavBar from "../partial/navbar";
import './ticket.css'


function Ticket(){
    return(
        <div>
            <NavBar/>
            <div class="container">
                <div class="grouptickect">
                    <div className="group-a">
                        <div class="left">
                            <div class="redline"></div>
                            <h1 class="event">Events and Concert</h1>
                            <h2>Buy and Sell Ticket</h2>
                            <p>Featuring concert tickets for top tours, festivals and more - Vivid Seats<br/> 
                            is your one-stop resource for live concert events. Find detailed <br/>seating charts and concert ...</p>
                            <a href="#" class="vmore">Get a Ticket</a>
                        </div>
                    </div>
                    <div className="group-b">
                        <div class="right">
                            <img style={{
                                width:'350px', boxShadow:'35px 35px #ffa900'
                            }} src="assets/events1.jpg" alt=""/>
                        </div>
                    </div>
                    
                </div>
                <div class="ticketgroup">
                    <div class="ticket">
                    <div class="fluid">
             {/* <h4>Upcoming Events</h4>
             <div class="card-container ">
                <div class="card">
                    <img src="assets/dig11.jpeg" alt="Image 1"/>
                    <div class="card-info">
                        <h5>IOT West Africa</h5>
                        <div class="category">
                        <p>Categories</p>
                        <div class="categorylist">
                            <p style={{color:'red', fontSize:'10px', marginLeft:'20px'}}>20k <span style={{color:'green', fontSize:'10px',marginLeft:'20px'}}>50k</span><span style={{color:'blue', fontSize:'10px',marginLeft:'20px'}}>100k</span></p>
                        </div>
                        </div>
                        <div class="button">
                            <a class="orderB" id="myButton">Order</a>
                        </div>
                        
                    
                    </div>
                </div>
                <div class="card">
                    <img src="assets/dig5.jpeg" alt="Image 2"/>
                    <div class="card-info">
                        <h5>IOT West Africa</h5>
                        <div class="category">
                        <p>Categories</p>
                        <div class="categorylist">
                            <p style={{color:'red', fontSize:'10px', marginLeft:'20px'}}>20k <span style={{color:'green', fontSize:'10px',marginLeft:'20px'}}>50k</span><span style={{color:'blue', fontSize:'10px',marginLeft:'20px'}}>100k</span></p>
                        </div>
                        </div>
                        <div class="button">
                            <a class="orderB" id="myButton">Order</a>
                        </div>
                        
                    
                    </div>
                </div>
                <div class="card">
                    <img src="assets/dig5.jpeg" alt="Image 2"/>
                    <div class="card-info">
                        <h5>IOT West Africa</h5>
                        <div class="category">
                        <p>Categories</p>
                        <div class="categorylist">
                            <p style={{color:'red', fontSize:'10px', marginLeft:'20px'}}>20k <span style={{color:'green', fontSize:'10px',marginLeft:'20px'}}>50k</span><span style={{color:'blue', fontSize:'10px',marginLeft:'20px'}}>100k</span></p>
                        </div>
                        </div>
                        <div class="button">
                            <a class="orderB" id="myButton">Order</a>
                        </div>
                        
                    
                    </div>
                </div>
                <div class="card">
                    <img src="assets/dig5.jpeg" alt="Image 2"/>
                    <div class="card-info">
                        <h5>IOT West Africa</h5>
                        <div class="category">
                        <p>Categories</p>
                        <div class="categorylist">
                            <p style={{color:'red', fontSize:'10px', marginLeft:'20px'}}>20k <span style={{color:'green', fontSize:'10px',marginLeft:'20px'}}>50k</span><span style={{color:'blue', fontSize:'10px',marginLeft:'20px'}}>100k</span></p>
                        </div>
                        </div>
                        <div class="button">
                            <a class="orderB" id="myButton">Order</a>
                        </div>
                        
                    
                    </div>
                </div>
            </div> */}
        </div>
                    </div>
                </div>
            </div>




            <Footer/>
        </div>
    )
}

export default Ticket;