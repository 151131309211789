import React from 'react'
import NavBar from '../partial/navbar';
import Footer from '../partial/footer';
import './About.css';

function About() {
  return (
    <div>
      <NavBar />

      <div class="infinite-page-wrapper" id="infinite-page-wrapper">
        <div class="gdlr-core-page-builder-body">
          <div class="gdlr-core-pbf-wrapper" style={{
            padding: '90px 0px 90px 0p'
          }} data-skin="Dark">
            <div class="gdlr-core-pbf-background-wrap">
              <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js" style={{
                backgroundImage:"url('assets/wp-content/uploads/2022/01/about-us1.jpg')",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }} data-parallax-speed="0.8"></div>
            </div>
            <div class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js">
              <div class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container">
                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-title-item
                                            gdlr-core-item-pdb clearfix
                                            gdlr-core-left-align
                                            gdlr-core-title-item-caption-bottom
                                            gdlr-core-item-pdlr">
                    <div class="gdlr-core-title-item-title-wrap">
                      <h1 class="gdlr-core-title-item-title gdlr-core-skin-title" style={{
                        fontSize: '55px',
                        fontWeight: '700'
                      }}>About Us.
                      <span class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span></h1>
                    </div>
                    <span class="gdlr-core-title-item-caption
                                                gdlr-core-info-font
                                                gdlr-core-skin-caption"style={{
                                                  fontSize: '19px'
                                                }}>Story
                      About Us
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-wrapper" style={{
            padding: '0px 0px 0px 0px'
          }}>
            <div class="gdlr-core-pbf-background-wrap"></div>
            <div class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js">
              <div class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container">
                <div class="gdlr-core-pbf-column
                                        gdlr-core-column-30
                                        gdlr-core-column-first" id="gdlr-core-column-93556">
                  <div class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js" style={{
                                              padding: '40px 40px 50px 0px'
                                            }} data-sync-height="height-1" data-sync-height-center>
                    <div class="gdlr-core-pbf-background-wrap"></div>
                    <div class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content" data-gdlr-animation="fadeInLeft"
                      data-gdlr-animation-duration="600ms" data-gdlr-animation-offset="0.8">
                      <div class="gdlr-core-pbf-element">
                        <div class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-title-item-caption-top
                                                        gdlr-core-item-pdlr"><span class="gdlr-core-title-item-caption
                                                            gdlr-core-info-font
                                                            gdlr-core-skin-caption" style={{
                                                              fontSize:'17px'
                                                            }}></span>
                          <div class="gdlr-core-title-item-title-wrap">
                            <h3 class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title" style={{
                                                                  letterSpacing:'0px',
                                                                  textTransform:'none',
                                                                  color: '#001326'
                                                                }}>Welcome to lagoSmart<span class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h3>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div class="gdlr-core-text-box-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        gdlr-core-left-align">
                          <div class="gdlr-core-text-box-item-content" style={{
                            fontSize:'18px',
                            textTransform: 'none'
                          }}>
                            <p>Welcome
                              to lagoSmart, a Web
                              Digital
                              Technology
                              Company in
                              Nigeria.</p>
                              
                            <p><br/><br/>lagoSmart
                                      is Nigeria’s
                                      premier Web Digital
                                      Technology
                                      Company was
                                      established its
                                      first unit
                                      in 2001
                                      exploring its
                                      products across
                                      Africa.</p>
                                    <p><br/>Given
                                            its uniqueness
                                            and standing
                                            among the
                                            leading
                                            technology
                                            company in the
                                            country,
                                            lagoSmart also
                                            enjoys
                                            widespread
                                            foreign
                                            patronage, with
                                            over fifteen
                                            countries being
                                            extensive users
                                            of the product.</p>
                                          <p>Have a rewarding
                                            time at
                                            lagoSmart!!!!!</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="gdlr-core-pbf-column
                                        gdlr-core-column-30" id="gdlr-core-column-23415">
                                <div class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js
                                            gdlr-core-column-extend-right" style={{
                                              minHeight: '300px'
                                            }}
                                  data-sync-height="height-1" data-sync-height-center>
                                  <div class="gdlr-core-pbf-background-wrap">
                                    <div class="gdlr-core-pbf-background
                                                    gdlr-core-parallax
                                                    gdlr-core-js" style={{
                                                      backgroundImage:"url('assets/wp-content/uploads/2022/01/about-0.jpg')",
                                                      backgroundSize: 'cover',
                                                      backgroundPosition: 'center'
                                                    }} data-parallax-speed="0.2"></div>
                                  </div>
                                  <div class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"></div>
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-wrapper" style={{
                        padding: '0px 0px 0px 0px'
                      }} data-skin="Dark">
                        <div class="gdlr-core-pbf-background-wrap" style={{
                          backgroundColor: '#b1dddd'
                        }}></div>
                        <div class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js">
                          <div class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container">
                            <div class="gdlr-core-pbf-column
                                        gdlr-core-column-30
                                        gdlr-core-column-first" id="gdlr-core-column-84700">
                              <div class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js
                                            gdlr-core-column-extend-left" data-sync-height="height-1">
                                <div class="gdlr-core-pbf-background-wrap" style={{
                                  // backgroundColor: '#011e2f'
                                }}></div>
                                <div class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content">
                                  <div class="gdlr-core-pbf-element">
                                    <div class="gdlr-core-image-item
                                                        gdlr-core-item-pdb
                                                        gdlr-core-center-align
                                                        gdlr-core-item-pdlr">
                                      <div class="gdlr-core-image-item-wrap
                                                            gdlr-core-media-image
                                                            gdlr-core-image-item-style-rectangle" style={{
                                                              borderWidth:'0px',
                                                              marginTop: '10px'
                                                            }}>
                                            <img src="assets/medialogo.jpeg"alt="" width="500" height="700" title="logoc1" />
                                          </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="gdlr-core-pbf-column
                                        gdlr-core-column-30" data-skin="White
                                        Text" id="gdlr-core-column-23732">
                              <div class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js" style={{padding: '140px 0px 70px 70px'}} data-sync-height="height-1" data-sync-height-center>
                                <div class="gdlr-core-pbf-background-wrap" style={{
                                  backgroundColor:'#b1dddd'
                                  }}></div>
                                <div class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content" data-gdlr-animation="fadeInRight"
                                  data-gdlr-animation-duration="600ms" data-gdlr-animation-offset="0.8">
                                  <div class="gdlr-core-pbf-element">
                                    <div class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-title-item-caption-top
                                                        gdlr-core-item-pdlr">
                                      <div class="gdlr-core-title-item-title-wrap">
                                        <h3 class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title" style={{
                                                                  letterSpacing:'0px',
                                                                  textTransform:'none',
                                                                  color: '#001326'
                                                                }}>lagoSmart
                                          at a Glance<span class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h3>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="gdlr-core-pbf-element">
                                    <div class="gdlr-core-text-box-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        gdlr-core-left-align">
                                      <div class="gdlr-core-text-box-item-content" style={{
                                        fontSize:'18px',
                                        textTransform:'none',
                                        color: '#0a0a0a'
                                      }}>
                                        <p><br/>lagoSmart
                                              is Nigeria’s
                                              premier Web
                                              &amp; Digital
                                              Technology
                                              Company which
                                              was established
                                              in 2001
                                              exploring its
                                              products across
                                              more than
                                              fifteen
                                              countries and is
                                              devoted to
                                              providing
                                              Software
                                              solutions,
                                              Ecommerce Portal
                                              Development,
                                              Technology
                                              Support, and
                                              Digital
                                              Marketing
                                              Services.</p>
                                            <p><br/>The
                                                company’s
                                                Payroll
                                                Management and
                                                Human Resources
                                                Management
                                                software is
                                                already leaving
                                                their footstep
                                                in over ten
                                                countries on
                                                Africa.</p>
                                            <p><br/>lagoSmart
                                                has also
                                                Launched a B2B
                                                web portal
                                                <a href='www.9jaBizz.com' style={{color:'red'}}> (9jaBizz.com) </a>in
                                                Nigeria to
                                                connect the
                                                buyers and
                                                suppliers to
                                                fulfill the
                                                manufacturing
                                                need.</p>
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="gdlr-core-pbf-wrapper" style={{
                          padding: '0px 0px 0px 0px'
                        }}>
                          <div class="gdlr-core-pbf-background-wrap"></div>
                          <div class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js">
                            <div class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container">
                              <div class="gdlr-core-pbf-column
                                        gdlr-core-column-30
                                        gdlr-core-column-first" id="gdlr-core-column-44358">
                                <div class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js" style={{
                                              padding: '40px 40px 50px 0px'
                                            }} data-sync-height="height-1" data-sync-height-center>
                                  <div class="gdlr-core-pbf-background-wrap"></div>
                                  <div class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content" data-gdlr-animation="fadeInLeft"
                                    data-gdlr-animation-duration="600ms" data-gdlr-animation-offset="0.8">
                                    <div class="gdlr-core-pbf-element">
                                      <div class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-title-item-caption-top
                                                        gdlr-core-item-pdlr">
                                        <div class="gdlr-core-title-item-title-wrap">
                                          <h3 class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title" style={{
                                                                  letterSpacing: '0px',
                                                                  textTransform: 'none',
                                                                  color: '#001326'
                                                                }}>CSR
                                            Activities<span class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h3>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="gdlr-core-pbf-element">
                                      <div class="gdlr-core-text-box-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        gdlr-core-left-align">
                                        <div class="gdlr-core-text-box-item-content" style={{
                                          fontSize:'18px',
                                          textTransform:'none'
                                        }}>
                                          <p>Every aspect of our business exemplifies our commitment to keep people and
                                            their lives moving forward and, hence,
                                            Corporate Social
                                            Responsibility
                                            is and forever
                                            has been a prime
                                            focus for
                                            lagoSmart. Our
                                            philosophy is to
                                            encourage
                                            everyone to give
                                            back to the
                                            society, the
                                            nation and
                                            humankind, and
                                            we are always at
                                            the forefront to
                                            help the ones in
                                            need.</p>
                                          <p>lagoSmart
                                            participates
                                            actively in
                                            numerous social
                                            events and
                                            contributes to
                                            the cause
                                            financially
                                            through the
                                            Rotary Club, an
                                            NGO that strives
                                            to improve the
                                            lives of human
                                            being. It is a
                                            humanitarian
                                            agency dedicated
                                            to providing
                                            basic
                                            educational
                                            support,
                                            health­care
                                            access, economic
                                            opportunity, and
                                            hope to the
                                            underprivileged
                                            and deserving.</p>
                                          <p>In addition,
                                            lagoSmart
                                            recognizes the
                                            importance of
                                            instilling the
                                            spirit of
                                            sportsmanship in
                                            the younger
                                            generation. We
                                            support that
                                            thought by
                                            sponsoring and
                                            nurturing
                                            promising talent
                                            in the sport of
                                            Football.</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="gdlr-core-pbf-column
                                        gdlr-core-column-30" id="gdlr-core-column-77700">
                                <div class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js
                                            gdlr-core-column-extend-right"  style={{
                                              minHeight: '200px'
                                            }}
                                  data-sync-height="height-1" data-sync-height-center>
                                  <div class="gdlr-core-pbf-background-wrap">
                                    <div class="gdlr-core-pbf-background
                                                    gdlr-core-parallax
                                                    gdlr-core-js" style={{
                                                      backgroundImage: "url('assets/wp-content/uploads/2022/01/csr-1.jpg')",
                                                      backgroundSize: "cover",
                                                      backgroundPosition: "center"
                                                    }} data-parallax-speed="0.2"></div>
                                  </div>
                                  <div class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="gdlr-core-pbf-wrapper">
                          <div class="gdlr-core-pbf-background-wrap" style={{
                            backgroundColor: '#ffffff'
                          }}>
                            <div class="gdlr-core-pbf-background
                                    gdlr-core-parallax gdlr-core-js" style={{
                                      opacity: .5,
                                      backgroundImage:"url('assets/wp-content/uploads/2022/01/bg-2.jpg')",
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center'
                                    }} data-parallax-speed="0.8"></div>
                          </div>
                          <div class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js">
                            <div class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container">
                              <div class="gdlr-core-pbf-column
                                        gdlr-core-column-10
                                        gdlr-core-column-first" id="gdlr-core-column-79650">
                                <div class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js" style={{
                                              padding: '10px 10px 10px 10px'
                                            }}>
                                  <div class="gdlr-core-pbf-background-wrap"></div>
                                  <div class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js"></div>
                                </div>
                              </div>
                              <div class="gdlr-core-pbf-column
                                        gdlr-core-column-20" id="gdlr-core-column-66467">
                                <div class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js" style={{
                                              padding: '10px 10px 10px 10px'
                                            }}>
                                  <div class="gdlr-core-pbf-background-wrap"></div>
                                  <div class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js">
                                    <div class="gdlr-core-pbf-element">
                                      <div class="gdlr-core-column-service-item
                                                        gdlr-core-item-pdb
                                                        gdlr-core-left-align
                                                        gdlr-core-column-service-icon-top
                                                        gdlr-core-no-caption
                                                        gdlr-core-item-pdlr
                                                        services-c" style={{
                                                          backgroundColor: '#f9f9f9',
                                                          paddingBottom:'30px'
                                                        }}>
                                        <div class="gdlr-core-column-service-media
                                                            gdlr-core-media-image" style={{
                                                              margin: '15px 5px 5px 5px',
                                                              maxWidth: '100%'
                                                            }}>
                                                              <img src="assets/wp-content/uploads/2022/01/OUR-VISION.jpg"
                                            alt="" width="600" height="418" title="OUR-VISION" /></div>
                                        <div class="gdlr-core-column-service-content-wrapper">
                                          <div class="gdlr-core-column-service-title-wrap" style={{
                                            marginBottom:'0px'
                                          }}>
                                            <h3 class="gdlr-core-column-service-title
                                                                    gdlr-core-skin-title" style={{
                                                                      fontSize:'20px',
                                                                      paddingTop:'0px'
                                                                    }}>Our
                                              Vision</h3>
                                          </div>
                                          <div class="gdlr-core-column-service-content" style={{
                                            textTransform:'none'
                                          }}>
                                            <p>Rise
                                              and expand
                                              as an
                                              organization
                                              and become a
                                              name
                                              synonymous
                                              with
                                              quality.</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                              <div class="gdlr-core-pbf-column
                                        gdlr-core-column-20" id="gdlr-core-column-31572">
                                <div class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js" style={{
                                              padding: '10px 10px 10px 10px'
                                            }}>
                                  <div class="gdlr-core-pbf-background-wrap"></div>
                                  <div class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js">
                                    <div class="gdlr-core-pbf-element">
                                      <div class="gdlr-core-column-service-item
                                                        gdlr-core-item-pdb
                                                        gdlr-core-left-align
                                                        gdlr-core-column-service-icon-top
                                                        gdlr-core-no-caption
                                                        gdlr-core-item-pdlr
                                                        services-c" style={{
                                                          backgroundColor: '#ffffff',
                                                          paddingBottom:'30p'
                                                        }}>
                                        <div class="gdlr-core-column-service-media
                                                            gdlr-core-media-image" style={{
                                                              margin: '15px 5px 5px 5px',
                                                              maxWidth: '100%'
                                                            }}>
                                                              <img src="assets/wp-content/uploads/2022/01/MISSION.jpg"
                                            alt="" width="600" height="418" title="MISSION" /></div>
                                        <div class="gdlr-core-column-service-content-wrapper">
                                          <div class="gdlr-core-column-service-title-wrap" style={{
                                            marginBottom: '0px'
                                          }}>
                                            <h3 class="gdlr-core-column-service-title
                                                                    gdlr-core-skin-title" style={{
                                                                      fontSize:'20px',
                                                                      paddingTop:'0px'
                                                                    }}>Mission</h3>
                                          </div>
                                          <div class="gdlr-core-column-service-content" style={{
                                            textTransform: 'none'
                                          }}>
                                            <p>To
                                              provide the
                                              best
                                              service,
                                              coupled with
                                              the right
                                              levels of
                                              enthusiasm
                                              and
                                              ambition,
                                              with results
                                              in-sync with
                                              the market
                                              trends and
                                              demands.</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                              
                              <div class="gdlr-core-pbf-column
                                        gdlr-core-column-10" id="gdlr-core-column-31062">
                                <div class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js" style={{
                                              padding: '10px 10px 10px 10px'
                                            }}>
                                  <div class="gdlr-core-pbf-background-wrap"></div>
                                  <div class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="gdlr-core-pbf-wrapper" style={{
                          padding: '100px 0px 60px 0px'
                        }}>
                          <div class="gdlr-core-pbf-background-wrap" style={{
                            backgroundColor: '#ffffff'
                          }}>
                            <div class="gdlr-core-pbf-background
                                    gdlr-core-parallax gdlr-core-js" style={{
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center'
                                    }} data-parallax-speed="0.2"></div>
                          </div>
                          <div class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js">
                            <div class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container">
                              <div class="gdlr-core-pbf-column
                                        gdlr-core-column-30
                                        gdlr-core-column-first">
                                <div class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js">
                                  <div class="gdlr-core-pbf-background-wrap"></div>
                                  <div class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js">
                                    <div class="gdlr-core-pbf-element">
                                      <div class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-title-item-caption-top
                                                        gdlr-core-item-pdlr" style={{
                                                          paddingBottom: '20px'
                                                        }}>
                                        <div class="gdlr-core-title-item-title-wrap">
                                          <h3 class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title" style={{
                                                                  fontSize: '35px',
                                                                  letterSpacing:'0px',
                                                                  textTransform: 'none',
                                                                  color: '#001326'
                                                                }}>We
                                            Value<span class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h3>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="gdlr-core-pbf-element">
                                      <div class="gdlr-core-icon-list-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-style-1" style={{
                                                          paddingBottom: '25px'
                                                        }}>
                                        <ul>
                                          <li class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix" style={{
                                                                  marginBottom: '24px'
                                                                }}><span class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px'
                                                                        }}></i><i class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check-circle-o" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px',
                                                                          width: '25px'
                                                                        }}></i></span>
                                            <div class="gdlr-core-icon-list-content-wrap"><span
                                              class="gdlr-core-icon-list-content" style={{
                                                fontSize:'18px'
                                              }}>Customer
                                              First</span></div>
                                          </li>
                                          <li class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix" style={{
                                                                  marginBottom: '24px'
                                                                }}><span class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px'
                                                                        }}></i><i class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check-circle-o" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px',
                                                                          width: '25px'
                                                                        }}></i></span>
                                            <div class="gdlr-core-icon-list-content-wrap"><span
                                              class="gdlr-core-icon-list-content" style={{
                                                fontSize:'18px'
                                              }}>Highest
                                              standards
                                              of
                                              ethics
                                              and
                                              integrity</span></div>
                                          </li>
                                          <li class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"  style={{
                                                                  marginBottom: '24px'
                                                                }}><span class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px'
                                                                        }}></i><i class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check-circle-o" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px',
                                                                          width: '25px'
                                                                        }}></i></span>
                                            <div class="gdlr-core-icon-list-content-wrap"><span
                                              class="gdlr-core-icon-list-content" style={{
                                                fontSize:'18px'
                                              }}>Open
                                              and
                                              transparent
                                              culture
                                            </span></div>
                                          </li>
                                          <li class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"  style={{
                                                                  marginBottom: '24px'
                                                                }}><span class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px'
                                                                        }}></i><i class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check-circle-o" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px',
                                                                          width: '25px'
                                                                        }}></i></span>
                                            <div class="gdlr-core-icon-list-content-wrap"><span
                                              class="gdlr-core-icon-list-content" style={{
                                                fontSize:'18px'
                                              }}>Aggressive
                                              commitments
                                              for self
                                              and
                                              others</span></div>
                                          </li>
                                          <li class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"  style={{
                                                                  marginBottom: '24px'
                                                                }}><span class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px'
                                                                        }}></i><i class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check-circle-o" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px',
                                                                          width: '25px'
                                                                        }}></i></span>
                                            <div class="gdlr-core-icon-list-content-wrap"><span
                                              class="gdlr-core-icon-list-content" style={{
                                                fontSize:'18px'
                                              }}>The
                                              collaborative
                                              partnership
                                              with our
                                              clients
                                              and view
                                              ourselves
                                              as an
                                              integral
                                              extension
                                              of their
                                              business.
                                            </span></div>
                                          </li>
                                          <li class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix" style={{
                                                                  marginBottom: '24px'
                                                                }}><span class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px'
                                                                        }}></i><i class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check-circle-o" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px',
                                                                          width: '25px'
                                                                        }}></i></span>
                                            <div class="gdlr-core-icon-list-content-wrap"><span
                                              class="gdlr-core-icon-list-content" style={{
                                                fontSize:'18px'
                                              }}>Long-lasting
                                              and
                                              forward-looking
                                              relationships
                                              with our
                                              clients
                                            </span></div>
                                          </li>
                                          <li class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"  style={{
                                                                  marginBottom: '24px'
                                                                }}><span class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px'
                                                                        }}></i><i class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check-circle-o" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px',
                                                                          width: '25px'
                                                                        }}></i></span>
                                            <div class="gdlr-core-icon-list-content-wrap"><span
                                              class="gdlr-core-icon-list-content" style={{
                                                fontSize:'18px'
                                              }}>Commitment
                                              to
                                              Confidentiality
                                            </span></div>
                                          </li>
                                          <li class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"  style={{
                                                                  marginBottom: '24px'
                                                                }}><span class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px'
                                                                        }}></i><i class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check-circle-o" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px',
                                                                          width: '25px'
                                                                        }}></i></span>
                                            <div class="gdlr-core-icon-list-content-wrap"><span
                                              class="gdlr-core-icon-list-content" style={{
                                                fontSize:'18px'
                                              }}>Adherence
                                              and
                                              improvement</span></div>
                                          </li>
                                          <li class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"  style={{
                                                                  marginBottom: '24px'
                                                                }}><span class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px'
                                                                        }}></i><i class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check-circle-o" style={{
                                                                          color: '#dd3333',
                                                                          fontSize: '25px',
                                                                          width: '25px'
                                                                        }}></i></span>
                                            <div class="gdlr-core-icon-list-content-wrap"><span
                                              class="gdlr-core-icon-list-content" style={{
                                                fontSize:'18px'
                                              }}>Strive
                                              for
                                              excellence
                                            </span></div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="gdlr-core-pbf-column
                                        gdlr-core-column-30">
                                <div class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js">
                                  <div class="gdlr-core-pbf-background-wrap"></div>
                                  <div class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js">
                                    <div class="gdlr-core-pbf-element">
                                      <div class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-title-item-caption-top
                                                        gdlr-core-item-pdlr" style={{
                                                          paddingBottom:'20px'
                                                        }}>
                                        <div class="gdlr-core-title-item-title-wrap">
                                          <h3 class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title" style={{
                                                                  fontSize: '35px',
                                                                  letterSpacing:'0px',
                                                                  textTransform: 'none',
                                                                  color:'#001326'
                                                                }}>Quality
                                            Policy <span class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h3>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="gdlr-core-pbf-element">
                                      <div class="gdlr-core-text-box-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        gdlr-core-left-align" style={{
                                                          paddingBottom: '25px'
                                                        }}>
                                        <div class="gdlr-core-text-box-item-content" style={{
                                          fontSize: '17px',
                                          textTransform:'none'
                                        }}>
                                          <p>We at
                                            9JABIZZ
                                            RESOURCES
                                            LIMITED. strive
                                            for continual
                                            improvement in
                                            service
                                            deliveries as
                                            per Customer
                                            requirements and
                                            to be a market
                                            leader in the
                                            industry
                                            through:</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="gdlr-core-pbf-element">
                                      <div class="gdlr-core-icon-list-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-style-1" style={{
                                                          paddingBottom: '25px'
                                                        }}>
                                        <ul>
                                          <li class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix" style={{
                                                                  marginBottom:'24px'
                                                                }}><span class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check" style={{
                                                                          color: '#dd3333',
                                                                          fontSize:'25px'
                                                                        }}></i><i class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check-circle-o" style={{
                                                                          color: '#dd3333',
                                                                          fontSize:'25px',
                                                                          width: '25px'
                                                                        }}></i></span>
                                            <div class="gdlr-core-icon-list-content-wrap"><span
                                              class="gdlr-core-icon-list-content" style={{
                                                fontSize: '18px'
                                              }}>Efficient
                                              Processes</span></div>
                                          </li>
                                          <li class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"  style={{
                                                                  marginBottom:'24px'
                                                                }}><span class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check" style={{
                                                                          color: '#dd3333',
                                                                          fontSize:'25px'
                                                                        }}></i><i class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check-circle-o" style={{
                                                                          color: '#dd3333',
                                                                          fontSize:'25px',
                                                                          width: '25px'
                                                                        }}></i></span>
                                            <div class="gdlr-core-icon-list-content-wrap"><span
                                              class="gdlr-core-icon-list-content" style={{
                                                fontSize: '18px'
                                              }}>On
                                              time
                                              Deliveries</span></div>
                                          </li>
                                          <li class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"  style={{
                                                                  marginBottom:'24px'
                                                                }}><span class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check" style={{
                                                                          color: '#dd3333',
                                                                          fontSize:'25px'
                                                                        }}></i><i class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check-circle-o" style={{
                                                                          color: '#dd3333',
                                                                          fontSize:'25px',
                                                                          width: '25px'
                                                                        }}></i></span>
                                            <div class="gdlr-core-icon-list-content-wrap"><span
                                              class="gdlr-core-icon-list-content" style={{
                                                fontSize: '18px'
                                              }}>Adapting
                                              to the
                                              Changing
                                              Market
                                              Requirements</span></div>
                                          </li>
                                          <li class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"  style={{
                                                                  marginBottom:'24px'
                                                                }}><span class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check" style={{
                                                                          color: '#dd3333',
                                                                          fontSize:'25px'
                                                                        }}></i><i class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check-circle-o" style={{
                                                                          color: '#dd3333',
                                                                          fontSize:'25px',
                                                                          width: '25px'
                                                                        }}></i></span>
                                            <div class="gdlr-core-icon-list-content-wrap"><span
                                              class="gdlr-core-icon-list-content" style={{
                                                fontSize: '18px'
                                              }}>Involvement,
                                              commitment,
                                              and
                                              improvement
                                              of
                                              employees.</span></div>
                                          </li>
                                          <li class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"  style={{
                                                                  marginBottom:'24px'
                                                                }}><span class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check" style={{
                                                                          color: '#dd3333',
                                                                          fontSize:'25px'
                                                                        }}></i><i class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check-circle-o" style={{
                                                                          color: '#dd3333',
                                                                          fontSize:'25px',
                                                                          width: '25px'
                                                                        }}></i></span>
                                            <div class="gdlr-core-icon-list-content-wrap"><span
                                              class="gdlr-core-icon-list-content" style={{
                                                fontSize: '18px'
                                              }}>Effective
                                              communication
                                              between
                                              departments.</span></div>
                                          </li>
                                          <li class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix" style={{
                                                                  marginBottom:'24px'
                                                                }}><span class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check" style={{
                                                                          color: '#dd3333',
                                                                          fontSize:'25px'
                                                                        }}></i><i class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check-circle-o" style={{
                                                                          color: '#dd3333',
                                                                          fontSize:'25px',
                                                                          width: '25px'
                                                                        }}></i></span>
                                            <div class="gdlr-core-icon-list-content-wrap"><span
                                              class="gdlr-core-icon-list-content" style={{
                                                fontSize: '18px'
                                              }}>Develop
                                              Data and
                                              Metrics
                                              for
                                              factual
                                              decision-making.</span></div>
                                          </li>
                                          <li class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix" style={{
                                                                  marginBottom:'24px'
                                                                }}><span class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check" style={{
                                                                          color: '#dd3333',
                                                                          fontSize:'25px'
                                                                        }}></i><i class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check-circle-o" style={{
                                                                          color: '#dd3333',
                                                                          fontSize:'25px',
                                                                          width: '25px'
                                                                        }}></i></span>
                                            <div class="gdlr-core-icon-list-content-wrap"><span
                                              class="gdlr-core-icon-list-content" style={{
                                                fontSize: '18px'
                                              }}>Continual
                                              Improvement
                                              in
                                              Quality.</span></div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="gdlr-core-pbf-section">
                          <div class="gdlr-core-pbf-section-container
                                gdlr-core-container clearfix">
                            <div class="gdlr-core-pbf-column
                                    gdlr-core-column-30 gdlr-core-column-first">
                              <div class="gdlr-core-pbf-column-content-margin
                                        gdlr-core-js">
                                <div class="gdlr-core-pbf-column-content
                                            clearfix gdlr-core-js"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                 
         
           
          


    <Footer />
    </div>
  )
}
export default About;