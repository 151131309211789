import React from "react";
import Footer from "../partial/footer";
import NavBar from "../partial/navbar";

function Vpay() {
    return (
        <div>
            <NavBar />


            <div class="infinite-page-wrapper"
                id="infinite-page-wrapper">
                <div
                    class="gdlr-core-page-builder-body"><div
                        class="gdlr-core-pbf-wrapper" style={{
                            padding: '90px 0px 90px 0px'
                        }} data-skin="White Text"><div
                            class="gdlr-core-pbf-background-wrap"
                            style={{
                                backgroundColor: '#0a0a0a' 
                            }}><div
                                class="gdlr-core-pbf-background
                                    gdlr-core-parallax gdlr-core-js"
                                style={{
                                    backgroundImage:
                                        "url('assets/wp-content/uploads/2022/07/vpay.jpg')",
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}
                                data-parallax-speed="0.8"></div></div><div
                                    class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js"><div
                                class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                    class="gdlr-core-pbf-element"><div
                                        class="gdlr-core-title-item
                                            gdlr-core-item-pdb clearfix
                                            gdlr-core-left-align
                                            gdlr-core-title-item-caption-bottom
                                            gdlr-core-item-pdlr"><div
                                            class="gdlr-core-title-item-title-wrap"><h1
                                                class="gdlr-core-title-item-title
                                                    gdlr-core-skin-title" style={{
                                                    fontSize: '55px ;font-weight: 700',
                                                    textTransform: 'none'
                                                }}>Vpay<span
                                                    class="gdlr-core-title-item-title-divider
                                                        gdlr-core-skin-divider"></span></h1></div><span
                                            class="gdlr-core-title-item-caption
                                                gdlr-core-info-font
                                                gdlr-core-skin-caption"
                                            style={{
                                                fontSize: '19px',
                                                fontStyle: 'normal'
                                            }}>Payroll
                                            Management System</span></div></div><div
                                                class="gdlr-core-pbf-element"><div
                                                    class="gdlr-core-text-box-item
                                            gdlr-core-item-pdlr
                                            gdlr-core-item-pdb
                                            gdlr-core-left-align"><div
                                            class="gdlr-core-text-box-item-content"
                                            style={{
                                                textTransform: 'none'
                                            }}><p><button
                                                class="popmake-226"
                                                style={{
                                                    color: '#fff'
                                                }}>
                                                REQUEST A DEMO</button></p>
                                        </div></div></div></div></div></div><div
                                            class="gdlr-core-pbf-wrapper" style={{
                                                padding: '0px 0px 0px 0px'
                                            }}><div class="gdlr-core-pbf-background-wrap"></div><div
                                                class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js"><div
                                class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                    class="gdlr-core-pbf-column
                                        gdlr-core-column-30
                                        gdlr-core-column-first"><div
                                        class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js"style={{
                                            padding: '140px 70px 70px 0px'
                                        }}
                                        data-sync-height="height-1"
                                        data-sync-height-center><div
                                            class="gdlr-core-pbf-background-wrap"></div><div
                                                class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"
                                                data-gdlr-animation="fadeInLeft"
                                                data-gdlr-animation-duration="600ms"
                                                data-gdlr-animation-offset="0.8"><div
                                                    class="gdlr-core-pbf-element"><div
                                                        class="gdlr-core-text-box-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        gdlr-core-left-align"
                                                        style={{
                                                            paddingBottom: '0px'
                                                        }}><div
                                                            class="gdlr-core-text-box-item-content"
                                                            style={{
                                                                fontSize: '18px',
                                                                textTransform: 'none'
                                                            }}><p>This
                                                                cloud-based
                                                                system allows to
                                                                automate and
                                                                digitize manual
                                                                data entry and
                                                                saves time and
                                                                human efforts
                                                                both. The
                                                                software is
                                                                responsible to
                                                                improve
                                                                engagement and
                                                                overall,
                                                                employee
                                                                experience. Here
                                                                are few reasons
                                                                to choose a VPay
                                                                Solution as your
                                                                preferred
                                                                payroll
                                                                management
                                                                software for
                                                                your business:</p>
                                                    </div></div></div><div
                                                        class="gdlr-core-pbf-element"><div
                                                            class="gdlr-core-icon-list-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-style-1"><ul><li
                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                            class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                            class="gdlr-core-icon-list-content-wrap"><span
                                                                class="gdlr-core-icon-list-content">It
                                                                is a web
                                                                application</span></div></li><li
                                                                    class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">User-friendly
                                                                    interfaces
                                                                    for your
                                                                    payroll
                                                                    management</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">It
                                                                    is
                                                                    customizable</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">World
                                                                    wide
                                                                    access,
                                                                    no
                                                                    virus,
                                                                    Access
                                                                    via
                                                                    mobile
                                                                    device,
                                                                    Easy to
                                                                    update</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Quick
                                                                    Data
                                                                    Migration</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Integration
                                                                    with
                                                                    Biometric
                                                                    Device
                                                                    or
                                                                    Existing
                                                                    System</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Multiple
                                                                    Companies
                                                                    and
                                                                    Location
                                                                    support</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Powerful
                                                                    reporting</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Export
                                                                    to
                                                                    Excel/PDF/Word
                                                                    for all
                                                                    list and
                                                                    report</span></div></li></ul></div></div></div></div></div><div
                                                                        class="gdlr-core-pbf-column gdlr-core-column-30"
                                                                        id="gdlr-core-column-55629"><div
                                                                            class="gdlr-core-pbf-column-content-margin gdlr-core-js
                                                                            gdlr-core-column-extend-right"
                                                                            style={{
                                                                                minHeight: '300px'
                                                                            }}
                                                                            data-sync-height="height-1"><div
                                                                                class="gdlr-core-pbf-background-wrap"><div
                                                                                    class="gdlr-core-pbf-background
                                                    gdlr-core-parallax
                                                    gdlr-core-js"
                                                                                    style={{
                                                                                        backgroundImage:
                                                                                            "url('assets/wp-content/uploads/2022/07/pay-roll.jpg')",
                                                                                        backgroundSize: 'cover',
                                                                                        backgroundPosition: 'center'
                                                                                    }} data-parallax-speed="0.2"></div></div><div
                                                                                        class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"></div></div></div></div></div></div><div
                        class="gdlr-core-pbf-wrapper"><div
                            class="gdlr-core-pbf-background-wrap"
                            style={{
                                backgroundColor: '#050d1a' 
                            }}></div><div
                                class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js"><div
                                class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                    class="gdlr-core-pbf-column
                                        gdlr-core-column-60
                                        gdlr-core-column-first"><div
                                        class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js"><div
                                            class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js"><div
                                                class="gdlr-core-pbf-element"><div
                                                    class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-center-align
                                                        gdlr-core-title-item-caption-top
                                                        gdlr-core-item-pdlr"><div
                                                        class="gdlr-core-title-item-title-wrap"><h3
                                                            class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title" style={{
                                                                    fontSize: '35px',
                                                                    textTransform:'none',
                                                                    color: '#ffffff' 
                                                                }}>Benefits
                                                            of VPay Payroll
                                                            Solution<span
                                                                class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h3></div></div></div></div></div></div></div></div></div><div
                        class="gdlr-core-pbf-wrapper" style={{
                            padding: '0px 0px 0px 0px'
                        }} data-skin="Dark"><div
                            class="gdlr-core-pbf-background-wrap"
                            style={{
                                backgroundColor: '#278dd6'
                            }}></div><div
                                class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js"><div
                                class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                    class="gdlr-core-pbf-column
                                        gdlr-core-column-30
                                        gdlr-core-column-first"
                                    id="gdlr-core-column-51227"><div
                                        class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js
                                            gdlr-core-column-extend-left"
                                        style={{
                                            minHeight:'300px'
                                        }}
                                        data-sync-height="height-1"
                                        data-sync-height-center><div
                                            class="gdlr-core-pbf-background-wrap"><div
                                                class="gdlr-core-pbf-background
                                                    gdlr-core-parallax
                                                    gdlr-core-js"
                                                style={{
                                                    backgroundImage:
                                                    "url('assets/wp-content/uploads/2022/07/Employee-Information.jpg')",
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center'
                                                }} data-parallax-speed="0.2"></div></div><div
                                            class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"></div></div></div><div
                                    class="gdlr-core-pbf-column
                                        gdlr-core-column-30" data-skin="White
                                        Text"><div
                                        class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js"style={{
                                                padding: '140px 0px 70px 70px'
                                            }}
                                        data-sync-height="height-1"
                                        data-sync-height-center><div
                                            class="gdlr-core-pbf-background-wrap"></div><div
                                                class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"
                                                data-gdlr-animation="fadeInRight"
                                                data-gdlr-animation-duration="600ms"
                                                data-gdlr-animation-offset="0.8"><div
                                                    class="gdlr-core-pbf-element"><div
                                                        class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-title-item-caption-top
                                                        gdlr-core-item-pdlr"><div
                                                        class="gdlr-core-title-item-title-wrap"><h3
                                                            class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title" style={{
                                                                    letterSpacing: '0px',
                                                                    textTransform: 'none' 
                                                                }}>Employee
                                                            Information<span
                                                                class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h3></div></div></div><div
                                                class="gdlr-core-pbf-element"><div
                                                    class="gdlr-core-text-box-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        gdlr-core-left-align"><div
                                                        class="gdlr-core-text-box-item-content"
                                                        style={{
                                                            fontSize:'18px',
                                                            textTransform: 'none'
                                                        }}><ul>
                                                            <li>Employee
                                                                personal
                                                                details,
                                                                contact
                                                                details,
                                                                qualification,
                                                                Emergency
                                                                details,
                                                                guarantor
                                                                details etc.</li>
                                                            <li>Employee
                                                                Payment
                                                                method</li>
                                                            <li>Employee
                                                                transfer,
                                                                promotion,
                                                                and
                                                                separation</li>
                                                            <li>Employee
                                                                history
                                                                record and
                                                                lots more</li>
                                                        </ul>
                                                    </div></div></div></div></div></div></div></div></div><div
                                                        class="gdlr-core-pbf-wrapper" style={{
                                                            padding: '0px 0px 0px 0px'
                                                        }}><div class="gdlr-core-pbf-background-wrap"></div><div
                            class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js"><div
                                class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                    class="gdlr-core-pbf-column
                                        gdlr-core-column-30
                                        gdlr-core-column-first"><div
                                        class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js"style={{
                                                padding: '140px 70px 70px 0px'
                                            }}
                                        data-sync-height="height-1"
                                        data-sync-height-center><div
                                            class="gdlr-core-pbf-background-wrap"></div><div
                                                class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"
                                                data-gdlr-animation="fadeInLeft"
                                                data-gdlr-animation-duration="600ms"
                                                data-gdlr-animation-offset="0.8"><div
                                                    class="gdlr-core-pbf-element"><div
                                                        class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-title-item-caption-bottom
                                                        gdlr-core-item-pdlr"><div
                                                        class="gdlr-core-title-item-title-wrap"><h3
                                                            class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title"style={{
                                                                    textTransform:'none'
                                                                }}>Time
                                                            Management<span
                                                                class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h3></div><span
                                                        class="gdlr-core-title-item-caption
                                                            gdlr-core-info-font
                                                            gdlr-core-skin-caption"
                                                        style={{
                                                            fontStyle: 'normal' 
                                                        }}>
                                                        (Attendance + Shift)</span></div></div><div
                                                            class="gdlr-core-pbf-element"><div
                                                                class="gdlr-core-icon-list-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-style-1"><ul><li
                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                            class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                            class="gdlr-core-icon-list-content-wrap"><span
                                                                class="gdlr-core-icon-list-content">Attendance
                                                                Calendar
                                                                management</span></div></li><li
                                                                    class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Real-time
                                                                    data
                                                                    from
                                                                    Biometric/Swipe
                                                                    card
                                                                    device/
                                                                    Face
                                                                    recognition
                                                                    system</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Attendance
                                                                    month
                                                                    setup to
                                                                    take
                                                                    into
                                                                    account
                                                                    the
                                                                    cut-off
                                                                    provision-
                                                                </span></div></li><li
                                                                    class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Attendance
                                                                    can be
                                                                    imported
                                                                    through
                                                                    excel
                                                                    sheet</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Employee
                                                                    shift
                                                                    rotation
                                                                    facility</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Flexible
                                                                    and
                                                                    regular
                                                                    shift
                                                                    types
                                                                    with
                                                                    night
                                                                    shift
                                                                    functionality</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Dynamic
                                                                    off-day/
                                                                    week-off
                                                                    setup
                                                                    according
                                                                    to
                                                                    state,
                                                                    Branch,
                                                                    Employee
                                                                    Grade/Cadre
                                                                    or
                                                                    Employee
                                                                    specific</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Timing
                                                                    policies
                                                                    for
                                                                    grace
                                                                    period,
                                                                    late
                                                                    hours,
                                                                    working
                                                                    hours
                                                                    e.t.c</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Set
                                                                    any
                                                                    working
                                                                    day to
                                                                    non –
                                                                    working
                                                                    day and
                                                                    vice
                                                                    versa in
                                                                    case of
                                                                    riots
                                                                    e.t.c</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Employee
                                                                    overtime
                                                                    management</span></div></li></ul></div></div></div></div></div><div
                                                                        class="gdlr-core-pbf-column
                                        gdlr-core-column-30"
                                                                        id="gdlr-core-column-71892"><div
                                                                            class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js
                                            gdlr-core-column-extend-right"
                                                                            style={{
                                                                                minHeight: '300px'
                                                                            }}
                                                                            data-sync-height="height-1"><div
                                                                                class="gdlr-core-pbf-background-wrap"><div
                                                                                    class="gdlr-core-pbf-background
                                                    gdlr-core-parallax
                                                    gdlr-core-js"
                                                                                    style={{
                                                                                        backgroundImage:
                                                    "url('assets/wp-content/uploads/2022/07/Time-Management.jpg')",
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center'
                                                                                    }} data-parallax-speed="0.2"></div></div><div
                                            class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"></div></div></div></div></div></div><div
                        class="gdlr-core-pbf-wrapper" style={{
                            padding: '0px 0px 0px 0px'
                        }} data-skin="Dark"><div
                            class="gdlr-core-pbf-background-wrap"
                            style={{
                                backgroundColor: '#00335e'
                            }}></div><div
                                class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js"><div
                                class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                    class="gdlr-core-pbf-column
                                        gdlr-core-column-30
                                        gdlr-core-column-first"
                                    id="gdlr-core-column-2005"><div
                                        class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js
                                            gdlr-core-column-extend-left"
                                        style={{
                                            minHeight: '300px'
                                        }}
                                        data-sync-height="height-1"
                                        data-sync-height-center><div
                                            class="gdlr-core-pbf-background-wrap"><div
                                                class="gdlr-core-pbf-background
                                                    gdlr-core-parallax
                                                    gdlr-core-js"
                                                style={{
                                                    backgroundImage:
                                                    "url('assets/wp-content/uploads/2022/07/Payroll-Processing.jpg')",
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center'
                                                }} data-parallax-speed="0.2"></div></div><div
                                            class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"></div></div></div><div
                                    class="gdlr-core-pbf-column
                                        gdlr-core-column-30" data-skin="White
                                        Text"><div
                                        class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js" style={{
                                                padding: '140px 0px 70px 70px'
                                            }}
                                        data-sync-height="height-1"
                                        data-sync-height-center><div
                                            class="gdlr-core-pbf-background-wrap"></div><div
                                                class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"
                                                data-gdlr-animation="fadeInRight"
                                                data-gdlr-animation-duration="600ms"
                                                data-gdlr-animation-offset="0.8"><div
                                                    class="gdlr-core-pbf-element"><div
                                                        class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-title-item-caption-top
                                                        gdlr-core-item-pdlr"><div
                                                        class="gdlr-core-title-item-title-wrap"><h3
                                                            class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title"style={{
                                                                    letterSpacing: '0px',
                                                                textTransform: 'none'
                                                                }}>Payroll
                                                            Processing<span
                                                                class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h3></div></div></div><div
                                                class="gdlr-core-pbf-element"><div
                                                    class="gdlr-core-icon-list-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-style-1"><ul><li
                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                            class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                            class="gdlr-core-icon-list-content-wrap"><span
                                                                class="gdlr-core-icon-list-content">Leave
                                                                management</span></div></li><li
                                                                    class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Loan
                                                                    and
                                                                    Advance
                                                                    management</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Loan
                                                                    and
                                                                    Advance
                                                                    EMI
                                                                    deduction
                                                                    automatic
                                                                    and
                                                                    displayed
                                                                    in
                                                                    salary
                                                                    slip</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Loan
                                                                    rescheduling,
                                                                    stop
                                                                    payment
                                                                    and
                                                                    settlement
                                                                    options</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Interest
                                                                    on loan
                                                                    calculation
                                                                    if
                                                                    required</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Allowance
                                                                    Earning/deduction
                                                                    can be
                                                                    defined</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Dynamic
                                                                    salary
                                                                    formula
                                                                    creation</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">On
                                                                    attendance
                                                                    and non
                                                                    –
                                                                    attendance
                                                                    pay
                                                                    heads</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Salaries
                                                                    reprocess
                                                                    and
                                                                    posting
                                                                    features</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">With
                                                                    interview
                                                                    date and
                                                                    time.</span></div></li></ul></div></div></div></div></div></div></div></div><div
                                                                        class="gdlr-core-pbf-wrapper" style={{
                                                                            padding: '0px 0px 0px 0px'
                                                                        }}><div class="gdlr-core-pbf-background-wrap"></div><div
                            class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js"><div
                                class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                    class="gdlr-core-pbf-column
                                        gdlr-core-column-30
                                        gdlr-core-column-first"><div
                                        class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js"style={{
                                                padding: '140px 70px 70px 0px'
                                            }}
                                        data-sync-height="height-1"
                                        data-sync-height-center><div
                                            class="gdlr-core-pbf-background-wrap"></div><div
                                                class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"
                                                data-gdlr-animation="fadeInLeft"
                                                data-gdlr-animation-duration="600ms"
                                                data-gdlr-animation-offset="0.8"><div
                                                    class="gdlr-core-pbf-element"><div
                                                        class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-title-item-caption-bottom
                                                        gdlr-core-item-pdlr"><div
                                                        class="gdlr-core-title-item-title-wrap"><h3
                                                            class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title"style={{
                                                                    textTransform: 'none' 
                                                                }}>Employee
                                                            Loan Management<span
                                                                class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h3></div></div></div><div
                                                class="gdlr-core-pbf-element"><div
                                                    class="gdlr-core-icon-list-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-style-1"><ul><li
                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                            class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                            class="gdlr-core-icon-list-content-wrap"><span
                                                                class="gdlr-core-icon-list-content">Loan
                                                                application</span></div></li><li
                                                                    class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Loan
                                                                    reschedules</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Monthly
                                                                    loan
                                                                    statement</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Load
                                                                    details</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Loan
                                                                    installment
                                                                    report</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Pending
                                                                    loan</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Loan
                                                                    payment</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Loan
                                                                    interest
                                                                    report</span></div></li></ul></div></div></div></div></div><div
                                                                        class="gdlr-core-pbf-column
                                        gdlr-core-column-30"
                                                                        id="gdlr-core-column-58636"><div
                                                                            class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js
                                            gdlr-core-column-extend-right"
                                                                            style={{
                                                                                minHeight: '300px'
                                                                            }}
                                                                            data-sync-height="height-1"><div
                                                                                class="gdlr-core-pbf-background-wrap"><div
                                                                                    class="gdlr-core-pbf-background
                                                    gdlr-core-parallax
                                                    gdlr-core-js"
                                                                                    style={{
                                                                                        backgroundImage:
                                                    "url('assets/wp-content/uploads/2022/07/Employee-Loan-Management.jpg')",
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center'
                                                                                    }} data-parallax-speed="0.2"></div></div><div
                                            class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"></div></div></div></div></div></div><div
                        class="gdlr-core-pbf-wrapper" style={{
                            padding: '0px 0px 0px 0px'
                        }} data-skin="Dark"><div
                            class="gdlr-core-pbf-background-wrap"
                            style={{
                                backgroundColor: '#0ea08a'
                            }}></div><div
                                class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js"><div
                                class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                    class="gdlr-core-pbf-column
                                        gdlr-core-column-30
                                        gdlr-core-column-first"
                                    id="gdlr-core-column-20188"><div
                                        class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js
                                            gdlr-core-column-extend-left"
                                        style={{
                                            minHeight: '300px'
                                        }}
                                        data-sync-height="height-1"
                                        data-sync-height-center><div
                                            class="gdlr-core-pbf-background-wrap"><div
                                                class="gdlr-core-pbf-background
                                                    gdlr-core-parallax
                                                    gdlr-core-js"
                                                style={{
                                                    backgroundImage:
                                                    "url('assets/wp-content/uploads/2022/07/Employee-leave-management1.png')",
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center'
                                                }} data-parallax-speed="0.2"></div></div><div
                                            class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"></div></div></div><div
                                    class="gdlr-core-pbf-column
                                        gdlr-core-column-30" data-skin="White
                                        Text"><div
                                        class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js"style={{
                                                padding: '140px  0px 70px 70px'
                                            }}
                                        data-sync-height="height-1"
                                        data-sync-height-center><div
                                            class="gdlr-core-pbf-background-wrap"></div><div
                                                class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"
                                                data-gdlr-animation="fadeInRight"
                                                data-gdlr-animation-duration="600ms"
                                                data-gdlr-animation-offset="0.8"><div
                                                    class="gdlr-core-pbf-element"><div
                                                        class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-title-item-caption-top
                                                        gdlr-core-item-pdlr"><div
                                                        class="gdlr-core-title-item-title-wrap"><h3
                                                            class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title"style={{
                                                                    letterSpacing:  '0px',
                                                                textTransform: 'none'
                                                                }}>Employee
                                                            leave management<span
                                                                class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h3></div></div></div><div
                                                class="gdlr-core-pbf-element"><div
                                                    class="gdlr-core-icon-list-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-style-1"><ul><li
                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                            class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                            class="gdlr-core-icon-list-content-wrap"><span
                                                                class="gdlr-core-icon-list-content">Entitlement</span></div></li><li
                                                                    class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Leave
                                                                    Balances</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Transaction</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Leave
                                                                    carry
                                                                    forward</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Monthly
                                                                    leave
                                                                    balance</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Leave
                                                                    absent
                                                                    report</span></div></li></ul></div></div></div></div></div></div></div></div><div
                                                                        class="gdlr-core-pbf-wrapper" style={{
                                                                            padding: '0px 0px 0px 0px'
                                                                        }}>
                                                                            <div class="gdlr-core-pbf-background-wrap"></div><div
                            class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js"><div
                                class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                    class="gdlr-core-pbf-column
                                        gdlr-core-column-30
                                        gdlr-core-column-first"><div
                                        class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js" style={{
                                                padding: '140px 70px 70px 0px'
                                            }}
                                        data-sync-height="height-1"
                                        data-sync-height-center><div
                                            class="gdlr-core-pbf-background-wrap"></div><div
                                                class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"
                                                data-gdlr-animation="fadeInLeft"
                                                data-gdlr-animation-duration="600ms"
                                                data-gdlr-animation-offset="0.8"><div
                                                    class="gdlr-core-pbf-element"><div
                                                        class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-title-item-caption-bottom
                                                        gdlr-core-item-pdlr"><div
                                                        class="gdlr-core-title-item-title-wrap"><h3
                                                            class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title"style={{
                                                                    textTransform: 'none'
                                                                }}>Statutory
                                                            deduction<span
                                                                class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h3></div></div></div><div
                                                class="gdlr-core-pbf-element"><div
                                                    class="gdlr-core-icon-list-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-style-1"><ul><li
                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                            class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                            class="gdlr-core-icon-list-content-wrap"><span
                                                                class="gdlr-core-icon-list-content">Automatic
                                                                calculation
                                                                of PAYE
                                                                deductions
                                                                in
                                                                accordance
                                                                with
                                                                government
                                                                policies
                                                            </span></div></li><li
                                                                class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Employers
                                                                    and
                                                                    employee
                                                                    pension
                                                                    contribution
                                                                    deduction</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">NSITF
                                                                    and
                                                                    other
                                                                    statutory
                                                                    deduction</span></div></li></ul></div></div></div></div></div><div
                                                                        class="gdlr-core-pbf-column
                                        gdlr-core-column-30"
                                                                        id="gdlr-core-column-74160"><div
                                                                            class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js
                                            gdlr-core-column-extend-right"
                                                                            style={{
                                                                                minHeight: '300px'
                                                                            }}
                                                                            data-sync-height="height-1"><div
                                                                                class="gdlr-core-pbf-background-wrap"><div
                                                                                    class="gdlr-core-pbf-background
                                                    gdlr-core-parallax
                                                    gdlr-core-js"
                                                    style={{
                                                        backgroundImage:
                                                    "url('assets/wp-content/uploads/2022/07/Statutory-deduction.jpg')",
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center'
                                                    }} data-parallax-speed="0.2"></div></div><div
                                            class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"></div></div></div></div></div></div><div
                        class="gdlr-core-pbf-wrapper" style={{
                            padding: '0px 0px 0px 0px'
                        }} data-skin="Dark"><div
                            class="gdlr-core-pbf-background-wrap"
                            style={{
                                backgroundColor: '#00112d'
                            }}></div><div
                                class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js"><div
                                class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                    class="gdlr-core-pbf-column
                                        gdlr-core-column-30
                                        gdlr-core-column-first"
                                    id="gdlr-core-column-75972"><div
                                        class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js
                                            gdlr-core-column-extend-left"
                                        style={{
                                            minHeight: '300px'
                                        }}
                                        data-sync-height="height-1"
                                        data-sync-height-center><div
                                            class="gdlr-core-pbf-background-wrap"><div
                                                class="gdlr-core-pbf-background
                                                    gdlr-core-parallax
                                                    gdlr-core-js"
                                                style={{
                                                    backgroundImage:
                                                    "url('assets/wp-content/uploads/2022/07/Biometric-1.jpg')",
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center'
                                                }} data-parallax-speed="0.2"></div></div><div
                                            class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"></div></div></div><div
                                    class="gdlr-core-pbf-column
                                        gdlr-core-column-30" data-skin="White
                                        Text"><div
                                        class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js" style={{
                                                padding: '140px 0px 70px 70px'
                                            }}
                                        data-sync-height="height-1"
                                        data-sync-height-center><div
                                            class="gdlr-core-pbf-background-wrap"></div><div
                                                class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"
                                                data-gdlr-animation="fadeInRight"
                                                data-gdlr-animation-duration="600ms"
                                                data-gdlr-animation-offset="0.8"><div
                                                    class="gdlr-core-pbf-element"><div
                                                        class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-title-item-caption-top
                                                        gdlr-core-item-pdlr"><div
                                                        class="gdlr-core-title-item-title-wrap"><h3
                                                            class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title" style={{
                                                                    letterSpacing: '0px',
                                                                textTransform: 'none'
                                                                }} >Biometric
                                                            Machine
                                                            Integration with
                                                            vPay Solution
                                                            Software<span
                                                                class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h3></div></div></div><div
                                                class="gdlr-core-pbf-element"><div
                                                    class="gdlr-core-icon-list-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-style-1"><ul><li
                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                            class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                            class="gdlr-core-icon-list-content-wrap"><span
                                                                class="gdlr-core-icon-list-content">Biometric
                                                                Device
                                                                Data
                                                                Extract</span></div></li><li
                                                                    class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Daily
                                                                    Device
                                                                    Attendance
                                                                    Extraction</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Warnings</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Manual
                                                                    Internal
                                                                    Data
                                                                    Process</span></div></li></ul></div></div></div></div></div></div></div></div><div
                                                                        class="gdlr-core-pbf-wrapper" style={{
                                                                            padding: '0px 0px  0px 0px'
                                                                        }}><div class="gdlr-core-pbf-background-wrap"></div><div
                            class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js"><div
                                class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                    class="gdlr-core-pbf-column
                                        gdlr-core-column-30
                                        gdlr-core-column-first"
                                    id="gdlr-core-column-58026"><div
                                        class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js" style={{
                                                padding: '100px 70px 70px 0px'
                                            }}
                                        data-sync-height="height-1"
                                        data-sync-height-center><div
                                            class="gdlr-core-pbf-background-wrap"></div><div
                                                class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"
                                                data-gdlr-animation="fadeInLeft"
                                                data-gdlr-animation-duration="600ms"
                                                data-gdlr-animation-offset="0.8"><div
                                                    class="gdlr-core-pbf-element"><div
                                                        class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-title-item-caption-bottom
                                                        gdlr-core-item-pdlr"><div
                                                        class="gdlr-core-title-item-title-wrap"><h3
                                                            class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title"style={{
                                                                    textTransform: 'none'
                                                                }}>Exit-Separation
                                                            Notification and
                                                            verification<span
                                                                class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h3></div></div></div><div
                                                class="gdlr-core-pbf-element"><div
                                                    class="gdlr-core-icon-list-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-style-1"><ul><li
                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                            class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                            class="gdlr-core-icon-list-content-wrap"><span
                                                                class="gdlr-core-icon-list-content">Employee
                                                                can
                                                                apply
                                                                online
                                                                application
                                                                &
                                                                Notification
                                                                to
                                                                superior
                                                                and one
                                                                approval</span></div></li><li
                                                                    class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Exit
                                                                    Module
                                                                    feedback
                                                                    and Full
                                                                    and
                                                                    final
                                                                    settlement.</span></div></li></ul></div></div><div
                                                                        class="gdlr-core-pbf-element"><div
                                                                            class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-title-item-caption-bottom
                                                        gdlr-core-item-pdlr"><div
                                                        class="gdlr-core-title-item-title-wrap"><h3
                                                            class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title"style={{
                                                                    fontSize: '20px',
                                                                textTransform:'none'
                                                                }}>Web-based
                                                            solution &
                                                            User-friendly
                                                            interface
                                                            Software Value
                                                            Additions:<span
                                                                class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h3></div></div></div><div
                                                class="gdlr-core-pbf-element"><div
                                                    class="gdlr-core-icon-list-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-style-1"><ul><li
                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                            class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                            class="gdlr-core-icon-list-content-wrap"><span
                                                                class="gdlr-core-icon-list-content">Easy
                                                                Data
                                                                Migration</span></div></li><li
                                                                    class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Export
                                                                    to
                                                                    Excel/PDF
                                                                    from all
                                                                    the
                                                                    Pages
                                                                    and
                                                                    Reports</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Flexible
                                                                    to
                                                                    integrate
                                                                    with
                                                                    Biometric
                                                                    device,
                                                                    Face
                                                                    recognition
                                                                    system,
                                                                    Legacy
                                                                    system,
                                                                    ERP
                                                                    systems</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Easy
                                                                    new
                                                                    features
                                                                    or
                                                                    remove
                                                                    any
                                                                    existing
                                                                    feature</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Easy
                                                                    to learn
                                                                    &
                                                                    operate
                                                                    by
                                                                    non-Computer
                                                                    background
                                                                    Employees.</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Advance
                                                                    Search
                                                                    facility</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Powerful
                                                                    reporting
                                                                    tool</span></div></li><li
                                                                        class="gdlr-core-skin-divider
                                                                gdlr-core-with-hover
                                                                clearfix"><span
                                                                class="gdlr-core-icon-list-icon-wrap
                                                                    gdlr-core-left"><i
                                                                    class="gdlr-core-icon-list-icon-hover
                                                                        fa
                                                                        fa-check"></i><i
                                                                    class="gdlr-core-icon-list-icon
                                                                        fa
                                                                        fa-check"></i></span><div
                                                                class="gdlr-core-icon-list-content-wrap"><span
                                                                    class="gdlr-core-icon-list-content">Task
                                                                    Alert
                                                                    and
                                                                    Reminders</span></div></li></ul></div></div></div></div></div><div
                                                                        class="gdlr-core-pbf-column
                                        gdlr-core-column-30"
                                                                        id="gdlr-core-column-22208"><div
                                                                            class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js
                                            gdlr-core-column-extend-right"
                                                                            style={{
                                                                                minHeight: '300px'
                                                                            }}
                                                                            data-sync-height="height-1"><div
                                                                                class="gdlr-core-pbf-background-wrap"><div
                                                                                    class="gdlr-core-pbf-background
                                                    gdlr-core-parallax
                                                    gdlr-core-js"
                                                                                    style={{
                                                                                        backgroundImage:
                                                    "url('assets/wp-content/uploads/2022/07/Notification-and-verification.png')",
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center'
                                                                                    }} data-parallax-speed="0.2"></div></div><div
                                            class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js
                                                gdlr-core-sync-height-content"></div></div></div></div></div></div><div
                        class="gdlr-core-pbf-wrapper" data-skin="White Text"><div
                            class="gdlr-core-pbf-background-wrap"
                            style={{
                                backgroundColor: '#4256c2'
                            }}></div><div
                                class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js"><div
                                class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                    class="gdlr-core-pbf-column
                                        gdlr-core-column-60
                                        gdlr-core-column-first"><div
                                        class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js"><div
                                            class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js"><div
                                                class="gdlr-core-pbf-element"><div
                                                    class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-center-align
                                                        gdlr-core-title-item-caption-bottom
                                                        gdlr-core-item-pdlr"><div
                                                        class="gdlr-core-title-item-title-wrap"><h3
                                                            class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title" style={{
                                                                    textTransform:'none'
                                                                }}>The
                                                            payroll software
                                                            has<span
                                                                class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h3></div><span
                                                        class="gdlr-core-title-item-caption
                                                            gdlr-core-info-font
                                                            gdlr-core-skin-caption"
                                                        style={{
                                                            fontStyle: 'normal'
                                                        }}>A
                                                        Role-based privilege
                                                        easy to setup for
                                                        employee role and
                                                        authority</span>
                                                        </div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                        
                    </div>
                </div>
            </div>


            <Footer />

        </div>
    )
}
export default Vpay;