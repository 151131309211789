import React from "react";
import Footer from "../partial/footer";
import NavBar from "../partial/navbar";
import './digital.css'

function Digital(){
    return(
        <div>
            <NavBar/>


            <div class="infinite-page-wrapper"
                    id="infinite-page-wrapper"><div
                        class="gdlr-core-page-builder-body"><div
                            class="gdlr-core-pbf-wrapper"><div
                                class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js"><div
                                    class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                        class="gdlr-core-pbf-column
                                        gdlr-core-column-60
                                        gdlr-core-column-first"><div
                                            class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js"><div
                                                class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js"><div
                                                    class="gdlr-core-pbf-element"><div
                                                        class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-left-align
                                                        gdlr-core-title-item-caption-top
                                                        gdlr-core-item-pdlr"><div
                                                            class="gdlr-core-title-item-title-wrap"><h1
                                                                class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title"style={{
                                                                    fontSize:'25px',
                                                                textTransform: 'none'
                                                                }}>Digital
                                                                Marketing
                                                                Manager<span
                                                                    class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h1></div></div></div></div></div></div><div
                                        class="gdlr-core-pbf-element"><div
                                            class="gdlr-core-text-box-item
                                            gdlr-core-item-pdlr
                                            gdlr-core-item-pdb
                                            gdlr-core-left-align"><div
                                                class="gdlr-core-text-box-item-content"
                                                style={{
                                                    textTransform: 'none'
                                                }}><h5>Job
                                                    description</h5>
                                                <p>The
                                                    Digital Marketing Manager is
                                                    responsible for implementing
                                                    paid digital media
                                                    strategies for clients. He/
                                                    She can then quickly
                                                    understand, manage, and
                                                    support initiatives that
                                                    will contribute to the goals
                                                    and success of client
                                                    campaigns.</p>
                                                <p><strong>Key Functional Areas
                                                        of Responsibility:</strong></p>
                                                <ul>
                                                    <li>Manage, review, and
                                                        perform daily account
                                                        responsibilities
                                                        associated with Google
                                                        AdWords, Facebook,
                                                        Instagram etc. and other
                                                        search platforms for a
                                                        variety of clients.</li>
                                                    <li>Maintain and monitor
                                                        keyword bids, account
                                                        daily and monthly budget
                                                        caps, impression share,
                                                        quality score and other
                                                        important account
                                                        metrics across search
                                                        and social channels.</li>
                                                    <li>Provide creative copy
                                                        suggestions and
                                                        graphical ad templates.</li>
                                                    <li>Manage Display network
                                                        placement lists on
                                                        AdWords and through
                                                        other contextual
                                                        advertising platforms.</li>
                                                    <li>Provide recommendations
                                                        and execute strategies
                                                        for keyword
                                                        opportunities, campaign
                                                        structuring, targeting,
                                                        display network, and
                                                        other facets of paid
                                                        search in accordance
                                                        with client goals.</li>
                                                    <li>Provide oversight and
                                                        manage new paid search
                                                        campaigns, ad groups,
                                                        and accounts and aid in
                                                        the creation of paid
                                                        search marketing
                                                        initiatives.</li>
                                                    <li>Provide oversight,
                                                        manage, and be able to
                                                        generate weekly and
                                                        monthly client reporting
                                                        for all major metrics,
                                                        goals tracking, revenue
                                                        tracking, and other paid
                                                        search initiatives.</li>
                                                    <li>Keep pace with search
                                                        engines, social channels
                                                        and PPC industry trends
                                                        and developments.</li>
                                                    <li>Monitor and administer
                                                        web analytics
                                                        dashboards, reports and
                                                        key reporting tools, and
                                                        point out key areas of
                                                        importance in accordance
                                                        with client goals.</li>
                                                    <li>Monitor and evaluate
                                                        results &amp;
                                                        performance across the
                                                        major paid digital
                                                        channels.</li>
                                                    <li>Communication to team
                                                        and management on
                                                        project development,
                                                        timelines, and results.</li>
                                                    <li>Provide oversight and
                                                        work closely with the
                                                        other team members to
                                                        meet client goals.</li>
                                                </ul>
                                            </div></div></div><div
                                        class="gdlr-core-pbf-element"><div
                                            class="gdlr-core-text-box-item
                                            gdlr-core-item-pdlr
                                            gdlr-core-item-pdb
                                            gdlr-core-left-align"><div
                                                class="gdlr-core-text-box-item-content"
                                                style={{
                                                    textTransform: 'none'
                                                }}><p><br/><strong>Role:</strong>
                                                    Media Planning<br />
                                                    <strong>Industry Type:
                                                    </strong>Advertising &amp;
                                                    Marketing<br />
                                                    <strong>Functional Area:
                                                    </strong>Marketing &amp;
                                                    Communication<br />
                                                    <strong>Employment Type:
                                                    </strong>Full Time,
                                                    Permanent<br />
                                                    <strong>Role Category:
                                                    </strong>Advertising &amp;
                                                    Creative</p>
                                                <h5>Education</h5>
                                                <p><strong>UG:</strong> Any
                                                    Graduate<br />
                                                    <strong>PG:</strong> Post
                                                    Graduation Not Required<br
                                                        />
                                                    <strong>Doctorate:</strong>
                                                    Doctorate Not Required</p>
                                                <h5>Key Skills</h5>
                                                <p> Performance
                                                    marketing, Team Management,
                                                    PPC, Media buying, Google
                                                    Adwords, E-commerce, Digital
                                                    Strategy, Digital Marketing,
                                                    Facebook Marketing, Social
                                                    Media Marketing</p>
                                            </div></div></div></div></div></div></div></div>

            <Footer/>

        </div>
    )
}
export default Digital;