// import React, { useEffect, useState }from'react';
import Footer from '../partial/footer';
import NavBar from '../partial/navbar';
import './OnlineRM.css'


function OnlineRM() {

    return (
        <div>
            <NavBar />
            <div class="infinite-page-wrapper"
                id="infinite-page-wrapper">
                <div
                    class="gdlr-core-page-builder-body">
                    <div
                        class="gdlr-core-pbf-wrapper" style={{
                            padding: '90px 0px 90px 0px'
                        }} data-skin="Dark">
                        <div
                            class="gdlr-core-pbf-background-wrap">
                            <div
                                class="gdlr-core-pbf-background
                                    gdlr-core-parallax gdlr-core-js"
                                    style={{
                                        backgroundImage:"url('assets/wp-content/uploads/2022/07/locobuzz.jpg')",
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center'
                                            }}
                                data-parallax-speed="0.8">
                            </div>
                        </div>

                    </div>

                    <div
                        class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js">
                        <div
                            class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container">

                            <div
                                class="gdlr-core-pbf-element">
                                <div
                                    class="gdlr-core-title-item
                                            gdlr-core-item-pdb clearfix
                                            gdlr-core-left-align
                                            gdlr-core-title-item-caption-top
                                            gdlr-core-item-pdlr">
                                    <div
                                        class="gdlr-core-title-item-title-wrap">
                                        <h1
                                            class="gdlr-core-title-item-title
                                                    gdlr-core-skin-title" style={{
                                                        fontSize:'45px',
                                                        fontWeight: '700',
                                                        color: '#0a0a0a'
                                                    }}>Online
                                            Reputation <br /> Management<span
                                                class="gdlr-core-title-item-title-divider
                                                            gdlr-core-skin-divider"></span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="gdlr-core-pbf-element"><div
                                    class="gdlr-core-text-box-item
                                                gdlr-core-item-pdlr
                                                gdlr-core-item-pdb
                                                gdlr-core-left-align"
                                    style={{
                                        paddingBottom: '0px'
                                    }}><div
                                        class="gdlr-core-text-box-item-content"
                                        style={{
                                            textTransform: 'none',
                                            color: '#050d1a'
                                        }}><p>The
                                            Complete Customer
                                            Experience Management
                                            Suite</p>
                                    </div></div></div>
                            <div
                                class="gdlr-core-pbf-element"><div
                                    class="gdlr-core-text-box-item
                                                gdlr-core-item-pdlr
                                                gdlr-core-item-pdb
                                                gdlr-core-left-align"
                                    style={{
                                        paddingBottom: '0px'
                                    }}><div
                                        class="gdlr-core-text-box-item-content"
                                        style={{
                                            textTransform: 'none',
                                            color: '#050d1a'
                                        }}><p><button
                                            class="popmake-209">
                                            REQUEST A DEMO</button></p>
                                    </div></div></div>


                        </div>
                    </div>
                </div>
                <div class="gdlr-core-pbf-wrapper"style={{
                    padding: '30px 0px 30px 0px'
                }}><div
                                    class="gdlr-core-pbf-background-wrap">
                                </div>
                                <div
                                    class="gdlr-core-pbf-wrapper-content
                                    gdlr-core-js"><div
                                        class="gdlr-core-pbf-wrapper-container
                                        clearfix gdlr-core-container"><div
                                            class="gdlr-core-pbf-column
                                            gdlr-core-column-30
                                            gdlr-core-column-first"
                                            id="gdlr-core-column-9869"><div
                                                class="gdlr-core-pbf-column-content-margin
                                                gdlr-core-js" style={{
                                                    padding: '0px 0px 0px 0px'
                                                }}><div
                                                    class="gdlr-core-pbf-background-wrap"></div><div
                                                    class="gdlr-core-pbf-column-content
                                                    clearfix gdlr-core-js"><div
                                                        class="gdlr-core-pbf-element"><div
                                                            class="gdlr-core-image-item
                                                            gdlr-core-item-pdb
                                                            gdlr-core-center-align
                                                            gdlr-core-item-pdlr"
                                                            style={{
                                                                paddingBottom:'0px'
                                                            }}><div
                                                                class="gdlr-core-image-item-wrap
                                                                gdlr-core-media-image
                                                                gdlr-core-image-item-style-rectangle"
                                                                style={{
                                                                    borderWidth:'0px'
                                                                }}><img
                                                                    src="assets/wp-content/uploads/2022/07/img_single.webp"
                                                                    alt=""
                                                                    width="720"
                                                                    height="592"
                                                                    title="img_single"
                                                                    /></div></div></div></div></div></div><div
                                            class="gdlr-core-pbf-column
                                            gdlr-core-column-30"
                                            id="gdlr-core-column-95392"><div
                                                class="gdlr-core-pbf-column-content-margin
                                                gdlr-core-js" style={{
                                                    padding: '80px 70px 0px 0px'
                                                }}
                                                data-sync-height="height-1"
                                                data-sync-height-center><div
                                                    class="gdlr-core-pbf-background-wrap"></div><div
                                                    class="gdlr-core-pbf-column-content
                                                    clearfix gdlr-core-js
                                                    gdlr-core-sync-height-content"
                                                    data-gdlr-animation="fadeInLeft"
                                                    data-gdlr-animation-duration="600ms"
                                                    data-gdlr-animation-offset="0.8"><div
                                                        class="gdlr-core-pbf-element"><div
                                                            class="gdlr-core-title-item
                                                            gdlr-core-item-pdb
                                                            clearfix
                                                            gdlr-core-left-align
                                                            gdlr-core-title-item-caption-top
                                                            gdlr-core-item-pdlr"><div
                                                                class="gdlr-core-title-item-title-wrap"><h3
                                                                    class="gdlr-core-title-item-title
                                                                    gdlr-core-skin-title"  style={{
                                                                        fontSize:'35px',
                                                                        letterSpacing:'0px',
                                                                        textTransform:'none'
                                                                    }}>A
                                                                    unified view
                                                                    of your
                                                                    brand,
                                                                    consumers,
                                                                    communications,
                                                                    performance,
                                                                    presence,
                                                                    appeal!<span
                                                                        class="gdlr-core-title-item-title-divider
                                                                        gdlr-core-skin-divider"></span></h3>
                                                                        </div>
                                                                        </div>
                                                                        </div>
                                                    <div
                                                        class="gdlr-core-pbf-element"><div
                                                            class="gdlr-core-text-box-item
                                                            gdlr-core-item-pdlr
                                                            gdlr-core-item-pdb
                                                            gdlr-core-left-align"><div
                                                                class="gdlr-core-text-box-item-content"
                                                                style={{
                                                                    fontSize:'18px',
                                                                    textTransform:'none'
                                                                }}><p>In
                                                                    a
                                                                    single-view,
                                                                    Online
                                                                    Reputation
                                                                    Management
                                                                    helps brands
                                                                    acutely
                                                                    measure
                                                                    brand
                                                                    insights,
                                                                    customer
                                                                    behaviour,
                                                                    competitor
                                                                    insights and
                                                                    performance
                                                                    analytics
                                                                    across all
                                                                    key digital
                                                                    channels to
                                                                    give
                                                                    empowering
                                                                    business
                                                                    insights on
                                                                    a unified CX
                                                                    management
                                                                    platform.</p>
                                                            </div></div></div></div></div></div></div>

                            </div>
                            
            </div>
            <div
                                class="gdlr-core-pbf-wrapper" style={{
                                    padding:'60px 0px 60px 0px'
                                }}><div
                                    class="gdlr-core-pbf-background-wrap"
                                    style={{
                                        backgroundColor: '#e7f7d2'
                                    }}></div><div
                                    class="gdlr-core-pbf-wrapper-content
                                    gdlr-core-js"><div
                                        class="gdlr-core-pbf-wrapper-container
                                        clearfix gdlr-core-container"><div
                                            class="gdlr-core-pbf-column
                                            gdlr-core-column-60
                                            gdlr-core-column-first"><div
                                                class="gdlr-core-pbf-column-content-margin
                                                gdlr-core-js"><div
                                                    class="gdlr-core-pbf-column-content
                                                    clearfix gdlr-core-js"><div
                                                        class="gdlr-core-pbf-element"><div
                                                            class="gdlr-core-title-item
                                                            gdlr-core-item-pdb
                                                            clearfix
                                                            gdlr-core-center-align
                                                            gdlr-core-title-item-caption-bottom
                                                            gdlr-core-item-pdlr"><div
                                                                class="gdlr-core-title-item-title-wrap"><h3
                                                                    class="gdlr-core-title-item-title
                                                                    gdlr-core-skin-title" style={{
                                                                        fontSize:'35px',
                                                                        textTransform:'none'
                                                                    }}><b>A
                                                                        complete</b>
                                                                    customer
                                                                    experience
                                                                    management
                                                                    <b>platform</b><span
                                                                        class="gdlr-core-title-item-title-divider
                                                                        gdlr-core-skin-divider"></span></h3></div><span
                                                                class="gdlr-core-title-item-caption
                                                                gdlr-core-info-font
                                                                gdlr-core-skin-caption"
                                                                style={{
                                                                    fontStyle:'normal'
                                                                }}>Your
                                                                brand, your
                                                                customers, your
                                                                competitors,
                                                                your
                                                                organization –
                                                                leverage the
                                                                power of AI-tech
                                                                to seamlessly
                                                                integrate data
                                                                and resources to
                                                                be the best
                                                                brand with the
                                                                best customer
                                                                service.</span></div></div></div></div></div><div
                                            class="gdlr-core-pbf-column
                                            gdlr-core-column-60
                                            gdlr-core-column-first"><div
                                                class="gdlr-core-pbf-column-content-margin
                                                gdlr-core-js"><div
                                                    class="gdlr-core-pbf-column-content
                                                    clearfix gdlr-core-js"><div
                                                        class="gdlr-core-pbf-element"><div
                                                            class="gdlr-core-tab-feature3-item
                                                            gdlr-core-js
                                                            gdlr-core-item-pdb
                                                            gdlr-core-item-pdlr
                                                            gdlr-core-size-5"><div
                                                                class="gdlr-core-tab-feature3-title-item-wrap
                                                                clearfix"><div
                                                                    class="gdlr-core-tab-feature3-title-wrap
                                                                    gdlr-core-column-12
                                                                    gdlr-core-active
                                                                    gdlr-core-js"
                                                                    data-tab-id="1"><h3
                                                                        class="gdlr-core-tab-feature3-title">listen</h3></div><div
                                                                    class="gdlr-core-tab-feature3-title-wrap
                                                                    gdlr-core-column-12
                                                                    gdlr-core-js"
                                                                    data-tab-id="2"><h3
                                                                        class="gdlr-core-tab-feature3-title">RESPOND</h3></div><div
                                                                    class="gdlr-core-tab-feature3-title-wrap
                                                                    gdlr-core-column-12
                                                                    gdlr-core-js"
                                                                    data-tab-id="3"><h3
                                                                        class="gdlr-core-tab-feature3-title">ANALYSE</h3></div><div
                                                                    class="gdlr-core-tab-feature3-title-wrap
                                                                    gdlr-core-column-12
                                                                    gdlr-core-js"
                                                                    data-tab-id="4"><h3
                                                                        class="gdlr-core-tab-feature3-title">PUBLISH</h3></div><div
                                                                    class="gdlr-core-tab-feature3-title-wrap
                                                                    gdlr-core-column-12
                                                                    gdlr-core-js"
                                                                    data-tab-id="5"><h3
                                                                        class="gdlr-core-tab-feature3-title">AUTOMATE</h3></div><div
                                                                    class="gdlr-core-tab-feature3-bottom-slide-bar"><div
                                                                        class="gdlr-core-tab-feature3-bottom-slide-bar-border"></div></div></div><div
                                                                class="gdlr-core-tab-feature3-item-content-wrap
                                                                clearfix"><div
                                                                    class="gdlr-core-tab-feature3-content-wrap
                                                                    gdlr-core-container
                                                                    gdlr-core-active
                                                                    gdlr-core-js"
                                                                    data-tab-id="1"><div
                                                                        class="gdlr-core-tab-feature3-content-right
                                                                        gdlr-core-media-image"><img
                                                                            src="assets/wp-content/uploads/2022/07/listen.webp"
                                                                            alt=""
                                                                            width="606"
                                                                            height="400"
                                                                            title="listen"
                                                                            /></div><div
                                                                        class="gdlr-core-tab-feature3-content"><div
                                                                            class="gdlr-core-tab-feature3-content-text"><p>Get
                                                                                real-time
                                                                                understanding
                                                                                of
                                                                                your
                                                                                customers,
                                                                                competitors,
                                                                                campaigns
                                                                                and
                                                                                influencers
                                                                                through
                                                                                the
                                                                                power
                                                                                of
                                                                                modern
                                                                                social
                                                                                listening.</p>
                                                                            <p><br/>Capture
                                                                                trends,
                                                                                market
                                                                                shifts,
                                                                                and
                                                                                critical
                                                                                social
                                                                                data
                                                                                to
                                                                                derive
                                                                                actionable
                                                                                insights
                                                                                that
                                                                                improve
                                                                                brand
                                                                                health,
                                                                                strategies
                                                                                and
                                                                                overall
                                                                                digital
                                                                                presence.</p>
                                                                        </div></div></div><div
                                                                    class="gdlr-core-tab-feature3-content-wrap
                                                                    gdlr-core-container
                                                                    gdlr-core-js"
                                                                    data-tab-id="2"><div
                                                                        class="gdlr-core-tab-feature3-content-right
                                                                        gdlr-core-media-image"><img
                                                                            src="assets/wp-content/uploads/2022/07/response.webp"
                                                                            alt=""
                                                                            width="606"
                                                                            height="400"
                                                                            title="response"
                                                                            /></div><div
                                                                        class="gdlr-core-tab-feature3-content"><div
                                                                            class="gdlr-core-tab-feature3-content-text"><p>The
                                                                                easy-to-use
                                                                                workflows
                                                                                help
                                                                                manage
                                                                                your
                                                                                customers
                                                                                with
                                                                                increased
                                                                                responsiveness
                                                                                and
                                                                                effective
                                                                                engagement.</p>
                                                                            <p>The
                                                                                Locobuzz
                                                                                CX
                                                                                platform
                                                                                streamlines
                                                                                data-inflow,
                                                                                assigns
                                                                                cases
                                                                                and
                                                                                manage
                                                                                tasks
                                                                                seamlessly
                                                                                while
                                                                                you
                                                                                focus
                                                                                on
                                                                                increasing
                                                                                engagement
                                                                                productivity.</p>
                                                                        </div></div></div><div
                                                                    class="gdlr-core-tab-feature3-content-wrap
                                                                    gdlr-core-container
                                                                    gdlr-core-js"
                                                                    data-tab-id="3"><div
                                                                        class="gdlr-core-tab-feature3-content-right
                                                                        gdlr-core-media-image"><img
                                                                            src="assets/wp-content/uploads/2022/07/analyse-2.webp"
                                                                            alt=""
                                                                            width="606"
                                                                            height="400"
                                                                            title="analyse-2"
                                                                            /></div><div
                                                                        class="gdlr-core-tab-feature3-content"><div
                                                                            class="gdlr-core-tab-feature3-content-text"><p>Derive
                                                                                insights
                                                                                that
                                                                                are
                                                                                rich,
                                                                                relevant
                                                                                and
                                                                                measurable
                                                                                for
                                                                                strategic
                                                                                and
                                                                                actionable
                                                                                business
                                                                                decisions.</p>
                                                                            <p> <br/>Measure,
                                                                                compare
                                                                                and
                                                                                study
                                                                                your
                                                                                brand’s
                                                                                ecosystem
                                                                                in
                                                                                a
                                                                                series
                                                                                of
                                                                                versatile,
                                                                                flexible
                                                                                and
                                                                                automated
                                                                                reports
                                                                                and
                                                                                craft
                                                                                a
                                                                                powerful
                                                                                marketing
                                                                                roadmap
                                                                                for
                                                                                the
                                                                                future!</p>
                                                                        </div></div></div><div
                                                                    class="gdlr-core-tab-feature3-content-wrap
                                                                    gdlr-core-container
                                                                    gdlr-core-js"
                                                                    data-tab-id="4"><div
                                                                        class="gdlr-core-tab-feature3-content-right
                                                                        gdlr-core-media-image"><img
                                                                            src="assets/wp-content/uploads/2022/07/publish-2.webp"
                                                                            alt=""
                                                                            width="606"
                                                                            height="400"
                                                                            title="publish-2"
                                                                            /></div><div
                                                                        class="gdlr-core-tab-feature3-content"><div
                                                                            class="gdlr-core-tab-feature3-content-text"><p>Schedule,
                                                                                preview,
                                                                                and
                                                                                publish
                                                                                your
                                                                                communications
                                                                                across
                                                                                all
                                                                                social
                                                                                channels
                                                                                from
                                                                                a
                                                                                single
                                                                                interface.</p>
                                                                            <p>Plan
                                                                                your
                                                                                content
                                                                                on
                                                                                a
                                                                                centralised
                                                                                calendar,
                                                                                easy-to-access
                                                                                content
                                                                                library
                                                                                and
                                                                                manage
                                                                                social
                                                                                campaigns
                                                                                for
                                                                                your
                                                                                brand
                                                                                effortlessly.</p>
                                                                        </div></div></div><div
                                                                    class="gdlr-core-tab-feature3-content-wrap
                                                                    gdlr-core-container
                                                                    gdlr-core-js"
                                                                    data-tab-id="5"><div
                                                                        class="gdlr-core-tab-feature3-content-right
                                                                        gdlr-core-media-image"><img
                                                                            src="assets/wp-content/uploads/2022/07/automate.webp"
                                                                            alt=""
                                                                            width="606"
                                                                            height="400"
                                                                            title="automate"
                                                                            /></div><div
                                                                        class="gdlr-core-tab-feature3-content"><div
                                                                            class="gdlr-core-tab-feature3-content-text"><p>AI-led
                                                                                automation
                                                                                saves
                                                                                you
                                                                                from
                                                                                performing
                                                                                time-consuming
                                                                                tasks
                                                                                and
                                                                                helps
                                                                                provide
                                                                                quick
                                                                                assistance
                                                                                to
                                                                                your
                                                                                customers
                                                                                24*7.</p>
                                                                            <p>Leverage
                                                                                the
                                                                                best
                                                                                of
                                                                                the
                                                                                platform’s
                                                                                machine
                                                                                learning
                                                                                capabilities
                                                                                and
                                                                                automated
                                                                                features
                                                                                to
                                                                                analyze
                                                                                and
                                                                                respond
                                                                                to
                                                                                your
                                                                                customers
                                                                                effectively.</p>
                                                                        </div></div></div></div></div></div></div></div></div></div></div>
                                                                        </div>
                                                                        </div>
                                                                        <div
                                class="gdlr-core-pbf-wrapper" style={{
                                    padding:'130px 0px 80px 0px'
                                }}><div
                                    class="gdlr-core-pbf-background-wrap"></div><div
                                    class="gdlr-core-pbf-wrapper-content
                                    gdlr-core-js"><div
                                        class="gdlr-core-pbf-wrapper-container
                                        clearfix gdlr-core-container"><div
                                            class="gdlr-core-pbf-column
                                            gdlr-core-column-60
                                            gdlr-core-column-first"><div
                                                class="gdlr-core-pbf-column-content-margin
                                                gdlr-core-js"><div
                                                    class="gdlr-core-pbf-column-content
                                                    clearfix gdlr-core-js"><div
                                                        class="gdlr-core-pbf-element"><div
                                                            class="gdlr-core-title-item
                                                            gdlr-core-item-pdb
                                                            clearfix
                                                            gdlr-core-center-align
                                                            gdlr-core-title-item-caption-top
                                                            gdlr-core-item-pdlr"><div
                                                                class="gdlr-core-title-item-title-wrap"><h3
                                                                    class="gdlr-core-title-item-title
                                                                    gdlr-core-skin-title" style={{
                                                                        fontSize:'35px',
                                                                        textTransform: 'none'
                                                                    }}><b>Online
                                                                        Reputation
                                                                        Management</b>
                                                                    immense
                                                                    business
                                                                    value <b>to
                                                                        your
                                                                        Customer
                                                                        Experience
                                                                        Management
                                                                        process.</b><span
                                                                        class="gdlr-core-title-item-title-divider
                                                                        gdlr-core-skin-divider"></span></h3></div></div></div></div></div></div>
                                        <div
                                            class="gdlr-core-pbf-column
                                            gdlr-core-column-20
                                            gdlr-core-column-first"><div
                                                class="gdlr-core-pbf-column-content-margin
                                                gdlr-core-js" style={{
                                                    padding:'60px 30px 0px 0px'
                                                }}><div
                                                    class="gdlr-core-pbf-background-wrap"></div><div
                                                    class="gdlr-core-pbf-column-content
                                                    clearfix gdlr-core-js"data-gdlr-animation="fadeInDown"
                                                    data-gdlr-animation-duration="600ms"
                                                    data-gdlr-animation-offset="0.8"><div
                                                        class="gdlr-core-pbf-element"><div
                                                            class="gdlr-core-column-service-item
                                                            gdlr-core-item-pdb
                                                            gdlr-core-left-align
                                                            gdlr-core-column-service-icon-top
                                                            gdlr-core-no-caption
                                                            gdlr-core-item-pdlr"
                                                            style={{
                                                                margin: '0px 0px 10px 0px'
                                                            }}><div
                                                                class="gdlr-core-column-service-media
                                                                gdlr-core-media-image"
                                                                style={{
                                                                    marginTop:'7px'
                                                                }}><img
                                                                    src="assets/wp-content/uploads/2022/07/icon_01_Online-Reputation-Management.webp"
                                                                    alt=""
                                                                    width="80"
                                                                    height="76"
                                                                    title="icon_01_Online-Reputation-Management"
                                                                    /></div><div
                                                                class="gdlr-core-column-service-content-wrapper"><div
                                                                    class="gdlr-core-column-service-title-wrap"><h3
                                                                        class="gdlr-core-column-service-title
                                                                        gdlr-core-skin-title"
                                                                        style={{
                                                                            fontSize: '30px',
                                                                            textTransform:'none'
                                                                        }}>Online
                                                                        Reputation
                                                                        Management</h3></div><div
                                                                    class="gdlr-core-column-service-content"
                                                                    style={{
                                                                        textTransform:'none'
                                                                    }}><p>
                                                                            <br/>Protect
                                                                        your
                                                                        brand
                                                                        equity
                                                                        across
                                                                        all
                                                                        prominent
                                                                        digital
                                                                        channels.
                                                                        Use
                                                                        advanced
                                                                        capabilities
                                                                        like
                                                                        sentiment
                                                                        analysis
                                                                        and
                                                                        specialised
                                                                        alert
                                                                        mechanisms
                                                                        to boost
                                                                        your
                                                                        online
                                                                        ratings.</p>
                                                                </div></div></div></div></div></div></div>
                                        <div
                                            class="gdlr-core-pbf-column
                                            gdlr-core-column-20"><div
                                                class="gdlr-core-pbf-column-content-margin
                                                gdlr-core-js" style={{
                                                    padding:'60px 30px 0px 0px'
                                                }}><div
                                                    class="gdlr-core-pbf-background-wrap"></div><div
                                                    class="gdlr-core-pbf-column-content
                                                    clearfix gdlr-core-js"data-gdlr-animation="fadeInUp"
                                                    data-gdlr-animation-duration="600ms"
                                                    data-gdlr-animation-offset="0.8"><div
                                                        class="gdlr-core-pbf-element"><div
                                                            class="gdlr-core-column-service-item
                                                            gdlr-core-item-pdb
                                                            gdlr-core-left-align
                                                            gdlr-core-column-service-icon-top
                                                            gdlr-core-no-caption
                                                            gdlr-core-item-pdlr"
                                                            style={{
                                                                margin: '0px 0px 10px 0px'
                                                            }}><div
                                                                class="gdlr-core-column-service-media
                                                                gdlr-core-media-image"
                                                                style={{
                                                                    marginTop:'7px'
                                                                }}><img
                                                                    src="assets/wp-content/uploads/2022/07/icon_02_Complete-Omnichannel-solution.webp"
                                                                    alt=""
                                                                    width="80"
                                                                    height="76"
                                                                    title="icon_02_Complete-Omnichannel-solution"
                                                                    /></div><div
                                                                class="gdlr-core-column-service-content-wrapper"><div
                                                                    class="gdlr-core-column-service-title-wrap"><h3
                                                                        class="gdlr-core-column-service-title
                                                                        gdlr-core-skin-title"
                                                                        style={{
                                                                            fontSize: '30px',
                                                                            textTransform:'none'
                                                                        }}>Complete
                                                                        Omnichannel
                                                                        Solution</h3></div><div
                                                                    class="gdlr-core-column-service-content"
                                                                    style={{
                                                                        textTransform:'none'
                                                                    }}><p><br/>Make
                                                                        communication
                                                                        and
                                                                        cross-channel
                                                                        customer
                                                                        assistance
                                                                        easy,
                                                                        effective
                                                                        and in
                                                                        real-time!
                                                                        Manage
                                                                        and
                                                                        measure
                                                                        your
                                                                        brand’s
                                                                        support
                                                                        performance
                                                                        entirely
                                                                        in a
                                                                        single
                                                                        view!</p>
                                                                </div></div></div></div></div></div></div><div
                                            class="gdlr-core-pbf-column
                                            gdlr-core-column-20"><div
                                                class="gdlr-core-pbf-column-content-margin
                                                gdlr-core-js" style={{
                                                    padding:'60px 30px 0px 0px'
                                                }}><div
                                                    class="gdlr-core-pbf-background-wrap"></div><div
                                                    class="gdlr-core-pbf-column-content
                                                    clearfix gdlr-core-js"data-gdlr-animation="fadeInUp"
                                                    data-gdlr-animation-duration="600ms"
                                                    data-gdlr-animation-offset="0.8"><div
                                                        class="gdlr-core-pbf-element"><div
                                                            class="gdlr-core-column-service-item
                                                            gdlr-core-item-pdb
                                                            gdlr-core-left-align
                                                            gdlr-core-column-service-icon-top
                                                            gdlr-core-no-caption
                                                            gdlr-core-item-pdlr"
                                                            style={{
                                                                margin: '0px 0px 10px 0px'
                                                            }}><div
                                                                class="gdlr-core-column-service-media
                                                                gdlr-core-media-image"
                                                                style={{
                                                                    marginTop:'7px'
                                                                }}><img
                                                                    src="assets/wp-content/uploads/2022/07/icon_03_Holistic-Social-Media-Management.webp"
                                                                    alt=""
                                                                    width="80"
                                                                    height="76"
                                                                    title="icon_03_Holistic-Social-Media-Management"
                                                                    /></div><div
                                                                class="gdlr-core-column-service-content-wrapper"><div
                                                                    class="gdlr-core-column-service-title-wrap"><h3
                                                                        class="gdlr-core-column-service-title
                                                                        gdlr-core-skin-title"
                                                                        style={{
                                                                            fontSize: '30px',
                                                                            textTransform:'none'
                                                                        }}>Holistic
                                                                        Social
                                                                        Media
                                                                        Management</h3></div><div
                                                                    class="gdlr-core-column-service-content"
                                                                    style={{
                                                                        textTransform:'none'
                                                                    }}><p>From
                                                                        content
                                                                        strategies
                                                                        to
                                                                        planning,
                                                                        publishing,
                                                                        analytics,
                                                                        and
                                                                        collaborations
                                                                        we give
                                                                        you
                                                                        powerful
                                                                        engagement
                                                                        workflows
                                                                        to
                                                                        redefine
                                                                        your
                                                                        brand’s
                                                                        social
                                                                        media
                                                                        presence.</p>
                                                                </div></div></div></div></div></div></div><div
                                            class="gdlr-core-pbf-column
                                            gdlr-core-column-20
                                            gdlr-core-column-first"><div
                                                class="gdlr-core-pbf-column-content-margin
                                                gdlr-core-js" style={{
                                                    padding:'60px 30px 0px 0px'
                                                }}><div
                                                    class="gdlr-core-pbf-background-wrap"></div><div
                                                    class="gdlr-core-pbf-column-content
                                                    clearfix gdlr-core-js"data-gdlr-animation="fadeInUp"
                                                    data-gdlr-animation-duration="600ms"
                                                    data-gdlr-animation-offset="0.8"><div
                                                        class="gdlr-core-pbf-element"><div
                                                            class="gdlr-core-column-service-item
                                                            gdlr-core-item-pdb
                                                            gdlr-core-left-align
                                                            gdlr-core-column-service-icon-top
                                                            gdlr-core-no-caption
                                                            gdlr-core-item-pdlr"
                                                            style={{
                                                                margin: '0px 0px 10px 0px'
                                                            }}><div
                                                                class="gdlr-core-column-service-media
                                                                gdlr-core-media-image"
                                                                style={{
                                                                    marginTop:'7px'
                                                                }}><img
                                                                    src="assets/wp-content/uploads/2022/07/icon_04_Conversational-commerce-and-chatbots.webp"
                                                                    alt=""
                                                                    width="80"
                                                                    height="76"
                                                                    title="icon_04_Conversational-commerce-and-chatbots"
                                                                    /></div><div
                                                                class="gdlr-core-column-service-content-wrapper"><div
                                                                    class="gdlr-core-column-service-title-wrap"><h3
                                                                        class="gdlr-core-column-service-title
                                                                        gdlr-core-skin-title"
                                                                        style={{
                                                                            fontSize: '30px',
                                                                            textTransform:'none'
                                                                        }}
                                                                        >Conversational
                                                                        Commerce
                                                                        and
                                                                        Chatbots</h3></div><div
                                                                    class="gdlr-core-column-service-content"
                                                                    style={{
                                                                        textTransform:'none'
                                                                    }}><p>Our
                                                                        AI
                                                                        chatbots
                                                                        digitize
                                                                        and
                                                                        automate
                                                                        self-service
                                                                        for
                                                                        customers
                                                                        in
                                                                        real-time.
                                                                        Generate
                                                                        leads
                                                                        and
                                                                        create
                                                                        opportunities
                                                                        for
                                                                        client
                                                                        retention,
                                                                        upselling,
                                                                        cross-selling
                                                                        and much
                                                                        more.</p>
                                                                </div></div></div></div></div></div></div><div
                                            class="gdlr-core-pbf-column
                                            gdlr-core-column-20"><div
                                                class="gdlr-core-pbf-column-content-margin
                                                gdlr-core-js" style={{
                                                    padding:'60px 30px 0px 0px'
                                                }}><div
                                                    class="gdlr-core-pbf-background-wrap"></div><div
                                                    class="gdlr-core-pbf-column-content
                                                    clearfix gdlr-core-js"data-gdlr-animation="fadeInUp"
                                                    data-gdlr-animation-duration="600ms"
                                                    data-gdlr-animation-offset="0.8"><div
                                                        class="gdlr-core-pbf-element"><div
                                                            class="gdlr-core-column-service-item
                                                            gdlr-core-item-pdb
                                                            gdlr-core-left-align
                                                            gdlr-core-column-service-icon-top
                                                            gdlr-core-no-caption
                                                            gdlr-core-item-pdlr"
                                                            style={{
                                                                margin: '0px 0px 10px 0px'
                                                            }}><div
                                                                class="gdlr-core-column-service-media
                                                                gdlr-core-media-image"
                                                                style={{
                                                                    marginTop:'7px'
                                                                }}><img
                                                                    src="assets/wp-content/uploads/2022/07/icon_05_Marketing-Research-and-Insights.webp"
                                                                    alt=""
                                                                    width="80"
                                                                    height="76"
                                                                    title="icon_05_Marketing-Research-and-Insights"
                                                                    /></div><div
                                                                class="gdlr-core-column-service-content-wrapper"><div
                                                                    class="gdlr-core-column-service-title-wrap"><h3
                                                                        class="gdlr-core-column-service-title
                                                                        gdlr-core-skin-title"
                                                                        style={{
                                                                            fontSize: '30px',
                                                                            textTransform:'none'
                                                                        }}>Marketing
                                                                        Research
                                                                        and
                                                                        Insights</h3></div><div
                                                                    class="gdlr-core-column-service-content"
                                                                    style={{
                                                                        textTransform:'none'
                                                                    }}><p>Make
                                                                        customer-focused,
                                                                        data-driven
                                                                        decisions
                                                                        faster
                                                                        than
                                                                        before
                                                                        by
                                                                        analysing
                                                                        user
                                                                        data in
                                                                        real-time.
                                                                        derive
                                                                        actionable
                                                                        insights,
                                                                        and
                                                                        identify
                                                                        important
                                                                        trends
                                                                        within
                                                                        your
                                                                        data
                                                                        sets.</p>
                                                                </div></div></div></div></div></div></div>
                                                                <div
                                            class="gdlr-core-pbf-column
                                            gdlr-core-column-20"><div
                                                class="gdlr-core-pbf-column-content-margin
                                                gdlr-core-js" style={{
                                                    padding:'60px 30px 0px 0px'
                                                }}><div
                                                    class="gdlr-core-pbf-background-wrap"></div><div
                                                    class="gdlr-core-pbf-column-content
                                                    clearfix gdlr-core-js"data-gdlr-animation="fadeInUp"
                                                    data-gdlr-animation-duration="600ms"
                                                    data-gdlr-animation-offset="0.8"><div
                                                        class="gdlr-core-pbf-element"><div
                                                            class="gdlr-core-column-service-item
                                                            gdlr-core-item-pdb
                                                            gdlr-core-left-align
                                                            gdlr-core-column-service-icon-top
                                                            gdlr-core-no-caption
                                                            gdlr-core-item-pdlr"
                                                            style={{
                                                                margin: '0px 0px 10px 0px'
                                                            }}><div
                                                                class="gdlr-core-column-service-media
                                                                gdlr-core-media-image"
                                                                style={{
                                                                    marginTop:'7px'
                                                                }}><img
                                                                    src="assets/wp-content/uploads/2022/07/icon_06_WhatsApp-Business-Services.webp"
                                                                    alt=""
                                                                    width="80"
                                                                    height="76"
                                                                    title="icon_06_WhatsApp-Business-Services"
                                                                    /></div><div
                                                                class="gdlr-core-column-service-content-wrapper"><div
                                                                    class="gdlr-core-column-service-title-wrap"><h3
                                                                        class="gdlr-core-column-service-title
                                                                        gdlr-core-skin-title"
                                                                        style={{
                                                                            fontSize: '30px',
                                                                            textTransform:'none'
                                                                        }}>WhatsApp
                                                                        Business<br/>
                                                                        Services</h3></div><div
                                                                    class="gdlr-core-column-service-content"
                                                                    style={{
                                                                        textTransform:'none'
                                                                    }}><p>Make
                                                                        it easy
                                                                        for your
                                                                        teams to
                                                                        manage
                                                                        WhatsApp
                                                                        conversations
                                                                        with
                                                                        your
                                                                        customers.
                                                                        Also use
                                                                        smart
                                                                        bots to
                                                                        deliver
                                                                        contextual
                                                                        notifications
                                                                        upon
                                                                        purchase
                                                                        and
                                                                        service
                                                                        events.</p>
                                                                </div></div></div></div></div></div></div></div>
                                                            </div>
                                                        </div>
                                                                        
                                                                        


            <Footer />
        </div>
      
       
      
     
        
    );
}

export default OnlineRM;