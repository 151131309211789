import React from "react";
import Footer from "../partial/footer";
import NavBar from "../partial/navbar";
function Vhrms(){
    return(
        <div>
            <NavBar/>

            <div class="infinite-page-wrapper"
          id="infinite-page-wrapper"><div class="gdlr-core-page-builder-body"><div
              class="gdlr-core-pbf-wrapper" style={{
                padding: '90px 0px 90px 0px'
              }}
              data-skin="White Text"><div class="gdlr-core-pbf-background-wrap"><div
                  class="gdlr-core-pbf-background gdlr-core-parallax
                  gdlr-core-js" style={{
                    backgroundImage: "url('assets/wp-content/uploads/2022/07/resource-management.jpg')",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                  data-parallax-speed="0.8"></div></div><div
                class="gdlr-core-pbf-wrapper-content gdlr-core-js"><div
                  class="gdlr-core-pbf-wrapper-container clearfix
                  gdlr-core-container"><div class="gdlr-core-pbf-element"><div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix
                      gdlr-core-left-align gdlr-core-title-item-caption-bottom
                      gdlr-core-item-pdlr"><div
                        class="gdlr-core-title-item-title-wrap"><h1
                          class="gdlr-core-title-item-title gdlr-core-skin-title" style={{
                            fontSize: '55px',
                            fontWeight: '700'
                          }}>VHRMS<span
                            class="gdlr-core-title-item-title-divider
                            gdlr-core-skin-divider"></span></h1></div><span
                        class="gdlr-core-title-item-caption gdlr-core-info-font
                        gdlr-core-skin-caption" style={{
                          fontSize: '19px',
                          fontStyle: 'normal'
                        }}>Human Resource Management System</span></div></div><div
                    class="gdlr-core-pbf-element"><div
                      class="gdlr-core-text-box-item gdlr-core-item-pdlr
                      gdlr-core-item-pdb gdlr-core-left-align"><div
                        class="gdlr-core-text-box-item-content"
                        style={{
                          textTransform: 'none',
                          color: '#ffffff'
                        }}><p><button
                            class="popmake-231" style={{
                              color: '#fff'
                            }}> REQUEST A
                            DEMO</button></p>
                      </div></div></div></div></div></div><div
              class="gdlr-core-pbf-wrapper"><div
                class="gdlr-core-pbf-wrapper-content gdlr-core-js"><div
                  class="gdlr-core-pbf-wrapper-container clearfix
                  gdlr-core-container"><div class="gdlr-core-pbf-column
                    gdlr-core-column-60 gdlr-core-column-first"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js"><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js"><div class="gdlr-core-pbf-element"><div
                            class="gdlr-core-text-box-item gdlr-core-item-pdlr
                            gdlr-core-item-pdb gdlr-core-left-align"><div
                              class="gdlr-core-text-box-item-content"
                              style={{
                                fontSize: '18px',
                                lineHeight: '25px',
                                textTransform: 'none'
                              }}><p>VHRMS is an HRMS
                                specially customized for African statutory
                                requirements. This can be used for the
                                automation of HRMS and Payroll operations. Day
                                to day data collection of attendance, leave,
                                travel, etc.</p>
                            </div></div></div></div></div></div></div></div></div><div
              class="gdlr-core-pbf-wrapper"style={{
                padding: '0px 0px 0px 0px'
              }}><div
                class="gdlr-core-pbf-background-wrap"></div><div
                class="gdlr-core-pbf-wrapper-content gdlr-core-js"><div
                  class="gdlr-core-pbf-wrapper-container clearfix
                  gdlr-core-container"><div class="gdlr-core-pbf-column
                    gdlr-core-column-30 gdlr-core-column-first"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js" style={{
                        padding: '140px 70px 70px 0px'
                      }} data-sync-height="height-1"
                      data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"
                        data-gdlr-animation="fadeInLeft"
                        data-gdlr-animation-duration="600ms"
                        data-gdlr-animation-offset="0.8"><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-title-item gdlr-core-item-pdb
                            clearfix gdlr-core-left-align
                            gdlr-core-title-item-caption-top
                            gdlr-core-item-pdlr"><div
                              class="gdlr-core-title-item-title-wrap"><h3
                                class="gdlr-core-title-item-title
                                gdlr-core-skin-title" style={{
                                  letterSpacing: '0px',
                                  textTransform: 'none'
                                }}>RECRUITMENT MANAGEMENT<span
                                  class="gdlr-core-title-item-title-divider
                                  gdlr-core-skin-divider"></span></h3></div></div></div><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-icon-list-item gdlr-core-item-pdlr
                            gdlr-core-item-pdb clearfix gdlr-core-left-align
                            gdlr-core-style-1"><ul><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">It gives
                                    HR people an opportunity to recruit more
                                    quality hires.</span></div></li><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">This
                                    software predicts the recruitment speed to
                                    attract top talent.</span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">It
                                    enhances the overall candidate experience
                                    throughout the hiring process.</span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Also,
                                    this module manages yours.</span></div></li></ul></div></div></div></div></div><div
                    class="gdlr-core-pbf-column gdlr-core-column-30"
                    id="gdlr-core-column-20882"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js
                      gdlr-core-column-extend-right" style={{
                        minHeight: '300px'
                      }}
                      data-sync-height="height-1" data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"><div
                          class="gdlr-core-pbf-background gdlr-core-parallax
                          gdlr-core-js" style={{
                            backgroundImage:"url('assets/wp-content/uploads/2022/07/RECRUITMENT-MANAGEMENT.png')",
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                          }} data-parallax-speed="0.2"></div></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"></div></div></div></div></div></div><div
              class="gdlr-core-pbf-wrapper"style={{
                padding: '0px 0px 0px 0px'
              }}
              data-skin="Dark"><div class="gdlr-core-pbf-background-wrap"
                style={{
                  backgroundColor: '#278dd6'
                }}></div><div
                class="gdlr-core-pbf-wrapper-content gdlr-core-js"><div
                  class="gdlr-core-pbf-wrapper-container clearfix
                  gdlr-core-container"><div class="gdlr-core-pbf-column
                    gdlr-core-column-30 gdlr-core-column-first"
                    id="gdlr-core-column-69332"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js
                      gdlr-core-column-extend-left" style={{
                        minHeight: '300px'
                      }}
                      data-sync-height="height-1" data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"><div
                          class="gdlr-core-pbf-background gdlr-core-parallax
                          gdlr-core-js" style={{
                            backgroundImage:"url('assets/wp-content/uploads/2022/07/EMPLOYEE-MANAGEMENT.jpg')",
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                          }} data-parallax-speed="0.2"></div></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"></div></div></div><div
                    class="gdlr-core-pbf-column gdlr-core-column-30"
                    data-skin="White Text"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js" style={{
                        padding:'140px 0px 70px 70px'
                      }} data-sync-height="height-1"
                      data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"
                        data-gdlr-animation="fadeInRight"
                        data-gdlr-animation-duration="600ms"
                        data-gdlr-animation-offset="0.8"><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-title-item gdlr-core-item-pdb
                            clearfix gdlr-core-left-align
                            gdlr-core-title-item-caption-top
                            gdlr-core-item-pdlr"><div
                              class="gdlr-core-title-item-title-wrap"><h3
                                class="gdlr-core-title-item-title
                                gdlr-core-skin-title" style={{
                                  letterSpacing: '0px',
                                  textTransform: 'none'
                                }}>EMPLOYEE MANAGEMENT<span
                                  class="gdlr-core-title-item-title-divider
                                  gdlr-core-skin-divider"></span></h3></div></div></div><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-icon-list-item gdlr-core-item-pdlr
                            gdlr-core-item-pdb clearfix gdlr-core-left-align
                            gdlr-core-style-1"><ul><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">This
                                    cloud-based system allows you to automate
                                    and digitize manual data entry and saves
                                    time and human effort.</span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">HR data
                                    analytics</span></div></li><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">The
                                    software is responsible to improve
                                    engagement and overall, employee experience.</span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">The
                                    system offers you special tools to
                                    communicate and maintain good relationships
                                    with your staff.</span></div></li></ul></div></div></div></div></div></div></div></div><div
              class="gdlr-core-pbf-wrapper" style={{
                padding: '0px 0px 0px 0px'
              }}><div
                class="gdlr-core-pbf-background-wrap"></div><div
                class="gdlr-core-pbf-wrapper-content gdlr-core-js"><div
                  class="gdlr-core-pbf-wrapper-container clearfix
                  gdlr-core-container"><div class="gdlr-core-pbf-column
                    gdlr-core-column-30 gdlr-core-column-first"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js" style={{
                        padding: '140px 70px 70px 0px'
                      }} data-sync-height="height-1"
                      data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"
                        data-gdlr-animation="fadeInLeft"
                        data-gdlr-animation-duration="600ms"
                        data-gdlr-animation-offset="0.8"><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-title-item gdlr-core-item-pdb
                            clearfix gdlr-core-left-align
                            gdlr-core-title-item-caption-top
                            gdlr-core-item-pdlr"><div
                              class="gdlr-core-title-item-title-wrap"><h3
                                class="gdlr-core-title-item-title
                                gdlr-core-skin-title" style={{
                                  letterSpacing: '0px',
                                  textTransform: 'none'
                                }}>ATTENDANCE AND LEAVE
                                MANAGEMENT<span
                                  class="gdlr-core-title-item-title-divider
                                  gdlr-core-skin-divider"></span></h3></div></div></div><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-icon-list-item gdlr-core-item-pdlr
                            gdlr-core-item-pdb clearfix gdlr-core-left-align
                            gdlr-core-style-1"><ul><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Increase
                                    accuracy</span></div></li><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Ensure
                                    data integrity</span></div></li><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Improve
                                    employee productivity</span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Reduce
                                    administrative work</span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Lower
                                    admin costs </span></div></li><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Enhance
                                    the efficiency of HR staff </span></div></li></ul></div></div></div></div></div><div
                    class="gdlr-core-pbf-column gdlr-core-column-30"
                    id="gdlr-core-column-80154"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js
                      gdlr-core-column-extend-right" style={{
                        minHeight: '300px'
                      }}
                      data-sync-height="height-1" data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"><div
                          class="gdlr-core-pbf-background gdlr-core-parallax
                          gdlr-core-js" style={{
                            backgroundImage:"url('assets/wp-content/uploads/2022/07/ATTENDANCE-AND-LEAVE-MANAGEMENT.jpg')",
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                          }} data-parallax-speed="0.2"></div></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"></div></div></div></div></div></div><div
              class="gdlr-core-pbf-wrapper" style={{
                padding: '0px 0px 0px 0px'
              }}
              data-skin="Dark"><div class="gdlr-core-pbf-background-wrap"
               style={{
                backgroundColor: '#b1b1b1'
               }}></div><div
                class="gdlr-core-pbf-wrapper-content gdlr-core-js"><div
                  class="gdlr-core-pbf-wrapper-container clearfix
                  gdlr-core-container"><div class="gdlr-core-pbf-column
                    gdlr-core-column-30 gdlr-core-column-first"
                    id="gdlr-core-column-27001"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js
                      gdlr-core-column-extend-left" style={{
                        minHeight: '300px'
                      }}
                      data-sync-height="height-1" data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"><div
                          class="gdlr-core-pbf-background gdlr-core-parallax
                          gdlr-core-js" style={{
                            backgroundImage:"url('assets/wp-content/uploads/2022/07/TRAVEL-MANAGEMENT.png')",
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                          }} data-parallax-speed="0.2"></div></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"></div></div></div><div
                    class="gdlr-core-pbf-column gdlr-core-column-30"
                    data-skin="Blue" id="gdlr-core-column-86590"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js" style={{
                        padding: '140px 0px 70px 70px'
                      }} data-sync-height="height-1"
                      data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"
                        data-gdlr-animation="fadeInRight"
                        data-gdlr-animation-duration="600ms"
                        data-gdlr-animation-offset="0.8"><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-title-item gdlr-core-item-pdb
                            clearfix gdlr-core-left-align
                            gdlr-core-title-item-caption-top
                            gdlr-core-item-pdlr"><div
                              class="gdlr-core-title-item-title-wrap"><h3
                                class="gdlr-core-title-item-title
                                gdlr-core-skin-title"  style={{
                                  letterSpacing: '0px',
                                  textTransform: 'none' ,
                                  color: '#0a0a0a'
                                }}>TRAVEL
                                MANAGEMENT<span
                                  class="gdlr-core-title-item-title-divider
                                  gdlr-core-skin-divider"></span></h3></div></div></div><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-icon-list-item gdlr-core-item-pdlr
                            gdlr-core-item-pdb clearfix gdlr-core-left-align
                            gdlr-core-className-1"><ul><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check" style={{
                                      color: '#0a0a0a' 
                                    }}></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"
                                    style={{
                                      color: '#0a0a0a' 
                                    }}></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content"
                                    style={{
                                      color: '#0a0a0a' 
                                    }}>Multi-tier
                                    approvals</span></div></li><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check" style={{
                                      color: '#0a0a0a' 
                                    }}></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"
                                    style={{
                                      color: '#0a0a0a' 
                                    }}></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content"
                                    style={{
                                      color: '#0a0a0a' 
                                    }}>Easier expense
                                    reporting</span></div></li><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check" style={{
                                      color: '#0a0a0a' 
                                    }}></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"
                                    style={{
                                      color: '#0a0a0a' 
                                    }}></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content"
                                    style={{
                                      color: '#0a0a0a' 
                                    }}>Transparency in
                                    management</span></div></li><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check" style={{
                                      color: '#0a0a0a' 
                                    }}></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"
                                    style={{
                                      color: '#0a0a0a' 
                                    }}></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content"
                                    style={{
                                      color: '#0a0a0a' 
                                    }}>Paperless
                                    expense/claims reporting</span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check" style={{
                                      color: '#0a0a0a' 
                                    }}></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"
                                    style={{
                                      color: '#0a0a0a' 
                                    }}></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content"
                                    style={{
                                      color: '#0a0a0a' 
                                    }}>Automated ROI
                                    reporting</span></div></li></ul></div></div></div></div></div></div></div></div><div
              class="gdlr-core-pbf-wrapper" style={{
                padding: '0px 0px 0px 0px'
              }}><div
                class="gdlr-core-pbf-background-wrap"></div><div
                class="gdlr-core-pbf-wrapper-content gdlr-core-js"><div
                  class="gdlr-core-pbf-wrapper-container clearfix
                  gdlr-core-container"><div class="gdlr-core-pbf-column
                    gdlr-core-column-30 gdlr-core-column-first"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js" style={{
                        padding: '140px 70px 70px 0px'
                      }} data-sync-height="height-1"
                      data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"
                        data-gdlr-animation="fadeInLeft"
                        data-gdlr-animation-duration="600ms"
                        data-gdlr-animation-offset="0.8"><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-title-item gdlr-core-item-pdb
                            clearfix gdlr-core-left-align
                            gdlr-core-title-item-caption-top
                            gdlr-core-item-pdlr"><div
                              class="gdlr-core-title-item-title-wrap"><h3
                                class="gdlr-core-title-item-title
                                gdlr-core-skin-title" style={{
                                  letterSpacing: '0px',
                                  textTransform: 'none' 
                                }}>PERFORMANCE MANAGEMENT<span
                                  class="gdlr-core-title-item-title-divider
                                  gdlr-core-skin-divider"></span></h3></div></div></div><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-icon-list-item gdlr-core-item-pdlr
                            gdlr-core-item-pdb clearfix gdlr-core-left-align
                            gdlr-core-class-1"><ul><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Reward
                                    your Performers</span></div></li><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Goal
                                    Setting and Tracking</span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Enact
                                    the simplest employee performance appraisal
                                    process</span></div></li><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Automated
                                    Reminders and notifications</span></div></li></ul></div></div></div></div></div><div
                    class="gdlr-core-pbf-column gdlr-core-column-30"
                    id="gdlr-core-column-98237"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js
                      gdlr-core-column-extend-right" style={{
                        minHeight: '300px'
                      }}
                      data-sync-height="height-1" data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"><div
                          class="gdlr-core-pbf-background gdlr-core-parallax
                          gdlr-core-js" style={{
                            backgroundImage: "url('assets/wp-content/uploads/2022/07/PERFORMANCE-MANAGEMENT.png')",
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                          }} data-parallax-speed="0.2"></div></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"></div></div></div></div></div></div><div
              class="gdlr-core-pbf-wrapper" style={{
                padding: '0px 0px 0px 0px'
              }}
              data-skin="Dark"><div class="gdlr-core-pbf-background-wrap"
                style={{
                  backgroundColor: '#278dd6'
                }}></div><div
                class="gdlr-core-pbf-wrapper-content gdlr-core-js"><div
                  class="gdlr-core-pbf-wrapper-container clearfix
                  gdlr-core-container"><div class="gdlr-core-pbf-column
                    gdlr-core-column-30 gdlr-core-column-first"
                    id="gdlr-core-column-87593"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js
                      gdlr-core-column-extend-left" style={{
                        minHeight: '300px'
                      }}
                      data-sync-height="height-1" data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"><div
                          class="gdlr-core-pbf-background gdlr-core-parallax
                          gdlr-core-js" style={{
                            backgroundImage:"url('assets/wp-content/uploads/2022/07/TRAINING-MANAGEMENT-1.png')",
                          backgroundSize: 'cover' ,
                          backgroundPosition: 'center'
                          }} data-parallax-speed="0.2"></div></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"></div></div></div><div
                    class="gdlr-core-pbf-column gdlr-core-column-30"
                    data-skin="White Text"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js" style={{
                        padding: '140px 0px 70px 70px'
                      }} data-sync-height="height-1"
                      data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"
                        data-gdlr-animation="fadeInRight"
                        data-gdlr-animation-duration="600ms"
                        data-gdlr-animation-offset="0.8"><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-title-item gdlr-core-item-pdb
                            clearfix gdlr-core-left-align
                            gdlr-core-title-item-caption-top
                            gdlr-core-item-pdlr"><div
                              class="gdlr-core-title-item-title-wrap"><h3
                                class="gdlr-core-title-item-title
                                gdlr-core-skin-title" style={{
                                  letterSpacing: '0px',
                                textTransform: 'none'
                                }}>TRAINING MANAGEMENT<span
                                  class="gdlr-core-title-item-title-divider
                                  gdlr-core-skin-divider"></span></h3></div></div></div><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-icon-list-item gdlr-core-item-pdlr
                            gdlr-core-item-pdb clearfix gdlr-core-left-align
                            gdlr-core-className-1"><ul><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Needs
                                    Assessment</span></div></li><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Defining
                                    Training Objective</span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Designing
                                    a Training Program</span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Implementation
                                    of the Training Program</span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Evaluation
                                    and follow up</span></div></li></ul></div></div></div></div></div></div></div></div><div
              class="gdlr-core-pbf-wrapper"style={{
                padding: '0px 0px 0px 0px'
              }}><div
                class="gdlr-core-pbf-background-wrap"></div><div
                class="gdlr-core-pbf-wrapper-content gdlr-core-js"><div
                  class="gdlr-core-pbf-wrapper-container clearfix
                  gdlr-core-container"><div class="gdlr-core-pbf-column
                    gdlr-core-column-30 gdlr-core-column-first"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js" style={{
                        padding:'140px 70px 70px 0px'
                      }} data-sync-height="height-1"
                      data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"
                        data-gdlr-animation="fadeInLeft"
                        data-gdlr-animation-duration="600ms"
                        data-gdlr-animation-offset="0.8"><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-title-item gdlr-core-item-pdb
                            clearfix gdlr-core-left-align
                            gdlr-core-title-item-caption-top
                            gdlr-core-item-pdlr"><div
                              class="gdlr-core-title-item-title-wrap"><h3
                                class="gdlr-core-title-item-title
                                gdlr-core-skin-title" style={{
                                  letterSpacing: '0px',
                                textTransform: 'none'
                                }}>MEDICAL INSURANCE<span
                                  class="gdlr-core-title-item-title-divider
                                  gdlr-core-skin-divider"></span></h3></div></div></div><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-icon-list-item gdlr-core-item-pdlr
                            gdlr-core-item-pdb clearfix gdlr-core-left-align
                            gdlr-core-className-1"><ul><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Insurance
                                    policy and definition </span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Insurance
                                    policy association with designation/
                                    department </span></div></li><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Claim
                                    request approval and settlement</span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Linking
                                    with payroll for settled claims </span></div></li></ul></div></div></div></div></div><div
                    class="gdlr-core-pbf-column gdlr-core-column-30"
                    id="gdlr-core-column-77603"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js
                      gdlr-core-column-extend-right" style={{
                        minHeight: '300px'
                      }}
                      data-sync-height="height-1" data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"><div
                          class="gdlr-core-pbf-background gdlr-core-parallax
                          gdlr-core-js" style={{
                            backgroundImage:
                          "url('assets/wp-content/uploads/2022/07/MEDICAL-INSURANCE.png')",
                          backgroundSize: 'cover',
                          backgroundPosition: 'center'
                          }} data-parallax-speed="0.2"></div></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"></div></div></div></div></div></div><div
              class="gdlr-core-pbf-wrapper"style={{
                padding: '0px 0px 0px 0px'
              }}
              data-skin="Dark"><div class="gdlr-core-pbf-background-wrap"
                style={{
                  backgroundColor: '#278dd6'
                }}></div><div
                class="gdlr-core-pbf-wrapper-content gdlr-core-js"><div
                  class="gdlr-core-pbf-wrapper-container clearfix
                  gdlr-core-container"><div class="gdlr-core-pbf-column
                    gdlr-core-column-30 gdlr-core-column-first"
                    id="gdlr-core-column-13604"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js
                      gdlr-core-column-extend-left" style={{
                        minHeight: '300px'
                      }}
                      data-sync-height="height-1" data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"><div
                          class="gdlr-core-pbf-background gdlr-core-parallax
                          gdlr-core-js" style={{
                            backgroundImage:
                          "url('assets/wp-content/uploads/2022/07/WORK-INJURY.jpg')",
                          backgroundSize: 'cover',
                          backgroundPosition: 'center'
                          }} data-parallax-speed="0.2"></div></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"></div></div></div><div
                    class="gdlr-core-pbf-column gdlr-core-column-30"
                    data-skin="White Text"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js" style={{
                        padding:'140px 0px 70px 70px'
                      }} data-sync-height="height-1"
                      data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"
                        data-gdlr-animation="fadeInRight"
                        data-gdlr-animation-duration="600ms"
                        data-gdlr-animation-offset="0.8"><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-title-item gdlr-core-item-pdb
                            clearfix gdlr-core-left-align
                            gdlr-core-title-item-caption-top
                            gdlr-core-item-pdlr"><div
                              class="gdlr-core-title-item-title-wrap"><h3
                                class="gdlr-core-title-item-title
                                gdlr-core-skin-title" style={{
                                  letterSpacing: '0px',
                                textTransform: 'none'
                                }}>WORK INJURY<span
                                  class="gdlr-core-title-item-title-divider
                                  gdlr-core-skin-divider"></span></h3></div></div></div><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-icon-list-item gdlr-core-item-pdlr
                            gdlr-core-item-pdb clearfix gdlr-core-left-align
                            gdlr-core-className-1"><ul><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Capture
                                    work injury details </span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Multiple
                                    medical document uploads along with status
                                  </span></div></li><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Work
                                    injury linked with payroll via attendance
                                  </span></div></li></ul></div></div></div></div></div></div></div></div><div
              class="gdlr-core-pbf-wrapper" style={{
                padding: '0px 0px 0px 0px'
              }}><div
                class="gdlr-core-pbf-background-wrap"></div><div
                class="gdlr-core-pbf-wrapper-content gdlr-core-js"><div
                  class="gdlr-core-pbf-wrapper-container clearfix
                  gdlr-core-container"><div class="gdlr-core-pbf-column
                    gdlr-core-column-30 gdlr-core-column-first"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js" style={{
                        padding:'140px 70px 70px 0px'
                      }} data-sync-height="height-1"
                      data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"
                        data-gdlr-animation="fadeInLeft"
                        data-gdlr-animation-duration="600ms"
                        data-gdlr-animation-offset="0.8"><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-title-item gdlr-core-item-pdb
                            clearfix gdlr-core-left-align
                            gdlr-core-title-item-caption-top
                            gdlr-core-item-pdlr"><div
                              class="gdlr-core-title-item-title-wrap"><h3
                                class="gdlr-core-title-item-title
                                gdlr-core-skin-title" style={{
                                  letterSpacing: '0px',
                                textTransform: 'none'
                                }}>ASSET MANAGEMENT<span
                                  class="gdlr-core-title-item-title-divider
                                  gdlr-core-skin-divider"></span></h3></div></div></div><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-icon-list-item gdlr-core-item-pdlr
                            gdlr-core-item-pdb clearfix gdlr-core-left-align
                            gdlr-core-className-1"><ul><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Build
                                    rules for allocating assets for different
                                    employee groups</span></div></li><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Maintain
                                    history and track inventory of all assets
                                    from procurement to retirement</span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Ensure
                                    hassle-free allocation, exchange, returns,
                                    and assigning of assets</span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Assign
                                    damage liability to employees for theft or
                                    negligence</span></div></li><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Search
                                    assets easily using asset specific
                                    custom-defined attributes</span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Assign
                                    different asset responsibilities to relevant
                                    employees and track all centrally</span></div></li></ul></div></div></div></div></div><div
                    class="gdlr-core-pbf-column gdlr-core-column-30"
                    id="gdlr-core-column-64136"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js
                      gdlr-core-column-extend-right" style={{
                        minHeight: '300px'
                      }}
                      data-sync-height="height-1" data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"><div
                          class="gdlr-core-pbf-background gdlr-core-parallax
                          gdlr-core-js" style={{
                            backgroundImage:"url('assets/wp-content/uploads/2022/07/ASSET-MANAGEMENT.jpg')",
                          backgroundSize: 'cover',
                          backgroundPosition: 'center'
                          }} data-parallax-speed="0.2"></div></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"></div></div></div></div></div></div><div
              class="gdlr-core-pbf-wrapper" style={{
                padding:'0px 0px 0px 0px'
              }}
              data-skin="Dark"><div class="gdlr-core-pbf-background-wrap"
                style={{
                  backgroundColor: '#278dd6'
                }}></div><div
                class="gdlr-core-pbf-wrapper-content gdlr-core-js"><div
                  class="gdlr-core-pbf-wrapper-container clearfix
                  gdlr-core-container"><div class="gdlr-core-pbf-column
                    gdlr-core-column-30 gdlr-core-column-first"
                    id="gdlr-core-column-84317"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js
                      gdlr-core-column-extend-left" style={{
                        minHeight: '300px' 
                      }}
                      data-sync-height="height-1" data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"><div
                          class="gdlr-core-pbf-background gdlr-core-parallax
                          gdlr-core-js" style={{
                            backgroundImage:
                          "url('assets/wp-content/uploads/2022/07/Payroll-Processing.jpg')",
                          backgroundSize: 'cover',
                          backgroundPosition: 'center'
                          }} data-parallax-speed="0.2"></div></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"></div></div></div><div
                    class="gdlr-core-pbf-column gdlr-core-column-30"
                    data-skin="White Text"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js" style={{
                        padding:'140px 0px 70px 70px'
                      }} data-sync-height="height-1"
                      data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"
                        data-gdlr-animation="fadeInRight"
                        data-gdlr-animation-duration="600ms"
                        data-gdlr-animation-offset="0.8"><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-title-item gdlr-core-item-pdb
                            clearfix gdlr-core-left-align
                            gdlr-core-title-item-caption-top
                            gdlr-core-item-pdlr"><div
                              class="gdlr-core-title-item-title-wrap"><h3
                                class="gdlr-core-title-item-title
                                gdlr-core-skin-title" style={{
                                  letterSpacing: '0px',
                                textTransform: 'none'
                                }}>PAYROLL MANAGEMENT<span
                                  class="gdlr-core-title-item-title-divider
                                  gdlr-core-skin-divider"></span></h3></div></div></div><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-text-box-item gdlr-core-item-pdlr
                            gdlr-core-item-pdb gdlr-core-left-align"><div
                              class="gdlr-core-text-box-item-content"
                              className="text-transform: none ;"><p><br/>Deploying a
                                cloud-based HR payroll system helps with
                                multiple payroll processes including recruiting
                                candidates, leave and attendance tracking,
                                workforce management, and much more, apart from
                                simply managing payroll data.</p>
                            </div></div></div></div></div></div></div></div></div><div
              class="gdlr-core-pbf-wrapper" style={{
                padding: '0px 0px 0px 0px'
              }}><div
                class="gdlr-core-pbf-background-wrap"></div><div
                class="gdlr-core-pbf-wrapper-content gdlr-core-js"><div
                  class="gdlr-core-pbf-wrapper-container clearfix
                  gdlr-core-container"><div class="gdlr-core-pbf-column
                    gdlr-core-column-30 gdlr-core-column-first"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js" style={{
                        padding: '140px 70px 70px 0px'
                      }} data-sync-height="height-1"
                      data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"
                        data-gdlr-animation="fadeInLeft"
                        data-gdlr-animation-duration="600ms"
                        data-gdlr-animation-offset="0.8"><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-title-item gdlr-core-item-pdb
                            clearfix gdlr-core-left-align
                            gdlr-core-title-item-caption-top
                            gdlr-core-item-pdlr"><div
                              class="gdlr-core-title-item-title-wrap"><h3
                                class="gdlr-core-title-item-title
                                gdlr-core-skin-title"style={{
                                  letterSpacing: '0px',
                                textTransform: 'none'
                                }}>FEATURES<span
                                  class="gdlr-core-title-item-title-divider
                                  gdlr-core-skin-divider"></span></h3></div></div></div><div
                          class="gdlr-core-pbf-element"><div
                            class="gdlr-core-icon-list-item gdlr-core-item-pdlr
                            gdlr-core-item-pdb clearfix gdlr-core-left-align
                            gdlr-core-className-1"><ul><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Cloud
                                    availability</span></div></li><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Short
                                    Implementation duration</span></div></li><li
                                class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">One time
                                    cost for a year</span></div></li><li class="gdlr-core-skin-divider
                                gdlr-core-with-hover clearfix"><span
                                  class="gdlr-core-icon-list-icon-wrap
                                  gdlr-core-left"><i
                                    class="gdlr-core-icon-list-icon-hover fa
                                    fa-check"></i><i
                                    class="gdlr-core-icon-list-icon fa fa-check"></i></span><div
                                  class="gdlr-core-icon-list-content-wrap"><span
                                    class="gdlr-core-icon-list-content">Customer
                                    service</span></div></li></ul></div></div></div></div></div><div
                    class="gdlr-core-pbf-column gdlr-core-column-30"
                    id="gdlr-core-column-71398"><div
                      class="gdlr-core-pbf-column-content-margin gdlr-core-js
                      gdlr-core-column-extend-right" style={{
                        minHeight: '300px'
                      }}
                      data-sync-height="height-1" data-sync-height-center><div
                        class="gdlr-core-pbf-background-wrap"><div
                          class="gdlr-core-pbf-background gdlr-core-parallax
                          gdlr-core-js" style={{
                            backgroundImage:
                          "url('assets/wp-content/uploads/2022/07/FEATURES.jpg')",
                          backgroundSize: 'cover',
                          backgroundPosition: 'center'
                          }} data-parallax-speed="0.2"></div></div><div
                        class="gdlr-core-pbf-column-content clearfix
                        gdlr-core-js gdlr-core-sync-height-content"></div></div></div></div></div></div></div></div>

            <Footer/>

        </div>
    )
}
export default Vhrms;