import React from "react";
import Footer from "../partial/footer";
import NavBar from "../partial/navbar";
import './Contant.css'

function Contact() {
    return (
        <div>
            <NavBar />



            <div class="infinite-page-wrapper" id="infinite-page-wrapper"><div class="gdlr-core-page-builder-body">
                <div class="gdlr-core-pbf-wrapper" style={{
                    padding: '0px 0px 0px 0px'
                }} >
                    <div class="gdlr-core-pbf-background-wrap" style={{
                        backgroundColor: '#f7f7f7'
                    }}>
                    </div>
                    <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                        <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                            <div class="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
                                <div class="gdlr-core-pbf-column-content-margin gdlr-core-js" style={{
                                    padding: '50px 20px 0px 20px'
                                }}>
                                    <div class="gdlr-core-pbf-background-wrap">
                                    </div>
                                    <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                        <div class="gdlr-core-pbf-element">
                                            <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr" 
                                            style={{
                                                paddingBottom: '15px'
                                            }}>
                                                <div class="gdlr-core-title-item-title-wrap ">
                                                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title " 
                                                    style={{
                                                        fontSize: '20px'
                                                    }}>Location<span class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="gdlr-core-pbf-element">
                                            <div class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align">
                                                <div class="gdlr-core-divider-container" style={{
                                                    maxWidth: '135px'
                                                }}>
                                                    <div class="gdlr-core-divider-line gdlr-core-skin-divider" style={{
                                                        borderWidth: '2px'
                                                    }}>
                                                    </div></div></div></div>
                                        <div class="gdlr-core-pbf-element">
                                            <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align" 
                                            style={{
                                                paddingBottom: '0px'
                                            }}><div class="gdlr-core-text-box-item-content" 
                                            style={{
                                                fontSize: '16px',
                                                textTransform: 'none'
                                            }}><p> 1 Morrison Crescent, Off Kudirat Abiola way, Oregun, Ikeja, Lagos Nigeria.</p>
                                            </div></div>
                                        </div><div class="gdlr-core-pbf-element"><div class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-style-1"><ul>
                                            <li class=" gdlr-core-skin-divider gdlr-core-with-hover clearfix"><a href="mailto:digital@lagoSmart.com" target="_self">
                                            <span class="gdlr-core-icon-list-icon-wrap gdlr-core-left">
                                                <i class="gdlr-core-icon-list-icon-hover fa fa-envelope-o"  
                                                style={{
                                                    fontSize: '16px'
                                                }}></i><i class="gdlr-core-icon-list-icon fa fa-envelope-o" style={{
                                                    fontSize: '16px',
                                                    width:'16px'
                                                }}></i></span>
                                            <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content" style={{
                                                    fontSize: '16px'
                                                }}>digital@lagoSmart.com</span>
                                            </div></a></li><li class=" gdlr-core-skin-divider gdlr-core-with-hover clearfix">
                                                <a href="tel:+2348157353535" target="_self">
                                                    <span class="gdlr-core-icon-list-icon-wrap gdlr-core-left">
                                                        <i class="gdlr-core-icon-list-icon-hover fa fa-phone" style={{
                                                    fontSize: '16px'
                                                }}>
                                                        </i><i class="gdlr-core-icon-list-icon fa fa-phone" style={{
                                                    fontSize: '16px',
                                                    width:'16px'
                                                }}></i></span>
                                                    <div class="gdlr-core-icon-list-content-wrap">
                                                        <span class="gdlr-core-icon-list-content" style={{
                                                    fontSize: '16px'
                                                }}>+234 815 735 3535</span></div></a></li></ul>
                                        </div></div></div></div></div><div class="gdlr-core-pbf-column gdlr-core-column-30">
                                <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{
                                    padding: '50px 20px 0px 20px'
                                }}>
                                    <div class="gdlr-core-pbf-background-wrap"></div><div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                        <div class="gdlr-core-pbf-element">
                                            <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr" 
                                            className="sty17">
                                                <div class="gdlr-core-title-item-title-wrap "><h1 class="gdlr-core-title-item-title gdlr-core-skin-title " 
                                                className="sty18">Send us a Message
                                                    <span class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span></h1></div></div></div>
                                        <div class="gdlr-core-pbf-element">
                                            <div class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align">
                                                <div class="gdlr-core-divider-container" className="sty19">
                                                    <div class="gdlr-core-divider-line gdlr-core-skin-divider" className="sty20"></div>
                                                </div></div></div><div class="gdlr-core-pbf-element">
                                            <div class="gdlr-core-contact-form-7-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                                                <div role="form" class="wpcf7" id="wpcf7-f74-p26-o5" lang="en-US" dir="ltr">
                                                    <div class="screen-reader-response"><p role="status" aria-live="polite" aria-atomic="true"></p> <ul></ul></div>
                                                    <form method="POST" action="https://formspree.io/f/mjvdogla">
                                                       
                                                        <div class="gdlr-core-input-wrap gdlr-core-large gdlr-core-full-width gdlr-core-with-column gdlr-core-border">

                                                                <div class="gdlr-core-column-60">
                                                                <span class="wpcf7-form-control-wrap" data-name="your-message">
                                                                    <input name="name" type="text" cols="40" rows="10" aria-required="true" aria-invalid="false" placeholder="Enter Your Name*"/>
                                                                    </span>
                                                                </div>

                                                                <div class="gdlr-core-column-60">
                                                                <span class="wpcf7-form-control-wrap" data-name="your-message">
                                                                    <input name="number" type="number" cols="40" rows="10" aria-required="true" aria-invalid="false" placeholder="Enter Your Number*"/>
                                                                    </span>
                                                                </div>

                                                                <div class="gdlr-core-column-60">
                                                                <span class="wpcf7-form-control-wrap" data-name="your-message">
                                                                    <input name="email" type="email" cols="40" rows="10" aria-required="true" aria-invalid="false" placeholder="Enter Your Email*"/>
                                                                    </span>
                                                                </div>

                                                                <div class="gdlr-core-column-60">
                                                                <span class="wpcf7-form-control-wrap" data-name="your-message">
                                                                    <input name="company" type="text" cols="40" rows="10" aria-required="true" aria-invalid="false" placeholder="Enter Your Company*"/>
                                                                    </span>
                                                                </div>
                                                            
                                                            <div class="gdlr-core-column-60">
                                                                <span class="wpcf7-form-control-wrap" data-name="your-message">
                                                                    <textarea name="message" cols="40" rows="10" aria-required="true" aria-invalid="false" placeholder="Mention Your Marketing Objective*"></textarea>
                                                                    </span>
                                                                </div>
                                                            <div class="gdlr-core-column-60"><input type="submit" value="Submit Now" class="wpcf7-form-control has-spinner wpcf7-submit gdlr-core-large" /></div>
                                                        </div>
                                                    </form>
                                                </div></div></div></div></div></div></div></div></div>
                <div class="gdlr-core-pbf-wrapper " className="sty22"><div class="gdlr-core-pbf-background-wrap">
                </div>
                    <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                        <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full-no-space">
                            <div class="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
                                <div class="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                    <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                        <div class="gdlr-core-pbf-element">
                                            <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align" className="sty23">
                                                <div class="gdlr-core-text-box-item-content" style={{
                                                    textTransform: 'none'
                                                }}><p>
                                                    <iframe style={{
                                                        border: '0',
                                                        width: '100%',
                                                        height: '400px'
                                                    }}
                                                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA0s1a7phLN0iaD6-UE7m4qP-z21pH0eSc&amp;q=lagoSmart"
                                                        allowfullscreen="allowfullscreen">

                                                    </iframe>
                                                </p>
                                                </div></div></div></div></div></div></div></div></div></div></div>

            <Footer />
        </div>
    )
}

export default Contact;