import React from "react"
import Footer from "../../partial/footer"
import NavBar from "../../partial/navbar"

function Reason(){
    return(
        <div>
        <NavBar/>
        <div class="infinite-page-wrapper" id="infinite-page-wrapper">
           <div class="infinite-blog-title-wrap  infinite-style-small">
                    <div class="infinite-header-transparent-substitute"></div>
                    <div class="infinite-blog-title-top-overlay"></div>
                    <div class="infinite-blog-title-overlay"></div>
                    <div class="infinite-blog-title-bottom-overlay"></div>
                    <div class="infinite-blog-title-container infinite-container">
                        <div class="infinite-blog-title-content infinite-item-pdlr">
                            <header class="infinite-single-article-head infinite-single-blog-title-style-1 clearfix">
                                <div class="infinite-single-article-date-wrapper post-date updated">
                                    <div class="infinite-single-article-date-day">20</div>
                                    <div class="infinite-single-article-date-month">April</div>
                                </div>
                                <div class="infinite-single-article-head-right">
                                    <h1 class="infinite-single-article-title">3 Reason your business should have a digital marketing strategy</h1>
                                    <div class="infinite-blog-info-wrapper">
                                        <div
                                            class="infinite-blog-info infinite-blog-info-font infinite-blog-info-author vcard author post-author ">
                                            <span class="infinite-head"><i class="icon_documents_alt"></i></span><span
                                                class="fn"><a href="" title="Posts by admin"
                                                    rel="author">admin</a></span></div>
                                        <div
                                            class="infinite-blog-info infinite-blog-info-font infinite-blog-info-category ">
                                            <span class="infinite-head"><i class="icon_folder-alt"></i></span><a
                                                href="/digital-marketing" rel="tag">Digital
                                                Media Services</a></div>
                                        <div
                                            class="infinite-blog-info infinite-blog-info-font infinite-blog-info-comment-number ">
                                            <span class="infinite-head"><i class="icon_comment_alt"></i></span>0 </div>
                                    </div>
                                </div>
                            </header>
                        </div>
                    </div>
                </div>
                </div>
           <div class="container">
               <p>
                <b style={{fontSize:'20px'}}>D</b>o you have a digital marketing strategy? If not, you could be pushing a large number of your 
                target customers from reaching your brand, products and services.. </p>
               
                <h5>Here are three reasons why your business needs a digital marketing strategy:</h5>

                <p><b style={{color:'#AB6261'}}>EXPOSURE TO YOUR TARGET AUDIENCE: </b><br/>With 75 percent of adults using some form of social networking, 
                businesses are engaging digitally to attract more customers. In digital marketing, using various digital display ads and social media 
                marketing platforms to communicate and advertise is meeting customers where they already are.</p>
                <p>In today's technology, we can reach a target audience across multiple devices (computers, tablets, phones, etc) and even serve ads 
                    while they're using apps or playing games on their phones
                </p>

                <p><b style={{color:'#AB6261'}}>COMPETING AS A SMALL FISH IN A BIG POND: </b><br/>Few advertising methods can level the playing field like 
                digital marketing. If you own a small business, or are just starting up, you might have limited resources and a limited budget. Digital 
                efforts can help you create a cost-effective, buzz-worthy campaign that competes with the big dogs. And because you can easily measure 
                your success, determining your return on investment is possible.</p>
                <p>
                These low-costs campaigns can be successful with just a few dollars per day, hyper-targeted at the perfect audience.
                </p>
                <p>
                As results start rolling in, increase the budget to accommodate and the snowball effect will kick in.
                </p>

                <p><b style={{color:'#AB6261'}}>BUILDING YOUR RELATIONSHIPS AND YOUR REPUTATION: </b><br/>Through monitoring of your digital advertising 
                and social sites, you have a direct connection to your customer’s interactions and reactions to your business. By monitoring, you can 
                proactively share customer accolades, respond to complaints, solve problems, answer questions, manage crisis, and discover trends; 
                all while developing a voice for your brand..
                </p>
                <p>
                As the interaction between you and your customers increase, your reputation builds with it. Customers will begin to trust your perspective. 
                They’ll feel their issues are being heard, and their problems are being addressed. That trust can turn into referrals as they share with 
                family and friends
                </p>

                

                
           </div>
        
        <Footer/>
    </div>
    )

}
export default Reason;