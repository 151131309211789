import React from "react";
import Footer from "../partial/footer";
import NavBar from "../partial/navbar";
import './Career.css'

function Career(){
    
    return(
        <div>
            <NavBar/>
       
            <div class="infinite-page-wrapper"
                    id="infinite-page-wrapper"><div
                        class="gdlr-core-page-builder-body">
                            <div
                            class="gdlr-core-pbf-wrapper" style={{
                                padding: '90px 0px 90px 0px'
                            }} data-skin="Dark"><div
                                class="gdlr-core-pbf-background-wrap"><div
                                    class="gdlr-core-pbf-background
                                    gdlr-core-parallax gdlr-core-js"
                                    style={{
                                        backgroundImage:"url('assets/wp-content/uploads/2022/07/Careers.jpg')",
                                        backgroundSize: 'cover',
                                        backgroundOosition: 'center',
                                    }}
                                    data-parallax-speed="0.8"></div></div><div
                                class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js"><div
                                    class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                        class="gdlr-core-pbf-element"><div
                                            class="gdlr-core-title-item
                                            gdlr-core-item-pdb clearfix
                                            gdlr-core-left-align
                                            gdlr-core-title-item-caption-bottom
                                            gdlr-core-item-pdlr"><div
                                                class="gdlr-core-title-item-title-wrap"><h1
                                                    class="gdlr-core-title-item-title
                                                    gdlr-core-skin-title" style={{
                                                        fontSize: '55px',
                                                        fontWeight: '700'
                                                    }}>Careers<span
                                                        class="gdlr-core-title-item-title-divider
                                                        gdlr-core-skin-divider"></span></h1></div></div></div></div></div></div><br/>
                                                        <div
                                                        class="gdlr-core-pbf-element"><div
                                                            class="gdlr-core-text-box-item
                                                            gdlr-core-item-pdlr
                                                            gdlr-core-item-pdb
                                                            gdlr-core-left-align"
                                                            ><div
                                                                class="gdlr-core-text-box-item-content"
                                                                style={{
                                                                    fontSize:'18px',
                                                                    textTransform:'none'
                                                                }}><p >We have been looking out for you! And your expertise in all things innovation!

                                                                At lagoSmart, we believe in innovating the content and stories we tell through ingenious minds. We are a clan of dedicated, passionate, multi-skilled think-tanks who are always looking out for more.
                                                                
                                                                And if this sounds like you, say hello to us with your resume.</p></div></div></div>
                            <div
                            class="gdlr-core-pbf-wrapper"><div
                                class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js">
                                    
                                    <div
                                    class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                        class="gdlr-core-pbf-column
                                        gdlr-core-column-60
                                        gdlr-core-column-first"><div
                                            class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js"><div
                                                class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js"><div
                                                    class="gdlr-core-pbf-element"><div
                                                        class="gdlr-core-stunning-text-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        gdlr-core-left-align
                                                        gdlr-core-stunning-text-caption-above-title
                                                        job-list"><h2
                                                            class="gdlr-core-stunning-text-item-title"
                                                            style={{
                                                                fontSize:'23px',
                                                                letterSpacing: '0px',
                                                                textTransform: 'none'
                                                            }}>Digital
                                                            Marketing Manager</h2><div
                                                            class="gdlr-core-stunning-text-item-content"><p>
                                                               <br/>The
                                                                Digital
                                                                Marketing
                                                                Manager is
                                                                responsible for
                                                                implementing
                                                                paid digital
                                                                media strategies
                                                                for clients. He/
                                                                She can then
                                                                quickly
                                                                understand,
                                                                manage, and
                                                                support
                                                                initiatives that
                                                                will contribute
                                                                to the goals and
                                                                success of
                                                                client
                                                                campaigns.</p>
                                                        </div><a
                                                            class="gdlr-core-stunning-text-item-link
                                                            gdlr-core-info-font"
                                                            href="/digital-marketing"
                                                            target="_self">Read More
                                                            </a></div></div></div></div></div></div></div></div>

                            <div class="gdlr-core-pbf-wrapper"><div
                                class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js">
                                    
                                    <div
                                    class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                        class="gdlr-core-pbf-column
                                        gdlr-core-column-60
                                        gdlr-core-column-first"><div
                                            class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js"><div
                                                class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js"><div
                                                    class="gdlr-core-pbf-element"><div
                                                        class="gdlr-core-stunning-text-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        gdlr-core-left-align
                                                        gdlr-core-stunning-text-caption-above-title
                                                        job-list"><h2
                                                            class="gdlr-core-stunning-text-item-title"
                                                            style={{
                                                                fontSize:'23px',
                                                                letterSpacing: '0px',
                                                                textTransform: 'none'
                                                            }}>Motion designer</h2><div
                                                            class="gdlr-core-stunning-text-item-content"><p>
                                                               <br/>We are looking for a passionate graphic designer and video editor who can turn around any idea into an aesthetic design for attractive social media posts.<br/>

                                                            You shall also be responsible for crisp visual edits of ad films, short stories and campaigns.<br/>

                                                            strong proficiency of Premier Pro CC and other video editing softwares<br/>
                                                            an aesthetic sense of visual design<br/>
                                                            Expertise in Adobe Illustrator, Adobe Photoshop, Maya, Blender for graphics and illustration<br/>
                                                            1+ years of experience in a digital media agency<br/>
                                                            ability to give visual inputs to the creative strategist's imagination<br/>
                                                            ability to comprehend and translate a brand's vision into social media posts</p>
                                                        </div></div></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="gdlr-core-pbf-wrapper"><div
                                class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js">
                                    
                                    <div
                                    class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                        class="gdlr-core-pbf-column
                                        gdlr-core-column-60
                                        gdlr-core-column-first"><div
                                            class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js"><div
                                                class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js"><div
                                                    class="gdlr-core-pbf-element"><div
                                                        class="gdlr-core-stunning-text-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        gdlr-core-left-align
                                                        gdlr-core-stunning-text-caption-above-title
                                                        job-list"><h2
                                                            class="gdlr-core-stunning-text-item-title"
                                                            style={{
                                                                fontSize:'23px',
                                                                letterSpacing: '0px',
                                                                textTransform: 'none'
                                                            }}>Front-End Developer</h2><div
                                                            class="gdlr-core-stunning-text-item-content"><p>
                                                               <br/>We are looking for a go-getter with a constructive approach towards developing new elements.

                                                            You shall be expected to collaborate with user experience designers, graphic designers, and backend engineers.

                                                            You'll be responsible for building and maintaining elements that include graphs, widgets, custom UI controls, and much more.<br/><br/>

                                                            Pre-requisites:<br/><br/>

                                                            Strong appreciation for design/usability<br/>
                                                            1+ years of experience in front-end development.<br/>
                                                            A deep understanding of JavaScript/CSS/HTML5<br/>
                                                            Expertise in React and Redux<br/>
                                                            Understanding of server-side CSS pre-processing platforms, such as LESS and SASS<br/>
                                                            Proficient in cross-browser compatibility issues and ways to work around them<br/>
                                                            Good understanding of SEO principles and ensuring that application will adhere to them<br/>
                                                            Comfortable with high paced environment while multi-tasking<br/>
                                                            Good communication and interpersonal skills</p>
                                                        </div></div></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="gdlr-core-pbf-wrapper"><div
                                class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js">
                                    
                                    <div
                                    class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                        class="gdlr-core-pbf-column
                                        gdlr-core-column-60
                                        gdlr-core-column-first"><div
                                            class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js"><div
                                                class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js"><div
                                                    class="gdlr-core-pbf-element"><div
                                                        class="gdlr-core-stunning-text-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        gdlr-core-left-align
                                                        gdlr-core-stunning-text-caption-above-title
                                                        job-list"><h2
                                                            class="gdlr-core-stunning-text-item-title"
                                                            style={{
                                                                fontSize:'23px',
                                                                letterSpacing: '0px',
                                                                textTransform: 'none'
                                                            }}>Back-End Developer</h2><div
                                                            class="gdlr-core-stunning-text-item-content"><p>
                                                               <br/>We are looking for a dynamic hustler who has a positive approach towards developing and creating new elements for user's ease.

                                                                You shall be expected to collaborate with user experience designers, graphic designers, and front-end engineers.<br/>

                                                                You'll be responsible for building and maintaining elements that include graphs, widgets, custom UI controls, and much more.<br/><br/>

                                                                Pre-requisites:<br/><br/>

                                                                Strong appreciation for logic/optimization.<br/>
                                                                1+ years of experience in backend-end development.<br/>
                                                                A deep understanding of JavaScript.<br/>
                                                                Expertise in Node, express and Python.<br/>
                                                                Ability to quickly implement and adapt to new projects.<br/>
                                                                Comfortable with high paced environment.<br/>
                                                                Good communication and interpersonal skills.<br/></p>
                                                        </div></div></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="gdlr-core-pbf-wrapper"><div
                                class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js">
                                    
                                    <div
                                    class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                        class="gdlr-core-pbf-column
                                        gdlr-core-column-60
                                        gdlr-core-column-first"><div
                                            class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js"><div
                                                class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js"><div
                                                    class="gdlr-core-pbf-element"><div
                                                        class="gdlr-core-stunning-text-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb
                                                        gdlr-core-left-align
                                                        gdlr-core-stunning-text-caption-above-title
                                                        job-list"><h2
                                                            class="gdlr-core-stunning-text-item-title"
                                                            style={{
                                                                fontSize:'23px',
                                                                letterSpacing: '0px',
                                                                textTransform: 'none'
                                                            }}>Creative leader</h2><div
                                                            class="gdlr-core-stunning-text-item-content"><p>
                                                               <br/>We are looking for a creative thinktank who is passionate about visual storytelling and can direct short as well as sensitive clips with subtlety and seamlessness.<br/>

                                                                You'll be responsible for creating an impeccable visual experience with the brand's perspective for their target audience.<br/><br/>

                                                                Pre-requisites:<br/><br/>

                                                                strong intuition to weave stories in given situations<br/>
                                                                comprehensive understanding of production and professional shoots<br/>
                                                                1+ years of experience in a digital media agency<br/>
                                                                ability to direct and administer pre as well as post production of a shoot<br/>
                                                                ability to comprehend a brand's vision into a visual storytelling</p>
                                                        </div></div></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                                            
                                                            <div
                            class="gdlr-core-pbf-wrapper" style={{
                                padding: '60px 90px 30px 90px'
                            }}><div
                                class="gdlr-core-pbf-background-wrap"
                                style={{
                                    backgroundColor: '#ededed'
                                }}></div><div
                                class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js"><div
                                    class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container"><div
                                        class="gdlr-core-pbf-column
                                        gdlr-core-column-30
                                        gdlr-core-column-first"
                                        id="gdlr-core-column-45560"><div
                                            class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js"style={{
                                                padding: '0px 0px 0px 130px'
                                            }}><div
                                                class="gdlr-core-pbf-background-wrap"></div><div
                                                class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js"><div
                                                    class="gdlr-core-pbf-element"><div
                                                        class="gdlr-core-title-item
                                                        gdlr-core-item-pdb
                                                        clearfix
                                                        gdlr-core-right-align
                                                        gdlr-core-title-item-caption-top
                                                        gdlr-core-item-pdlr"><div
                                                            class="gdlr-core-title-item-title-wrap"><h3
                                                                class="gdlr-core-title-item-title
                                                                gdlr-core-skin-title" style={{
                                                                    fontSize:'70px',
                                                                    fontWeight:'700',
                                                                    textTransform:'none'
                                                                }}>Apply
                                                                for this job<span
                                                                    class="gdlr-core-title-item-title-divider
                                                                    gdlr-core-skin-divider"></span></h3></div></div></div></div></div></div><div
                                        class="gdlr-core-pbf-column
                                        gdlr-core-column-30"><div
                                            class="gdlr-core-pbf-column-content-margin
                                            gdlr-core-js"><div
                                                class="gdlr-core-pbf-column-content
                                                clearfix gdlr-core-js"><div
                                                    class="gdlr-core-pbf-element"><div
                                                        class="gdlr-core-contact-form-7-item
                                                        gdlr-core-item-pdlr
                                                        gdlr-core-item-pdb"><div
                                                            role="form"
                                                            class="wpcf7"
                                                            id="wpcf7-f257-p138-o5"
                                                            lang="en-US"
                                                            dir="ltr">
                                                            <div
                                                                class="screen-reader-response"><p
                                                                    role="status"
                                                                    aria-live="polite"
                                                                    aria-atomic="true"></p>
                                                                <ul></ul></div>
                                                            <form
                                                                // action="https://lagosmart.com/careers/?ertthndxbcvs=yes#wpcf7-f257-p138-o5"
                                                                method="post"
                                                                // class="wpcf7-form init"
                                                                // enctype="multipart/form-data"
                                                                // novalidate="novalidate"
                                                                // data-status="init"
                                                                >
                                                                {/* <div
                                                                    style={{
                                                                        // display:'none'
                                                                    }}>
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7"
                                                                        value="257"
                                                                        />
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7_version"
                                                                        value="5.6.2"
                                                                        />
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7_locale"
                                                                        value="en_US"
                                                                        />
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7_unit_tag"
                                                                        value="wpcf7-f257-p138-o5"
                                                                        />
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7_container_post"
                                                                        value="138"
                                                                        />
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7_posted_data_hash"
                                                                        value=""
                                                                        />
                                                                </div> */}
                                                                <div
                                                                    class="gdlr-core-input-wrap
                                                                    gdlr-core-large
                                                                    gdlr-core-full-width
                                                                    gdlr-core-with-column
                                                                    gdlr-core-border">
                                                                    <div
                                                                        class="gdlr-core-column-60"><span
                                                                            class="wpcf7-form-control-wrap"
                                                                            data-name="your-name"><input
                                                                                type="text"
                                                                                name="your-name"
                                                                                value=""
                                                                                size="40"
                                                                                class="wpcf7-form-control
                                                                                wpcf7-text
                                                                                wpcf7-validates-as-required
                                                                                bir"
                                                                                aria-required="true"
                                                                                // aria-invalid="false"
                                                                                placeholder="Your
                                                                                Name*"
                                                                                /></span></div>
                                                                    <div
                                                                        class="clear"></div>
                                                                    <div
                                                                        class="gdlr-core-column-60"><span
                                                                            class="wpcf7-form-control-wrap"
                                                                            data-name="your-number"><input
                                                                                type="number"
                                                                                name="your-number"
                                                                                value=""
                                                                                class="wpcf7-form-control
                                                                                wpcf7-number
                                                                                wpcf7-validates-as-required
                                                                                wpcf7-validates-as-number
                                                                                bir"
                                                                                aria-required="true"
                                                                                aria-invalid="false"
                                                                                placeholder="Your
                                                                                Number*"
                                                                                /></span></div>
                                                                    <div
                                                                        class="gdlr-core-column-60"><span
                                                                            class="wpcf7-form-control-wrap"
                                                                            data-name="your-email"><input
                                                                                type="email"
                                                                                name="your-email"
                                                                                value=""
                                                                                size="40"
                                                                                class="wpcf7-form-control
                                                                                wpcf7-text
                                                                                wpcf7-email
                                                                                wpcf7-validates-as-required
                                                                                wpcf7-validates-as-email
                                                                                bir"
                                                                                aria-required="true"
                                                                                aria-invalid="false"
                                                                                placeholder="Your
                                                                                Email*"
                                                                                /></span></div>
                                                                    <div
                                                                        class="gdlr-core-column-60"><span
                                                                            class="wpcf7-form-control-wrap"
                                                                            data-name="your-experience"><input
                                                                                type="text"
                                                                                name="your-experience"
                                                                                value=""
                                                                                size="40"
                                                                                class="wpcf7-form-control
                                                                                wpcf7-text
                                                                                wpcf7-validates-as-required
                                                                                bir"
                                                                                aria-required="true"
                                                                                aria-invalid="false"
                                                                                placeholder="Your
                                                                                Experience*"
                                                                                /></span></div>
                                                                    <div
                                                                        class="clear"></div>
                                                                    <div
                                                                        class="gdlr-core-column-60"><span
                                                                            class="wpcf7-form-control-wrap"
                                                                            data-name="your-message"><textarea
                                                                                name="your-message"
                                                                                cols="40"
                                                                                rows="10"
                                                                                class="wpcf7-form-control
                                                                                wpcf7-textarea
                                                                                wpcf7-validates-as-required
                                                                                bir"
                                                                                aria-required="true"
                                                                                aria-invalid="false"
                                                                                placeholder="Message*"></textarea></span></div>
                                                                    <div
                                                                        class="clear"></div>
                                                                    <div
                                                                        class="gdlr-core-column-60">*
                                                                        Upload
                                                                        CV</div>
                                                                    <div
                                                                        class="gdlr-core-column-60"><span
                                                                            class="wpcf7-form-control-wrap"
                                                                            data-name="file-upload"><input
                                                                                type="file"
                                                                                name="file-upload"
                                                                                size="40"
                                                                                class="wpcf7-form-control
                                                                                wpcf7-file
                                                                                bir"
                                                                                accept=".doc,.docx,.pdf"
                                                                                aria-invalid="false"
                                                                                /></span></div>
                                                                    <div
                                                                        class="clear"></div>
                                                                    <div
                                                                        class="gdlr-core-column-60"><input
                                                                            type="submit"
                                                                            value="Submit
                                                                            Now"
                                                                            class="wpcf7-form-control
                                                                            has-spinner
                                                                            wpcf7-submit
                                                                            gdlr-core-large"
                                                                            /></div>
                                                                </div>
                                                                <input
                                                                    type='hidden'
                                                                    class='wpcf7-pum'
                                                                    value='{"
                                                                    closepopup"
                                                                    :false,"
                                                                    closedelay"
                                                                    :0,"
                                                                    openpopup"
                                                                    :false,"
                                                                    openpopup_id"
                                                                    :0}' /><div
                                                                    class="wpcf7-response-output"
                                                                    aria-hidden="true"></div></form></div></div></div></div></div></div></div></div></div>
                            </div>
                            </div>
           

            <Footer/>

        </div>
    )
}
export default Career;