import React from 'react'
import { NavLink } from 'react-router-dom';
import './navbar.css'

function NavBar() {
    return (
        <>
        <div class="infinite-mobile-header-wrap">
    <div class="infinite-mobile-header
            infinite-header-background infinite-style-slide
            infinite-sticky-mobile-navigation" id="infinite-mobile-header">
        <div class="infinite-mobile-header-container infinite-container clearfix">
            <div class="infinite-logo infinite-item-pdlr">
                <div class="infinite-logo-inner"><a class="infinite-fixed-nav-logo" href="/"><img
                            src="assets/wp-content/uploads/2022/07/LagoSmart-Logo.png" alt="" width="150" height="46" title="Logo"
                            /></a><a class="infinite-orig-logo"
                        href="/"><img src="assets/wp-content/uploads/2022/07/LagoSmart-Logo.png" alt=""
                            width="300" height="88" title="LagoSmart-Logo" /></a></div>
            </div>
            <div class="infinite-mobile-menu-right">
                <div class="infinite-main-menu-search" id="infinite-mobile-top-search"><i class="fa fa-search"></i>
                </div>
                <div class="infinite-top-search-wrap">
                    <div class="infinite-top-search-close"></div>

                    <div class="infinite-top-search-row">
                        <div class="infinite-top-search-cell">
                            <form role="search" method="get" class="search-form" action="https://lagosmart.com/">
                                <input type="text" class="search-field infinite-title-font" placeholder="Search..."
                                    value="" name="s"/>
                                <div class="infinite-top-search-submit"><i class="fa fa-search"></i></div>
                                <input type="submit" class="search-submit" value="Search"/>
                                <div class="infinite-top-search-close"><i class="icon_close"></i></div>
                            </form>
                        </div>
                    </div>

                </div>
                <div class="infinite-mobile-menu"><a class="infinite-mm-menu-button
                            infinite-mobile-menu-button infinite-mobile-button-hamburger-small"
                        href="#infinite-mobile-menu"><span></span></a>
                    <div class="infinite-mm-menu-wrap infinite-navigation-font" id="infinite-mobile-menu"
                        data-slide="left">
                        <ul id="menu-main-menu" class="m-menu">
                            <li class="menu-item menu-item-type-post_type
                                    menu-item-object-page menu-item-home current-menu-item page_item
                                    page-item-185 current_page_item menu-item-281"><a href="/" aria-current="page">Home</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page
                                    menu-item-282"><a href="/about">About Us</a></li>
                            <li class="menu-item menu-item-type-custom menu-item-object-custom
                                    menu-item-has-children menu-item-289"><a href="#">Services</a>
                                <ul class="sub-menu">
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page
                                            menu-item-288"><a href="/online-reputation-management">Online
                                            Reputation Management</a></li>
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page
                                            menu-item-435"><a href="/e-commerce">E-Commerce</a></li>
											<li class="menu-item menu-item-type-post_type menu-item-object-page
													menu-item-435" data-size="60"><a href="/digitalmarketing">Digital Marketing</a></li>
                                </ul>
                            </li>
                            <li class="menu-item menu-item-type-custom menu-item-object-custom
                                    menu-item-has-children menu-item-340"><a href="#">Products</a>
                                <ul class="sub-menu">
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page
                                            menu-item-341"><a href="/Vhrms">VHRMS</a></li>
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page
                                            menu-item-342"><a href="/vpay">VPay</a></li>
                                </ul>
                            </li>
							<li class="menu-item menu-item-type-post_type menu-item-object-page
											menu-item-283 infinite-normal-menu"><a href="/blogs">Blog</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page
                                    menu-item-283"><a href="/career">Careers</a></li>
                            <li class="menu-item menu-item-type-post_type menu-item-object-page
                                    menu-item-285"><a href="/contact">Contact Us</a></li>
							<li class="menu-item menu-item-type-post_type menu-item-object-page
                                    menu-item-285"><a href="/get-ticket">Ticket</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

								<div class="all-logos">
                                    <div class="linkage">
                                        <a href="https://www.virdi.biz/" target="_blank"> <img src="assets/img/virdi2.png" alt="" height="20px" width="40px"/></a>
                                        <a href="/"><img class="current-img" src="assets/img/logo-ai.png" alt="" height="20px" width="70px" /></a>
                                        <a href="https://techstate.org/" target="_blank"><img src="assets/img/techstatelogo.png" alt="" height="20px" width="70px"/></a>
                                        <a href="https://ubio.biz/" target="_blank"><img src="assets/img/ubio-removebg-preview.png" alt="" height="20px"width="50px" /></a>
                                        <a href="https://lagosmart.net/" target="_blank"><img src="assets/img/AxxIon_Brochure__1_-__-removebg-preview.png" alt="" height="27px" width="70px"/></a>
                                        <a href="https://lagosmart.biz/" target="_blank"><img src="assets/img/logonet-removebg-preview.png" alt="" height="27px" width="70px"/></a>
                                    </div>
									
                                </div>
								
        {/* <div class="infinite-top-bar">
				<div class="infinite-top-bar-background"></div>
				<div class="infinite-top-bar-container infinite-container">
					<div class="infinite-top-bar-container-inner clearfix">
						<div class="infinite-top-bar-right infinite-item-pdlr">
							<div class="infinite-top-bar-right-text"><a href="https://wa.me/+2348157353535"
									class="bt-whatsapp"><i class="fa
											fa-whatsapp"></i>
								</a> &nbsp; &nbsp;
								<a href="tel:+2348157353535"><i class="fa fa-phone"></i> +234 815 735
									3535 </a> &nbsp; &nbsp;
								<a href="mailto:digital@lagosmart.net" class="bt-mail"><i class="fa
											fa-envelope"></i> digital@lagosmart.net</a>
							</div>
						</div>
					</div>
				</div>
			</div> */}
            
            <header class="infinite-header-wrap infinite-header-style-plain
					infinite-style-menu-right infinite-sticky-navigation infinite-style-fixed" data-navigation-offset="75px">
				<div class="infinite-header-background"></div>
				<div class="infinite-header-container infinite-header-full">

					<div class="infinite-header-container-inner clearfix">
						<div class="infinite-logo infinite-item-pdlr">
							<div class="infinite-logo-inner"><a class="infinite-fixed-nav-logo" href="/"><img
										src="assets/wp-content/uploads/2022/07/LagoSmart-Logo.png" alt="" width="150" height="46"
										title="Logo"  /></a><a class="infinite-orig-logo" href="/"><img
										src="assets/wp-content/uploads/2022/07/LagoSmart-Logo.png" alt="" width="300"
										height="88" title="LagoSmart-Logo" /></a></div>
						</div>
						<div class="infinite-navigation infinite-item-pdlr clearfix
								infinite-navigation-submenu-indicator">
							<div class="infinite-main-menu" id="infinite-main-menu">
								<ul id="menu-main-menu-1" class="sf-menu">
									<li class="menu-item
											menu-item-type-post_type menu-item-object-page menu-item-home
											current-menu-item page_item page-item-185 current_page_item
											menu-item-281 infinite-normal-menu"><a href="/">Home</a></li>
									<li class="menu-item menu-item-type-post_type menu-item-object-page
											menu-item-282 infinite-normal-menu"><a href="/about">About
											Us</a></li>
									<li class="menu-item menu-item-type-custom menu-item-object-custom
											menu-item-has-children menu-item-289 infinite-normal-menu"><a href="#" class="sf-with-ul-pre">Services</a>
										<ul class="sub-menu">
											<li class="menu-item menu-item-type-post_type menu-item-object-page
													menu-item-288" data-size="60"><a href="/online-reputation-management">Online Reputation
													Management</a></li>
											<li class="menu-item menu-item-type-post_type menu-item-object-page
													menu-item-435" data-size="60"><a href="/e-commerce">E-Commerce</a></li>
													<li class="menu-item menu-item-type-post_type menu-item-object-page
													menu-item-435" data-size="60"><a href="/digitalmarketing">Digital Marketing</a></li>
										</ul>
									</li>
									<li class="menu-item menu-item-type-custom menu-item-object-custom
											menu-item-has-children menu-item-340 infinite-normal-menu"><a href="#" class="sf-with-ul-pre">Products</a>
										<ul class="sub-menu">
											<li class="menu-item menu-item-type-post_type menu-item-object-page
													menu-item-341" data-size="60"><a href="/Vhrms">VHRMS</a></li>
											<li class="menu-item menu-item-type-post_type menu-item-object-page
													menu-item-342" data-size="60"><a href="/vpay">VPay</a></li>
										</ul>
									</li>
									<li class="menu-item menu-item-type-post_type menu-item-object-page
											menu-item-283 infinite-normal-menu"><a href="/blogs">Blog</a></li>
									<li class="menu-item menu-item-type-custom menu-item-object-custom
											menu-item-has-children menu-item-340 infinite-normal-menu"><a href="#" class="sf-with-ul-pre">More</a>
										<ul class="sub-menu">
											<li class="menu-item menu-item-type-post_type menu-item-object-page
													menu-item-341" data-size="60"><a href="/career">Careers</a></li>
											<li class="menu-item menu-item-type-post_type menu-item-object-page
													menu-item-342" data-size="60"><a href="/contact">Contact
													Us</a></li>
											<li class="menu-item menu-item-type-post_type menu-item-object-page
													menu-item-342" data-size="60"><a href="/get-ticket">Ticket</a></li>
										</ul>
									</li>
									
								</ul>
								<div class="infinite-navigation-slide-bar
										infinite-navigation-slide-bar-style-1" id="infinite-navigation-slide-bar"></div>
							</div>
							<div class="infinite-main-menu-right-wrap clearfix">
								<div class="infinite-main-menu-search" id="infinite-top-search"><i
										class="fa fa-search"></i></div>
								<div class="infinite-top-search-wrap">
									<div class="infinite-top-search-close"></div>

									<div class="infinite-top-search-row">
										<div class="infinite-top-search-cell">
											<form role="search" method="get" class="search-form"
												action="https://lagosmart.com/">
												<input type="text" class="search-field infinite-title-font"
													placeholder="Search..." value="" name="s"/>
												<div class="infinite-top-search-submit"><i class="fa fa-search"></i>
												</div>
												<input type="submit" class="search-submit" value="Search"/>
												<div class="infinite-top-search-close"><i class="icon_close"></i></div>
											</form>
										</div>
									</div>

								</div>
							</div>
						</div>

					</div>
				</div>
			</header>
           
        </>

    )
};

export default NavBar;