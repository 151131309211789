import React from "react";
import Footer from "../partial/footer";
import NavBar from "../partial/navbar";
import './ecommerce.css'

function Ecommerce() {
    return (
        <div>
            <NavBar />
 
            <div>
                <div class="infinite-page-title-wrap
                    infinite-style-medium infinite-left-align"><div
                        class="infinite-header-transparent-substitute"></div><div
                            class="infinite-page-title-overlay"></div><div
                                class="infinite-page-title-container infinite-container"><div
                                    class="infinite-page-title-content
                            infinite-item-pdlr"><h1 class="infinite-page-title">E-Commerce</h1></div></div>
                </div>
                <div class="infinite-page-wrapper" id="infinite-page-wrapper">
                    <div
                        class="infinite-content-container infinite-container">
                        <div
                            class="infinite-content-area infinite-item-pdlr
                            infinite-sidebar-style-none clearfix" ><p><span
                            style={{
                                fontWeight: '400'
                            }}
                            >E-commerce is the
                                process of buying or selling items online.
                                It usually involves the use of credit cards
                                or PayPal. Customers can search for items,
                                buy them, and then be delivered to their
                                door. Businesses use e-commerce to sell
                                products online and avoid shipping costs. In
                                addition to businesses, individuals can sell
                                their items via e-commerce websites such as
                                eBay and Etsy. Online shopping is growing
                                faster than any other method of buying. And
                                we're not just talking about buying a
                                pair of shoes. Online shopping is the
                                preferred way to buy just about anything.
                                And there's no reason it can't
                                be done at home.</span></p>

                            <h4><b>E-Commerce SEO Services</b></h4>
                            <p><span
                            style={{
                                fontWeight: '400'
                            }}
                            >Every online business needs e-commerce SEO to be successful. 
                            As an e-commerce site grows and becomes more popular, 
                            its SEO must evolve to keep pace with the competition. 
                            For your site to perform well in search engine results, 
                            there are three critical areas: On the page, Off page, and 
                            Technical SEO. These three areas work together to achieve an 
                            ideal combination of quality content and keyword optimization 
                            that will make your site stand out among competitors and help 
                            it appear at the top of search results.</span></p>

                            <h4><b>Transparent Detailed Monthly Reports</b></h4>
                            <p><span
                            style={{
                                fontWeight: '400'
                            }}
                            >Lagosmart clients
                                can see everything we do. They can even go
                                to our client portal and access detailed
                                monthly reports. We show them where they
                                stand regarding their campaign performance
                                and even provide them with forecasts. We
                                have been doing this for over ten years now
                                and have seen some significant improvements
                                over time. We use Google Analytics and have
                                a few custom tools built on top of that.</span></p>

                            <h4><b>Proven E-commerce Strategies</b></h4>
                            <p><span
                            style={{
                                fontWeight: '400'
                            }}
                            >An E-commerce
                                strategy combines tactics that deliver a
                                profit for the business. In this course, you
                                will learn proven strategies to generate a
                                steady flow of qualified leads and
                                customers, increase traffic and improve the
                                conversion rate by using e-mail marketing,
                                social media marketing, SEO, PPC
                                advertising, and affiliate marketing. We
                                will cover these strategies in detail to
                                help you achieve sustainable growth and
                                profitable online sales.</span></p>

                            <h4><b>Let Our eCommerce SEO Pros Make Your SEO
                                Campaign a Huge Success</b></h4>
                            <p><span style={{
                                fontWeight: '400'
                            }} >We have exemplary
                                service if you are running an eCommerce
                                business and looking for some SEO services.
                                We are a leading SEO Company in Lagos,
                                Nigeria, and our team of experts can help
                                you with your eCommerce website in Nigeria.
                                We have exemplary service for you if you
                                want some SEO services.</span></p>
                            <p><span >Don't let
                                your brand suffer due to bad SEO! It would
                                help if you did what we do best: Make your
                                site perform and rank well on Google and
                                other search engines. We'll work with
                                your budget and timeline to create an
                                effective strategy to get you noticed by
                                your audience.</span></p>

                            <h4><b >We are #1 eCommerce SEO agency in Lagos, we
                                deliver proven results.</b></h4>
                            <p><span style={{
                                fontWeight: '400'
                            }} >At our agency, we
                                believe that the best SEO for your business
                                is not necessarily the most expensive or
                                complicated. We think of SEO as a science
                                and art. We use the scientific method to
                                discover what will work for your business,
                                and then we use our artistic creativity to
                                find the best way to make that happen.
                                That's why we focus on working with
                                companies of any size, whether you're
                                a local Brand or corporation.</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </div>
    )
}
export default Ecommerce;