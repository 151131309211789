import React from "react";
import Footer from "../../partial/footer";
import NavBar from "../../partial/navbar";
import './social-engagement.css'

function SocialEngage(){
    return(
       <div>
           <NavBar/>
           <div class="infinite-page-wrapper" id="infinite-page-wrapper">
           <div class="infinite-blog-title-wrap  infinite-style-small">
                    <div class="infinite-header-transparent-substitute"></div>
                    <div class="infinite-blog-title-top-overlay"></div>
                    <div class="infinite-blog-title-overlay"></div>
                    <div class="infinite-blog-title-bottom-overlay"></div>
                    <div class="infinite-blog-title-container infinite-container">
                        <div class="infinite-blog-title-content infinite-item-pdlr">
                            <header class="infinite-single-article-head infinite-single-blog-title-style-1 clearfix">
                                <div class="infinite-single-article-date-wrapper post-date updated">
                                    <div class="infinite-single-article-date-day">28</div>
                                    <div class="infinite-single-article-date-month">March</div>
                                </div>
                                <div class="infinite-single-article-head-right">
                                    <h1 class="infinite-single-article-title">Why is social media Engagement important?</h1>
                                    <div class="infinite-blog-info-wrapper">
                                        <div
                                            class="infinite-blog-info infinite-blog-info-font infinite-blog-info-author vcard author post-author ">
                                            <span class="infinite-head"><i class="icon_documents_alt"></i></span><span
                                                class="fn"><a href="" title="Posts by admin"
                                                    rel="author">admin</a></span></div>
                                        <div
                                            class="infinite-blog-info infinite-blog-info-font infinite-blog-info-category ">
                                            <span class="infinite-head"><i class="icon_folder-alt"></i></span><a
                                                href="/digital-marketing" rel="tag">Digital
                                                Media Services</a></div>
                                        <div
                                            class="infinite-blog-info infinite-blog-info-font infinite-blog-info-comment-number ">
                                            <span class="infinite-head"><i class="icon_comment_alt"></i></span>0 </div>
                                    </div>
                                </div>
                            </header>
                        </div>
                    </div>
                </div>
                </div>
           <div class="container">
                <h4 style={{fontSize:'20px'}}>Why is social media Engagement important?</h4>
               <p>
                <b style={{fontSize:'20px'}}>O</b>ver the years, with the advent and improvement of technology, Social media has grown to be the heart of the consumer-Brand 
               business relationship. Marketers are aware that social media is one of the best platform for connecting, engaging consumers, 
               raising brand recognition, creating new leads, and converting leads into customers because more than half of the world's population 
               are on social media.</p>

               <h5>Why is social media Engagement important?</h5>
               <p>
               The word "social media engagement" is a catch-all for activities that indicate and quantify how much your audience engages with your content 
               (Videos or Graphics). Likes, comments, and shares are just a few examples of social media involvement, but it differs per platform. 
               A businesses' social media efforts are measured by the number of shares, likes, and comments they receive from the general public. 
               This becomes a makeup of their social media status and relevance among competitors.</p>
               

               <p style={{color:'#0E3151'}}><i>Here are a few examples of social media platforms: Facebook, LinkedIn, Instagram, Twitter, 
                   YouTube, Hootsuite, Tiktok, snapchat, amongst many others.</i>
               </p><br/><br/><br/>

               
                <h5>Why is Social media Engagement important to your online identity?</h5>

                <p><b style={{color:'#AB6261'}}>It Builds Social Trust </b><br/> Consumers can develop deeper emotional connections with brands through social media. According to social 
                media research by Edelman, 39% of consumers only trust brands if they have engaged with them on social media. Hence, Being active on social 
                media is therefore no longer an option.</p>

                <p><b style={{color:'#AB6261'}}>It allows for awareness and reach</b><br/>One of the finest and modern methods to interact with current consumers and attract 
                new audiences is through social media. Although there are many other avenues that marketers may use to distribute their content, social media is the 
                most efficient. Paid social media advert is an effective approach to reach new prospects, while organic social media 
                posts is a wonderful way to increase connection with current consumers. Research shows that 27% of internet users 
                admitted that they discover new items and brands through paid social ads(Google ads), while 72% of B2B marketers 
                remain relevant through Paid adverts..</p>

                <p><b style={{color:'#AB6261'}}>Engage with your audience</b><br/> Consumers search for and research products on social media before making 
                any purchase. It helps them learn more about things to purchase and out weigh their options, 54% of people who use social media browse 
                for their shopping list. Furthermore, 67% of internet buyers have purchased a product after seeing a social network ad for the product 
                they are purchasing. Social media will inevitably be a very effective avenue for generating leads and closing business deals.
                In conclusion, an engaged social media page is an engaged business, lagoSmart offers the best deals in social media management and online 
                brand reputation. 
                .</p>

                <i style={{color:'#0E3151'}}>Connect with us and see your brand taking the lead in your industry. Join the train today. </i>

                
           </div>
           <Footer/>
       </div>
    )
}
export default SocialEngage;