import React from 'react'
import Footer from '../partial/footer';
import NavBar from '../partial/navbar';
import './Home.css'

function Home() {

    return (
        <div>
            <NavBar />

            <div class="infinite-page-wrapper" id="infinite-page-wrapper">
                <div class="gdlr-core-page-builder-body">
                    <div class="gdlr-core-pbf-wrapper" style={{ padding: '0px 0px 0px 0px' }}>
                        <div class="gdlr-core-pbf-background-wrap"></div>
                        <div class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js">
                            <div class="gdlr-core-pbf-wrapper-container
                                    clearfix
                                    gdlr-core-pbf-wrapper-full-no-space">
                                <div class="gdlr-core-pbf-element">
                                    <div class="gdlr-core-revolution-slider-item
                                            gdlr-core-item-pdlr
                                            gdlr-core-item-pdb" >
                                        <p class="rs-p-wp-fix"></p>
                                        <rs-module-wrap id="rev_slider_1_1_wrapper" data-source="gallery" style={{
                                            background: 'transparent',
                                            padding: '0',
                                            margin: '0px auto',
                                            marginTop: '0',
                                            marginBottom: '0'
                                        }}>
                                            <rs-module id="rev_slider_1_1" data-version="6.2.23">
                                                <rs-slides>
                                                    <rs-slide data-key="rs-1" data-title="Slide"
                                                        data-thumb="//lagosmart.com/wp-content/uploads/revslider/slider-home/banner-1-bg-1-50x100.jpg"
                                                        data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;">
                                                        <img src="assets/wp-content/uploads/revslider/slider-home/banner-1-bg-1.jpg"
                                                            alt="Lago Smart
                                                                - Global Digital
                                                                Agency" title="banner-1-bg-1.jpg" width="1980"
                                                            height="1109" class="rev-slidebg" data-no-retina />
                                                        <rs-layer id="slider-1-slide-1-layer-5" data-type="text"
                                                            data-color="#050d1a" data-rsp_ch="on"
                                                            data-xy="x:c;xo:-316px,-316px,-179px,6px;yo:122px,122px,69px,368px;"
                                                            data-text="w:normal;s:44,44,25,38;l:57,57,32,48;"
                                                            data-dim="w:439px,439px,250px,387px;"
                                                            data-frame_0="x:0,0,0,0px;y:0,0,0,0px;"
                                                            data-frame_1="x:0,0,0,0px;y:0,0,0,0px;"
                                                            data-frame_999="o:0;st:w;" className='transform'><b>Digital
                                                                transformation
                                                            </b> is the new normal, connect
                                                            globally through
                                                            Digital
                                                            Services
                                                        </rs-layer>
                                                        <rs-layer id="slider-1-slide-1-layer-12" data-type="image"
                                                            data-rsp_ch="on"
                                                            data-xy="xo:633px,633px,360px,-4px;yo:46px,46px,26px,38px;"
                                                            data-text="w:normal;s:20,20,11,6;l:0,0,14,8;"
                                                            data-dim="w:797px,797px,453px,496px;h:498px,498px,283px,310px;"
                                                            data-frame_999="o:0;st:w;"><img
                                                                src="assets/wp-content/uploads/revslider/slider-home/banne-globe.png"
                                                                alt="Lago
                                                                        Smart -
                                                                        Digital
                                                                        Agency
                                                                        Lagos
                                                                        Nigeria" width="1052" height="657"
                                                                data-no-retina />
                                                        </rs-layer>
                                                        <rs-layer id="slider-1-slide-1-layer-15" data-type="text"
                                                            data-rsp_ch="on"
                                                            data-xy="x:l,l,l,c;xo:161px,161px,91px,0;y:t,t,t,b;yo:392px,392px,223px,89px;"
                                                            data-text="w:normal;s:20,20,11,6;l:25,25,14,8;"
                                                            data-frame_999="o:0;st:w;"
                                                            className='indexz'
                                                        > <br /><br /><a
                                                            class="popmake-209" href='/contact'>REQUEST
                                                                A DEMO
                                                            </a>
                                                        </rs-layer>
                                                    </rs-slide>
                                                    <rs-slide data-key="rs-2" data-title="Slide"
                                                        data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;">
                                                        <img src="assets/wp-content/plugins/revslider/public/assets/assets/transparent.png"
                                                            alt="Slide" title="Home" data-bg="c:#a1d6dc;"
                                                            class="rev-slidebg" data-no-retina />
                                                        <rs-layer id="slider-1-slide-2-layer-0" data-type="image"
                                                            data-rsp_ch="on"
                                                            data-xy="xo:829px,829px,472px,66px;yo:8px,8px,4px,12px;"
                                                            data-text="w:normal;s:20,20,11,6;l:0,0,14,8;"
                                                            data-dim="w:443px,443px,252px,343.99199999999996px;h:535px,535px,304px,415px;"
                                                            data-frame_999="o:0;st:w;" className='dat' ><img
                                                                src="assets/wp-content/uploads/2022/01/home-landing-img.webp"
                                                                alt="Social
                                                                        Media
                                                                        Marketing
                                                                        firm -
                                                                        Lago
                                                                        Smart" width="580" height="700" data-no-retina />
                                                        </rs-layer>
                                                        <rs-layer id="slider-1-slide-2-layer-1" data-type="text"
                                                            data-color="#001849" data-rsp_ch="on"
                                                            data-xy="x:c;xo:-275px,-275px,-156px,6px;yo:120px,120px,68px,447px;"
                                                            data-text="w:normal;s:44,44,25,34;l:57,57,32,44;"
                                                            data-dim="w:526px,526px,299px,427px;"
                                                            data-frame_999="o:0;st:w;" className='transform'><b>Transforming
                                                                Customer
                                                                Service,
                                                            </b>Manage
                                                            your Brand
                                                            Reputation
                                                            through
                                                            Online
                                                            Reputation
                                                            Management
                                                        </rs-layer>
                                                        <rs-layer id="slider-1-slide-2-layer-16" data-type="text"
                                                            data-rsp_ch="on"
                                                            data-xy="x:l,l,l,c;xo:146px,146px,83px,0;y:t,t,t,b;yo:389px,389px,221px,45px;"
                                                            data-text="w:normal;s:20,20,11,6;l:25,25,14,8;"
                                                            data-frame_999="o:0;st:w;"
                                                            className='indexz'
                                                        ><br /><br /><a
                                                            class="popmake-209" href='/contact'>
                                                                Free Trial</a>
                                                        </rs-layer>
                                                    </rs-slide>
                                                </rs-slides>
                                            </rs-module>

                                        </rs-module-wrap>
                                        {/* END REVOLUTION SLIDER  */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* new about us */}
                    <div class="gdlr-core-pbf-wrapper" style={{ padding: '60px 60px 60px 60px' }} id="gdlr-core-wrapper-1">
                        <div class="about-us col-12">
                            <div class='imgAbout col-6'>
                                <img src='assets/frame 1-1.png' width='100%' height='100%' alt='' />
                            </div>
                            <div class='contentAbout'>
                                <h2>About Us</h2>
                                <p style={{ textAlign: 'left' }}>We are a full-service, data-driven digital marketing and demand generation agency.
                                    Originally founded in 2001 as lagoSmart, we has evolved its core services to adapt to
                                    the ever-changing needs and challenges of marketers – helping them to achieve their
                                    online marketing goals through search marketing, paid media, content marketing and marketing automation.<br />
                                    With experience in a wide range of digital marketing disciplines, lagoSmart uses the latest information
                                    and techniques to help companies maximize their sales funnel and get the highest quality results based
                                    on their goals. What sets us apart is our commitment to excellence.<br /><br />  <b> We are dedicated to deliver exceptional
                                        services and results, and we pride ourselves on our attention to detail, creativity, and innovation.</b>
                                </p>
                                <a style={{ color: 'white' }} href='/contact' class="startProject">Start Project <i style={{ marginLeft: '10px' }} class='fa fa-angle-right'></i> </a>
                            </div>
                        </div>
                    </div>

                    {/* mobile view  about us*/}
                    <div>
                        <div class="mobileAbout">
                            <h2>About Us</h2>
                            <div class='imgAbout'>
                                <img src='assets/frame 1-1.png' width='100%' height='100%' alt='' />
                            </div>
                            <div class='contentAbout'>

                                <p class='ptext'>We are a full-service, data-driven digital marketing and demand generation agency.
                                    Originally founded in 2001 as lagoSmart, we has evolved its core services to adapt to
                                    the ever-changing needs and challenges of marketers – helping them to achieve their
                                    online marketing goals through search marketing, paid media, content marketing and marketing automation.<br />
                                    With experience in a wide range of digital marketing disciplines,lagoSmart uses the latest information
                                    and techniques to help companies maximize their sales funnel and get the highest quality results based
                                    on their goals. What sets us apart is our commitment to excellence.<br /><br />  <b> We are dedicated to deliver exceptional
                                        services and results, and we pride ourselves on our attention to detail, creativity, and innovation.</b>
                                </p>
                                <div class="startProject" style={{ marginLeft: '30%' }}>
                                    <a style={{ color: 'white' }} href='/contact' >Start Project <i style={{ marginLeft: '10px' }} class='fa fa-angle-right'></i> </a>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* how we help section */}
                    {/* <div class="wrapper"> */}
                    <div class="content_wrapper brand_wrap">
                        {/* <div class="gdlr-core-pbf-wrapper" style={{padding:'60px 60px 60px 30px'}} id="gdlr-core-wrapper-1"> */}
                        <div class="row row-xl-3 row-l-3 row-m-1 flex-m">
                            <div class="col-xl-2 col-l-1 col-m-12" data-aos="zoom-in"></div>
                            <div class="col-xl-8 col-l-10 col-m-12 align-center" data-aos="zoom-in">
                                <h2 style={{ textAlign: 'center' }}> What We Stand For</h2>
                            </div>
                            <div class="col-xl-2 col-l-1 col-m-12" data-aos="zoom-in"></div>
                        </div>
                        <div class="container-fluid ring">
                            <div class="work_area">
                                <ul>
                                    <li data-aos="zoom-in"><img src="assets/icons/stream.png" weight='10px' alt="brand" />
                                        <h6>Reliable</h6>
                                        <p>we are trustworthy and dependable.</p>
                                    </li>
                                    <li data-aos="zoom-in"><img src="assets/icons/details.png" weight='10px' alt="brand" />
                                        <h6>Detailed</h6>
                                        <p>we provide comprehensive and thorough information.</p>
                                    </li>
                                    <li data-aos="zoom-in"> <img src="assets/icons/brand.png" weight='10px' alt="brand" />
                                        <h6>Responsible</h6>
                                        <p>We are reliable and always proactive in addressing problems.</p>
                                    </li>
                                </ul>
                            </div>

                            <div class="work_area">
                                <ul>
                                    <li data-aos="zoom-in"><img src="assets/icons/help.png" alt="brand" />
                                        <h6>Help</h6>
                                        <p>we listen, offer advice and provide guidance to brands that are struggling with a challenge or facing a difficult situation. </p>
                                    </li>
                                    <li data-aos="zoom-in"><img src="assets/icons/purpose.png" weight='10px' alt="brand" />
                                        <h6>Purpose</h6>
                                        <p>It is the underlying motivation that guides our decisions and actions. </p>
                                    </li>
                                    <li data-aos="zoom-in"> <img src="assets/icons/happiness.png" weight='10px' alt="brand" />
                                        <h6>Honesty</h6>
                                        <p>Honesty is an essential value that fosters trust and integrity with all our clients.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* mobile view ring */}
                        <div class="container-fluid mobilering">
                            <div class="workview">
                                <ul>
                                    <li data-aos="zoom-in"><img src="assets/icons/stream.png" weight='10px' alt="brand" />
                                        <h6>Reliable</h6>
                                        <p>we are trustworthy and dependable.</p>
                                    </li>
                                    <li data-aos="zoom-in"><img src="assets/icons/details.png" weight='10px' alt="brand" />
                                        <h6>Detailed</h6>
                                        <p>we provide comprehensive and thorough information.</p>
                                    </li>
                                    <li data-aos="zoom-in"> <img src="assets/icons/brand.png" weight='10px' alt="brand" />
                                        <h6>Responsible</h6>
                                        <p>We are reliable and always proactive in addressing problems.</p>
                                    </li>
                                </ul>
                            </div>

                            <div class="workview">
                                <ul>
                                    <li data-aos="zoom-in"><img src="assets/icons/help.png" alt="brand" />
                                        <h6>Help</h6>
                                        <p>we listen, offer advice and provide guidance to brands that are struggling with a challenge or facing a difficult situation. </p>
                                    </li>
                                    <li data-aos="zoom-in"><img src="assets/icons/purpose.png" weight='10px' alt="brand" />
                                        <h6>Purpose</h6>
                                        <p>It is the underlying motivation that guides our decisions and actions. </p>
                                    </li>
                                    <li data-aos="zoom-in"> <img src="assets/icons/happiness.png" weight='10px' alt="brand" />
                                        <h6>Honesty</h6>
                                        <p>Honesty is an essential value that fosters trust and integrity with all our clients.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                    {/* </div> */}


                    {/* Our services */}
                    <div class="gdlr-core-pbf-wrapper" style={{ padding: '60px 60px 60px 60px' }} id="gdlr-core-wrapper-1">
                        <div class="gdlr-core-pbf-background-wrap" style={{ backgroundColor: '#ededed' }}>
                            <div class="gdlr-core-pbf-background
                                        gdlr-core-parallax gdlr-core-js" style={{
                                    opacity: .5,
                                    backgroundImage: "url('assets/wp-content/uploads/2022/01/bg-s.png')",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                }} data-parallax-speed="0.8">
                            </div>
                        </div>
                        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js">
                            <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full-no-space">
                                <div class="gdlr-core-pbf-element">
                                    <div class="gdlr-core-title-item
                                                gdlr-core-item-pdb clearfix
                                                gdlr-core-center-align
                                                gdlr-core-title-item-caption-top
                                                gdlr-core-item-pdlr" style={{
                                            paddingBottom: '50px'
                                        }} >
                                        <div class="gdlr-core-title-item-title-wrap">
                                            <h3 class="gdlr-core-title-item-title gdlr-core-skin-title" style={{
                                                fontSize: '40px',
                                                textTransform: 'none',
                                                textAlign: 'center'
                                            }}>
                                                Our Services
                                                <span class="gdlr-core-title-item-title-divider gdlr-core-skin-divider">
                                                </span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                        <div id="carouselExampleControls" class="carousel carousel-dark slide service1" data-bs-ride="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div class="card-wrapper container-sm d-flex justify-content-around">
                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/04.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>ONLINE BRAND REPUTATION</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '30px', color: 'white' }}>ONLINE BRAND REPUTATION</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Your brand reputation is what sets you apart from your competitors and it builds trust with your customers.
                                                        We understand the importance of a strong brand reputation, and we’re here to help you build and maintain it. Our team of experts
                                                        will work with you to identify your brand’s unique selling proposition and create a strategy to communicate it effectively to your
                                                        target audience.
                                                        Contact Us today to learn more about our comprehensive brand reputation services and how we can help you build a brand that stands
                                                        out in your industry.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/02.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>APP DEVELOPMENT</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '30px', color: 'white' }}>APP DEVELOPMENT</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Are you looking to take your online presence
                                                        to the next level? Our expert team of web/app developers can help you build a stunning, high-performance website/app
                                                        that will impress your customers, user friendly and drive results. So if you are ready to take your online presence to
                                                        the next level, Contact Us today to learn more about our website development services. Let us help you build a website
                                                        that drive traffic, boost engagement, and help you achieve your goals.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/03.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>INFLUENCER MARKETING</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '30px', color: 'white' }}>INFLUENCER MARKETING</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Influencers are the new actors and actresses
                                                        that people follow. In today’s social media driven world, influencer marketing has become a powerful tool for businesses to
                                                        connect with their target audience. At lagoSmart, we specialize in influencer marketing and can help youn identify and partner
                                                        with the right influencers to promote your brand across multiple platforms like Instagram, youtube, facebook, and Tiktok.
                                                        We can help you develop an influencer marketing strategy that is tailored to your specific goals

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="card-wrapper container-sm d-flex justify-content-around">

                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/06.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>E-COMMERCE</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '30px', color: 'white' }}>E-COMMERCE</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>We are your one-stop-creative space for building your online store where
                                                        you can transact.
                                                        We pride ourselves on providing a seamless online shopping experience that is both convenient and secure for your customers. We build
                                                        user-friendly platform that allows users to easily browse through product categories, add items to cart and checkout with ease. So
                                                        whether you are looking at building the latest fashion online store, electronics or home appliances, or a CSR e-commerce we’ve got you covered.


                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/05.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>SOCIAL MEDIA MARKETING</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '30px', color: 'white' }}>SOCIAL MEDIA MARKETING</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Social media has become an essential tool for businesses to
                                                        connect with their customers and build their brand. But managing social media can be time-consuming and overwhelming.

                                                        Our team of social media experts specializes in helping businesses like yours build and maintain a strong social media presence.
                                                        We use data-driven approach to identify the right platforms and content strategies to reach your target audience and achieve your business goals.

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/04.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>SOCIAL MEDIA MANAGEMENT</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '30px', color: 'white' }}>SOCIAL MEDIA MANAGEMENT</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Are you looking for ways to improve your social media presence and
                                                        drive more engagement with your target audience? Our professional social media marketing services can help. We use a variety of social media
                                                        platforms and advertising techniques to reach your target audience, which includes Facebook, Instagram, Linkedin, Twitter, Whatsapp and more.

                                                        We’re committed to helping you achieve your business goals and drive more engagement with your target audience. Let us help you achieve social
                                                        media success.

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="card-wrapper container-sm d-flex justify-content-around">
                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/07.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>EVENT & PRODUCT MANAGEMENT</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '30px', color: 'white' }}>EVENT & PRODUCT MANAGEMENT</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>We are dedicated to helping
                                                        businesses of all sizes and industries manage their events with ease. Our product management help businesses
                                                        bring new products to market with ease. We are committed to delivering exceptional service and results.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/08.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>WEBSITE DEVELOPMENT</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '30px', color: 'white' }}>WEBSITE DEVELOPMENT</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Are you looking to take your online
                                                        presence to the next level? Our expert team of web/app developers can help you build a stunning, high-performance
                                                        website/app that will impress your customers, user friendly and drive results. So if you are ready to take your
                                                        online presence to the next level, Contact Us today to learn more about our website development services.
                                                        Let us help you build a website that drive traffic, boost engagement, and help you achieve your goals.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/09.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>SEARCH ENGINE OPTIMIZATION</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '10px', color: 'white' }}>SEARCH ENGINE OPTIMIZATION</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Looking for ways to improve your website’s online visibility
                                                        and drive more traffic to your business? Look no further than our professional SEO services. Our team has SEO experts with vast
                                                        experience in the industry and uses a data-driven approach to develop customized SEO plans for each of our clients.
                                                        But SEO is not just about optimizing your website for search engines. It’s also about creating high-quality content that
                                                        resonates with your audience and builds trust. That’s why we also offer content marketing services as part of our SEO strategy.
                                                        Let us help you achieve your business goals through effective search engine optimization, Contact Us today to learn more

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>

                        <div id="carouselExampleControls" class="carousel carousel-dark slide mservice" data-bs-ride="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div class="card-wrapper eachcard">
                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/04.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>ONLINE BRAND REPUTATION</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '30px', color: 'white' }}>ONLINE BRAND REPUTATION</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Your brand reputation is what sets you apart from your competitors and it builds trust with your customers.
                                                        We understand the importance of a strong brand reputation, and we’re here to help you build and maintain it. Our team of experts
                                                        will work with you to identify your brand’s unique selling proposition and create a strategy to communicate it effectively to your
                                                        target audience.
                                                        Contact Us today to learn more about our comprehensive brand reputation services and how we can help you build a brand that stands
                                                        out in your industry.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/02.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>APP DEVELOPMENT</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '30px', color: 'white' }}>APP DEVELOPMENT</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Are you looking to take your online presence
                                                        to the next level? Our expert team of web/app developers can help you build a stunning, high-performance website/app
                                                        that will impress your customers, user friendly and drive results. So if you are ready to take your online presence to
                                                        the next level, Contact Us today to learn more about our website development services. Let us help you build a website
                                                        that drive traffic, boost engagement, and help you achieve your goals.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/03.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>INFLUENCER MARKETING</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '30px', color: 'white' }}>INFLUENCER MARKETING</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Influencers are the new actors and actresses
                                                        that people follow. In today’s social media driven world, influencer marketing has become a powerful tool for businesses to
                                                        connect with their target audience. At lagoSmart, we specialize in influencer marketing and can help youn identify and partner
                                                        with the right influencers to promote your brand across multiple platforms like Instagram, youtube, facebook, and Tiktok.
                                                        We can help you develop an influencer marketing strategy that is tailored to your specific goals

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/06.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>E-COMMERCE</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '30px', color: 'white' }}>E-COMMERCE</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>We are your one-stop-creative space for building your online store where
                                                        you can transact.
                                                        We pride ourselves on providing a seamless online shopping experience that is both convenient and secure for your customers. We build
                                                        user-friendly platform that allows users to easily browse through product categories, add items to cart and checkout with ease. So
                                                        whether you are looking at building the latest fashion online store, electronics or home appliances, or a CSR e-commerce we’ve got you covered.


                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/05.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>SOCIAL MEDIA MARKETING</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '30px', color: 'white' }}>SOCIAL MEDIA MARKETING</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Social media has become an essential tool for businesses to
                                                        connect with their customers and build their brand. But managing social media can be time-consuming and overwhelming.

                                                        Our team of social media experts specializes in helping businesses like yours build and maintain a strong social media presence.
                                                        We use data-driven approach to identify the right platforms and content strategies to reach your target audience and achieve your business goals.

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/04.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>SOCIAL MEDIA MANAGEMENT</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '30px', color: 'white' }}>SOCIAL MEDIA MANAGEMENT</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Are you looking for ways to improve your social media presence and
                                                        drive more engagement with your target audience? Our professional social media marketing services can help. We use a variety of social media
                                                        platforms and advertising techniques to reach your target audience, which includes Facebook, Instagram, Linkedin, Twitter, Whatsapp and more.

                                                        We’re committed to helping you achieve your business goals and drive more engagement with your target audience. Let us help you achieve social
                                                        media success.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/07.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>EVENT & PRODUCT MANAGEMENT</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '30px', color: 'white' }}>EVENT & PRODUCT MANAGEMENT</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>We are dedicated to helping
                                                        businesses of all sizes and industries manage their events with ease. Our product management help businesses
                                                        bring new products to market with ease. We are committed to delivering exceptional service and results.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/08.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>WEBSITE DEVELOPMENT</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '30px', color: 'white' }}>WEBSITE DEVELOPMENT</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Are you looking to take your online
                                                        presence to the next level? Our expert team of web/app developers can help you build a stunning, high-performance
                                                        website/app that will impress your customers, user friendly and drive results. So if you are ready to take your
                                                        online presence to the next level, Contact Us today to learn more about our website development services.
                                                        Let us help you build a website that drive traffic, boost engagement, and help you achieve your goals.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flip-card m-2">
                                            <div class="flip-card-inner">
                                                <div class="flip-card-front">
                                                    <img src="assets/static/images/new/09.jpg" alt="Avatar" style={{ width: '500px', height: '320px' }} />
                                                    <h5 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>SEARCH ENGINE OPTIMIZATION</h5>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h5 style={{ textAlign: 'center', marginTop: '10px', color: 'white' }}>SEARCH ENGINE OPTIMIZATION</h5>
                                                    <p style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>Looking for ways to improve your website’s online visibility
                                                        and drive more traffic to your business? Look no further than our professional SEO services. Our team has SEO experts with vast
                                                        experience in the industry and uses a data-driven approach to develop customized SEO plans for each of our clients.
                                                        But SEO is not just about optimizing your website for search engines. It’s also about creating high-quality content that
                                                        resonates with your audience and builds trust. That’s why we also offer content marketing services as part of our SEO strategy.
                                                        Let us help you achieve your business goals through effective search engine optimization, Contact Us today to learn more

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button> */}
                            </div>
                        </div>
                    </div>




                    {/* Blogs */}
                    <div class="gdlr-core-pbf-wrapper" style={{
                        padding: '50px 30px 30px 30px'
                    }} data-skin="Light Border">
                        <div class="gdlr-core-pbf-background-wrap" style={{
                            backgroundColor: '#D2DBDD'
                        }}></div>
                        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js" data-gdlr-animation="fadeInUp" data-gdlr-animation-duration="600ms"
                            data-gdlr-animation-offset="0.8">
                            <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container-custom" style={{
                                maxWidth: '1200px'
                            }} >
                                <div class="gdlr-core-pbf-element">
                                    <div class="gdlr-core-blog-item gdlr-core-item-pdb clearfix gdlr-core-style-blog-column-with-frame gdlr-core-item-pdlr">
                                        <div class="gdlr-core-block-item-title-wrap gdlr-core">
                                            <div class="gdlr-core-block-item-title-inner">
                                                <h3 class="gdlr-core-block-item-title" style={{
                                                    fontSize: '43px',
                                                    textTransform: 'none',
                                                    textAlign: 'center'
                                                }}>From Our Blog
                                                </h3>
                                            </div>
                                        </div>
                                        <div class="gdlr-core-flexslider flexslider gdlr-core-js-2" data-type="carousel" data-column="4"
                                            data-move="1" data-nav="both" data-nav-parent="gdlr-core-blog-item"
                                            data-disable-autoslide="1">

<section class="cards">
                            
                            <article class="card card--10">
                                <div class="card__info-hover">
                                    <svg class="card__like" viewBox="0 0 24 24">
                                        <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                                    </svg>
                                    <div class="card__clock-info">
                                        <svg class="card__clock" viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                        </svg><span class="card__time">5 min</span>
                                    </div>
    
                                </div>
                                <div class="card__img"></div>
                                <a href="/reasons-your-business-should-have-a-digital-marketing-strategy" class="card_link">
                                    <div class="card__img--hover"></div>
                                </a>
                                <div class="card__info">
                                    <span class="card__category">Audiences </span>
                                    <h4 class="card__title"><a href='/the-quality-of-a-good-brand'>THE QUALITIES OF A GOOD BRAND</a></h4>
                                    <span class="card__by">Branding your business is the process of marking your identity to your clients. Every small business has something to offer 
                                    which is unique. Branding builds trust because it creates consistency and businesses that are solely based online often don’t take the time to properly 
                                    brand their products and style..</span>
    
                                </div>
                                <div class="card__footer">
                                    <div class="user">
                                        <img src="assets/img/team/tunmise_blog.jpeg" alt="user__image" class="user__image" width="10%"/>
                                        <div class="user__info">
                                        <small>Sobande Oluwatunmise</small>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="card card--9">
                            <div class="card__info-hover">
                                <svg class="card__like"  viewBox="0 0 24 24">
                                <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,
                                6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,
                                3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,
                                15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                            </svg>
                                <div class="card__clock-info">
                                    <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,
                                    1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,
                                    4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                    </svg><span class="card__time">15 min</span>
                                </div>
                                
                            </div>
                            <div class="card__img"></div>
                            <a href="/five-pitfalls-in-social-media-marketing" class="card_link">
                                <div class="card__img--hover"></div>
                            </a>
                            <div class="card__info">
                                <span class="card__category">Audiences</span>
                                <h4 class="card__title"><a href='/five-pitfalls-in-social-media-marketing'>5 PITFALL IN SOCIAL MEDIA MARKETING</a></h4>
                                <span class="card__by">Social media marketing can be an effective way to promote your business, build brand awareness, and engage with your
                                 audience. However, there are several pitfalls that businesses can fall into if they don't have a solid strategy in place...</span>
                            </div>
                            <div class="card__footer">
                                    <div class="user">
                                        <img src="assets/img/team/dan_blog.jpeg" alt="user__image" class="user__image" width="10%"/>
                                        <div class="user__info">
                                        <small>Daniel Aikhomogbe</small>
                                        </div>
                                    </div>
                                </div>
                            </article>
    
                            <article class="card card--8">
                            <div class="card__info-hover">
                                <svg class="card__like"  viewBox="0 0 24 24">
                                <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,
                                5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,
                                3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,
                                21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                            </svg>
                                <div class="card__clock-info">
                                    <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,
                                    13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,
                                    0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                    </svg><span class="card__time">2 min</span>
                                </div>
                                
                            </div>
                            <div class="card__img"></div>
                            <a href="/ways-a-digital-marketing-agency-can-boost-your-business" class="card_link">
                                <div class="card__img--hover"></div>
                            </a>
                            <div class="card__info">
                                <span class="card__category">Social Trust </span>
                                <h4 class="card__title"><a href='/ways-a-digital-marketing-agency-can-boost-your-business'>HOW A DIGITAL MARKETING AGENCY CAN BOOST YOUR BUSINESS</a></h4>
                                <span class="card__by">Partnering with a digital marketing agency can be a game-changer for your business. From strategic planning and branding. A digital marketing agency is a team of 
                                experts who specialize in creating and executing digital marketing strategies tailored to the unique needs and goals of a business....</span>
                                
                            </div>
                            <div class="card__footer">
                                    <div class="user">
                                        <img src="assets/img/team/dan_blog.jpeg" alt="user__image" class="user__image" width="10%"/>
                                        <div class="user__info">
                                        <small>Daniel Aikhomogbe</small>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            
                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/*client says */}
                    {/* <div class="gdlr-core-pbf-wrapper" style={{ padding: '60px 60px 60px 60px'}} data-skin="Light Border">
                            <div style={{textAlign:'center'}}>
                                <div>
                                    <h3>Client's Testimonies</h3>
                                    <p>We have been doing this for years now, so we got the experience.</p>
                                </div>
                                <figure class="snip1533">
                                    <figcaption>
                                    <blockquote>
                                        <p>If you do the job badly enough, sometimes you don't get asked to do it again.</p>
                                    </blockquote>
                                    <h3></h3>
                                    </figcaption>
                                </figure>
                                <figure class="snip1533">
                                    <figcaption>
                                    <blockquote>
                                        <p>I'm killing time while I wait for life to shower me with meaning and happiness. and happiness.</p>
                                    </blockquote>
                                    <h3></h3>
                                    </figcaption>
                                </figure>
                                <figure class="snip1533">
                                    <figcaption>
                                    <blockquote>
                                        <p>The only skills I have the patience to learn are those that have no real application in life. </p>
                                    </blockquote>
                                    <h3></h3>
                                    </figcaption>
                                </figure>
                            </div>
                        </div> */}

                    {/* some of our clients */}
                    {/* <div class="gdlr-core-pbf-wrapper" style={{ padding: '' }} data-skin="Light Border">
                        <div class="gdlr-core-pbf-background-wrap" style={{ backgroundColor: '' }}></div>
                        <div style={{ textAlign: 'center' }}>
                            <h2>Our Partners</h2>
                            <p>Our clients, like our people are our most treasured assets. <br />We nurture every
                                client relationship with commitment, passion and integrity, which is the reason why most
                                of our clients treasure the relationship.</p>

                            <div class="container mt-5 lvclient">
                                <div class="partner col-12">
                                    <div class="partner-item">
                                        <a href="javascript:void(0)">
                                            <img src="assets/static/images/partner/p1.jpeg" alt="new" />
                                        </a>
                                    </div>
                                    <div class="partner-item">
                                        <a href="javascript:void(0)">
                                            <img src="assets/static/images/partner/p6.png" alt="new" />
                                        </a>
                                    </div>
                                    <div class="partner-item">
                                        <a href="javascript:void(0)">
                                            <img src="assets/static/images/partner/p3.png" alt="new" />
                                        </a>
                                    </div>
                                    <div class="partner-item">
                                        <a href="javascript:void(0)">
                                            <img src="assets/static/images/partner/p4.png" alt="new" />
                                        </a>
                                    </div>
                                </div>

                                <div class="partner mt-5">
                                    <div class="partner-item">
                                        <a href="javascript:void(0)">
                                            <img src="assets/static/images/partner/p5.png" alt="new" />
                                        </a>
                                    </div>
                                    <div class="partner-item">
                                        <a href="javascript:void(0)">
                                            <img src="assets/static/images/partner/p9.jpeg" style={{ width: '300px', height: '70px' }} alt="new" />
                                        </a>
                                    </div>
                                    <div class="partner-item">
                                        <a href="javascript:void(0)">
                                            <img src="assets/static/images/partner/p7.png" alt="new" />
                                        </a>
                                    </div>
                                    <div class="partner-item">
                                        <a href="javascript:void(0)">
                                            <img src="assets/static/images/partner/p8.jpg" alt="new" />
                                        </a>
                                    </div>
                                </div>


                            </div>

                            <div class="mt-5 mclient">
                                <div class="partner col-12">
                                    <div class="partner-item">
                                        <a href="javascript:void(0)">
                                            <img src="assets/static/images/partner/p1.jpeg" alt="new" />
                                        </a>
                                    </div>
                                    <div class="partner-item">
                                        <a href="javascript:void(0)">
                                            <img src="assets/static/images/partner/p6.png" alt="new" />
                                        </a>
                                    </div>
                                    <div class="partner-item">
                                        <a href="javascript:void(0)">
                                            <img src="assets/static/images/partner/p3.png" alt="new" />
                                        </a>
                                    </div>
                             
                                </div>

                                <div class="partner mt-5">
                                    <div class="partner-item">
                                        <a href="javascript:void(0)">
                                            <img src="assets/static/images/partner/p5.png" alt="new" />
                                        </a>
                                    </div>
                                    <div class="partner-item">
                                        <a href="javascript:void(0)">
                                            <img src="assets/static/images/partner/p8.jpg" alt="new" />
                                        </a>
                                    </div>
                                    <div class="partner-item">
                                        <a href="javascript:void(0)">
                                            <img src="assets/static/images/partner/p7.png" alt="new" />
                                        </a>
                                    </div>
                                 
                                </div>


                        

                            </div>
                        </div>

                    </div> */}


                </div>
            </div>

            <Footer />
        </div>
    )
}
export default Home;