import React from "react";
import Footer from "../partial/footer";
import NavBar from "../partial/navbar";
import '../pages/About.css'

function DigitalMarketing(){
    return(
        <div>
            <NavBar/>
            
        <div class="gdlr-core-pbf-wrapper" style={{
            padding: '90px 0px 90px 0p'
          }} data-skin="Dark">
            <div class="gdlr-core-pbf-background-wrap">
              <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js" style={{
                backgroundImage:"url('assets/wp-content/uploads/2022/01/about-us1.jpg')",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }} data-parallax-speed="0.8"></div>
            </div>
            <div class="gdlr-core-pbf-wrapper-content
                                gdlr-core-js">
              <div class="gdlr-core-pbf-wrapper-container
                                    clearfix gdlr-core-container">
                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-title-item
                                            gdlr-core-item-pdb clearfix
                                            gdlr-core-left-align
                                            gdlr-core-title-item-caption-bottom
                                            gdlr-core-item-pdlr">
                    <div class="gdlr-core-title-item-title-wrap">
                      <h1 class="gdlr-core-title-item-title gdlr-core-skin-title" style={{
                        fontSize: '55px',
                        fontWeight: '700',
                        color:'white'
                      }}>Digital Marketing
                      <span class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span></h1>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* IT Development */}

          <div class="fluid">
             <h4>IT Development</h4>
             <div class="carddm-container ">
                <div class="carddm">
                    <img src="assets/dig13.jpeg" alt="Image 1"/>
                    <div class="carddm-info">
                    <p>Website Development</p>
                    </div>
                </div>
                <div class="carddm">
                    <img src="assets/dig5.jpeg" alt="Image 2"/>
                    <div class="carddm-info">
                    <p>App Development</p>
                    </div>
                </div>
            </div>
        </div>

        {/* Marketing */}
         <div class="fluid">
             <h4>Marketing</h4>
          <div class="carddm-container ">
                <div class="carddm">
                    <img src="assets/dig12.jpeg" alt="Image 1"/>
                    <div class="carddm-info">
                    <p>SEO - Search Engine Opt..</p>
                    </div>
                </div>
                <div class="carddm">
                    <img src="assets/dig8.jpeg" alt="Image 2"/>
                    <div class="carddm-info">
                    <p>Online Advertisment.</p>
                    </div>
                </div>
                <div class="carddm">
                    <img src="assets/dig6.jpeg" alt="Image 3"/>
                    <div class="carddm-info">
                    <p>WhatsApp Marketing</p>
                    </div>
                </div>
                <div class="carddm">
                    <img src="assets/dig9.jpeg" alt="Image 4"/>
                    <div class="carddm-info">
                    <p>Events and PR</p>
                    </div>
                </div>
                </div>
                <div class="carddm-container">
                <div class="carddm">
                    <img src="assets/dig11.jpeg" alt="Image 1"/>
                    <div class="carddm-info">
                    <p>Online Reputation</p>
                    </div>
                </div>
                <div class="carddm">
                    <img src="assets/dig7.jpeg" alt="Image 1"/>
                    <div class="carddm-info">
                    <p>Brand Management</p>
                    </div>
                </div>
                <div class="carddm">
                    <img src="assets/dig10.jpeg" alt="Image 1"/>
                    <div class="carddm-info">
                    <p>Social Media Marketing</p>
                    </div>
                </div>
                </div>
            </div>

             {/* E-Commerce */}

          <div class="fluid">
             <h4>E-Commerce</h4>
             <div class="carddm-container ">
                <div class="carddm">
                    <img src="assets/dig3.jpeg" alt="Image 1"/>
                    <div class="carddm-info">
                    <p>E-Commerce</p>
                    </div>
                </div>
            </div>
        </div>


        {/* Motion Pictures */}

        <div class="fluid">
             <h4>Motion Pictures</h4>
             <div class="carddm-container ">
                <div class="carddm">
                    <img src="assets/dig2.jpeg" alt="Image 1"/>
                    <div class="carddm-info">
                    <p>VFX and Animation</p>
                    </div>
                </div>
                <div class="carddm">
                    <img src="assets/dig4.jpeg" alt="Image 2"/>
                    <div class="carddm-info">
                    <p>Video Creation</p>
                    </div>
                </div>
            </div>
        </div>

        {/* Talent Management */}

        <div class="fluid">
             <h4>Talent Management</h4>
             <div class="carddm-container ">
                <div class="carddm">
                    <img src="assets/dig1.jpeg" alt="Image 1"/>
                    <div class="carddm-info">
                    <p>Influencer Management</p>
                    </div>
                </div>
            </div>
        </div>

            <Footer/>

        </div>
    )
}

export default DigitalMarketing;