import React from'react';
import { BrowserRouter as Router,Route, Routes } from 'react-router-dom';
import About from './components/pages/About';
import Blogs from './components/pages/blogs';
import BoostBusiness from './components/pages/blogs/boostbusiness';
import Brandstandout from './components/pages/blogs/howtomakebrand';
import Instagramhashtag from './components/pages/blogs/howtouseinstagram';
import Blogjan27 from './components/pages/blogs/jan27';
import Pitfall from './components/pages/blogs/pitfall';
import QualityBrand from './components/pages/blogs/qualityofgoodbrand';
import Reason from './components/pages/blogs/reason-marketing';
import SocialEngage from './components/pages/blogs/social-engagement';
import SocialManagement from './components/pages/blogs/social-management';
import Social from './components/pages/blogs/social-marketing';
import Promotingbusiness from './components/pages/blogs/tipsforbusiness';
import Career from './components/pages/Career';
import Contact from './components/pages/Contact';
import Digital from './components/pages/Digital';
import DigitalMarketing from './components/pages/digitalmarketing';
import Ecommerce from './components/pages/E-commerce';
import Home from './components/pages/Home';
import OnlineRM from './components/pages/OnlineRM';
import Ticket from './components/pages/ticket';
import Vhrms from './components/pages/Vhrms';
import Vpay from './components/pages/Vpay';



function App() {

  return(
    <Router>
        <Routes>
          
          <Route path='/' element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/blogs' element={<Blogs/>}/>
          <Route path='/e-commerce' element={<Ecommerce/>}/>
          <Route path='/online-reputation-management' element={<OnlineRM/>}/>
          <Route path='/career' element={<Career/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/vpay' element={<Vpay/>}/>
          <Route path='/Vhrms' element={<Vhrms/>}/>
          <Route path='/digital-marketing' element={<Digital/>}/>
          <Route path='/digitalmarketing' element={<DigitalMarketing/>}/>
          <Route path='/ever-increasing-role-of-orm-in-digital-marketing' element={<Blogjan27/>}/>
          <Route path='/get-ticket' element={<Ticket/>}/>
          <Route path='/social-marketing' element={<Social/>}/>
          <Route path='/social-engagement' element={<SocialEngage/>}/>
          <Route path='/social-management' element={<SocialManagement/>}/>
          <Route path='/how-to-use-instagram-hashtag-filter-to-promote-your-brand' element={<Instagramhashtag/>}/>
          <Route path='/tips-forp-romoting-your-business-on-instagram-using-the-hashtag-filter' element={<Promotingbusiness/>}/>
          <Route path='/how-to-make-your-brand-standout-online' element={<Brandstandout/>}/>
          <Route path='/reason-your-business-should-have-a-digital-marketing-strategy' element={<Reason/>}/>
          <Route path='/the-quality-of-a-good-brand' element={<QualityBrand/>}/>
          <Route path='/five-pitfalls-in-social-media-marketing' element={<Pitfall/>}/>
          <Route path='/ways-a-digital-marketing-agency-can-boost-your-business' element={<BoostBusiness/>}/>
        </Routes>
      </Router>


  );
}

export default App;