import React from "react";
import NavBar from "../partial/navbar";
import Footer from "../partial/footer";
import './blogs.css'

function Blogs(){
    return(
        <div>
            <NavBar/>
            <div class="containr">
                <h3 class="blog-name">Blog Section</h3>
                <div class="general">
                <div class="blogs-group col-12 laptop">
                    <div className="blogs-all">
                    <section class="cards">
                            
                            <article class="card card--10">
                                <div class="card__info-hover">
                                    <svg class="card__like" viewBox="0 0 24 24">
                                        <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                                    </svg>
                                    <div class="card__clock-info">
                                        <svg class="card__clock" viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                        </svg><span class="card__time">5 min</span>
                                    </div>
    
                                </div>
                                <div class="card__img"></div>
                                <a href="/reasons-your-business-should-have-a-digital-marketing-strategy" class="card_link">
                                    <div class="card__img--hover"></div>
                                </a>
                                <div class="card__info">
                                    <span class="card__category">Audiences </span>
                                    <h4 class="card__title"><a href='/the-quality-of-a-good-brand'>THE QUALITIES OF A GOOD BRAND</a></h4>
                                    <span class="card__by">Branding your business is the process of marking your identity to your clients. Every small business has something to offer 
                                    which is unique. Branding builds trust because it creates consistency and businesses that are solely based online often don’t take the time to properly 
                                    brand their products and style..</span>
    
                                </div>
                                <div class="card__footer">
                                    <div class="user">
                                        <img src="assets/img/team/tunmise_blog.jpeg" alt="user__image" class="user__image" width="10%"/>
                                        <div class="user__info">
                                        <small>Sobande Oluwatunmise</small>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="card card--9">
                            <div class="card__info-hover">
                                <svg class="card__like"  viewBox="0 0 24 24">
                                <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,
                                6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,
                                3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,
                                15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                            </svg>
                                <div class="card__clock-info">
                                    <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,
                                    1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,
                                    4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                    </svg><span class="card__time">15 min</span>
                                </div>
                                
                            </div>
                            <div class="card__img"></div>
                            <a href="/five-pitfalls-in-social-media-marketing" class="card_link">
                                <div class="card__img--hover"></div>
                            </a>
                            <div class="card__info">
                                <span class="card__category">Audiences</span>
                                <h4 class="card__title"><a href='/five-pitfalls-in-social-media-marketing'>5 PITFALL IN SOCIAL MEDIA MARKETING</a></h4>
                                <span class="card__by">Social media marketing can be an effective way to promote your business, build brand awareness, and engage with your
                                 audience. However, there are several pitfalls that businesses can fall into if they don't have a solid strategy in place...</span>
                            </div>
                            <div class="card__footer">
                                    <div class="user">
                                        <img src="assets/img/team/dan_blog.jpeg" alt="user__image" class="user__image" width="10%"/>
                                        <div class="user__info">
                                        <small>Daniel Aikhomogbe</small>
                                        </div>
                                    </div>
                                </div>
                            </article>
    
                            <article class="card card--8">
                            <div class="card__info-hover">
                                <svg class="card__like"  viewBox="0 0 24 24">
                                <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,
                                5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,
                                3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,
                                21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                            </svg>
                                <div class="card__clock-info">
                                    <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,
                                    13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,
                                    0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                    </svg><span class="card__time">2 min</span>
                                </div>
                                
                            </div>
                            <div class="card__img"></div>
                            <a href="/ways-a-digital-marketing-agency-can-boost-your-business" class="card_link">
                                <div class="card__img--hover"></div>
                            </a>
                            <div class="card__info">
                                <span class="card__category">Social Trust </span>
                                <h4 class="card__title"><a href='/ways-a-digital-marketing-agency-can-boost-your-business'>HOW A DIGITAL MARKETING AGENCY CAN BOOST YOUR BUSINESS</a></h4>
                                <span class="card__by">Partnering with a digital marketing agency can be a game-changer for your business. From strategic planning and branding. A digital marketing agency is a team of 
                                experts who specialize in creating and executing digital marketing strategies tailored to the unique needs and goals of a business....</span>
                                
                            </div>
                            <div class="card__footer">
                                    <div class="user">
                                        <img src="assets/img/team/dan_blog.jpeg" alt="user__image" class="user__image" width="10%"/>
                                        <div class="user__info">
                                        <small>Daniel Aikhomogbe</small>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            
                            </section>
                        <section class="cards mt-5">

                            
                            
                        <article class="card card--7">
                            <div class="card__info-hover">
                                <svg class="card__like" viewBox="0 0 24 24">
                                    <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                                </svg>
                                <div class="card__clock-info">
                                    <svg class="card__clock" viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                    </svg><span class="card__time">5 min</span>
                                </div>

                            </div>
                            <div class="card__img"></div>
                            <a href="/reason-your-business-should-have-a-digital-marketing-strategy" class="card_link">
                                <div class="card__img--hover"></div>
                            </a>
                            <div class="card__info">
                                <span class="card__category">Audiences </span>
                                <h4 class="card__title"><a href='/reason-your-business-should-have-a-digital-marketing-strategy'>3 Reasons Your Business Should Have a Digital Marketing Strategy</a></h4>
                                <span class="card__by">Do you have a digital marketing strategy? If not, you could be pushing a large number of your target
                                    customers from reaching your brand, products and services....</span>

                            </div>
                            <div class="card__footer">
                                <div class="user">
                                    <img src="assets/img/team/tunmise_blog.jpeg" alt="user__image" class="user__image" width="10%"/>
                                    <div class="user__info">
                                    <small>Sobande Oluwatunmise</small>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <article class="card card--4">
                        <div class="card__info-hover">
                            <svg class="card__like"  viewBox="0 0 24 24">
                            <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,
                            6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,
                            3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,
                            15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                        </svg>
                            <div class="card__clock-info">
                                <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,
                                1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,
                                4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                </svg><span class="card__time">15 min</span>
                            </div>
                            
                        </div>
                        <div class="card__img"></div>
                        <a href="how-to-use-instagram-hashtag-filter-to-promote-your-brand" class="card_link">
                            <div class="card__img--hover"></div>
                        </a>
                        <div class="card__info">
                            <span class="card__category">Audiences</span>
                            <h4 class="card__title"><a href='/how-to-use-instagram-hashtag-filter-to-promote-your-brand'>How to Use Instagram’s Hashtag Filter to Promote Your Brand</a></h4>
                            <span class="card__by">       Instagram hashtags are a powerful tool to promote your brand, increase your reach, 
                            and engage with your audience. Using Instagram’s hashtag filter can help you find the right hashtags for your content, 
                            making it more discoverable to people who are interested in your brand.  ...</span>
                        </div>
                        <div class="card__footer">
                                    <div class="user">
                                        <img src="assets/img/team/dan_blog.jpeg" alt="user__image" class="user__image" width="10%"/>
                                        <div class="user__info">
                                        <small>Daniel Aikhomogbe</small>
                                        </div>
                                    </div>
                                </div>
                        </article>

                        <article class="card card--5">
                        <div class="card__info-hover">
                            <svg class="card__like"  viewBox="0 0 24 24">
                            <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,
                            5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,
                            3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,
                            21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                        </svg>
                            <div class="card__clock-info">
                                <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,
                                13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,
                                0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                </svg><span class="card__time">2 min</span>
                            </div>
                            
                        </div>
                        <div class="card__img"></div>
                        <a href="/tips-forp-romoting-your-business-on-instagram-using-the-hashtag-filter" class="card_link">
                            <div class="card__img--hover"></div>
                        </a>
                        <div class="card__info">
                            <span class="card__category">Social Trust </span>
                            <h4 class="card__title"><a href='/tips-forp-romoting-your-business-on-instagram-using-the-hashtag-filter'>Tips for Promoting Your Business on Instagram Using the Hashtag Filter</a></h4>
                            <span class="card__by">Create a unique branded hashtag for your business and encourage your 
                            followers to use it when they post about your products or services. This will help you increase 
                            brand awareness and user-generated content ...</span>
                            
                        </div>
                        <div class="card__footer">
                                    <div class="user">
                                        <img src="assets/img/team/tunmise_blog.jpeg" alt="user__image" class="user__image" width="10%"/>
                                        <div class="user__info">
                                        <small>Sobande Oluwatunmise</small>
                                        </div>
                                    </div>
                                </div>
                        </article>
                        
                        </section>
                        <section class="cards mt-5">
                             
                        <article class="card card--6">
                        <div class="card__info-hover">
                            <svg class="card__like"  viewBox="0 0 24 24">
                            <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,
                            5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,
                            14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,
                            15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                        </svg>
                            <div class="card__clock-info">
                                <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,
                                1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,
                                4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                </svg><span class="card__time">6 min</span>
                            </div>
                            
                        </div>
                        <div class="card__img"></div>
                        <a href="/how-to-make-your-brand-standout-online" class="card_link">
                            <div class="card__img--hover"></div>
                        </a>
                        <div class="card__info">
                            <span class="card__category">Media</span>
                            <h4 class="card__title"><a href='/how-to-make-your-brand-standout-online'>How to make your brand standout online</a> </h4>
                            <span class="card__by">As a business owner, you will need to think about your online assets. 
                            Online assets are things you own, like websites, apps, and social media platforms that you use 
                            to communicate with your customers ...</span>
                        </div>
                        <div class="card__footer">
                                    <div class="user">
                                        <img src="assets/img/team/dan_blog.jpeg" alt="user__image" class="user__image" width="10%"/>
                                        <div class="user__info">
                                        <small>Daniel Aikhomogbe</small>
                                        </div>
                                    </div>
                                </div>
                        </article>
                        <article class="card card--1">
                        <div class="card__info-hover">
                            <svg class="card__like"  viewBox="0 0 24 24">
                            <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                        </svg>
                            <div class="card__clock-info">
                                <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                </svg><span class="card__time">15 min</span>
                            </div>
                            
                        </div>
                        <div class="card__img"></div>
                        <a href="/social-management" class="card_link">
                            <div class="card__img--hover"></div>
                        </a>
                        <div class="card__info">
                            <span class="card__category">Audiences</span>
                            <h4 class="card__title"><a href='/social-management'>Social Media Management</a></h4>
                            <span class="card__by">Businesses increase their reach to specific audiences to 
                            promote their brands and increase sales by using social media platforms strategically. 
                            Social media is used by more individuals to make purchases ...</span>
                        </div>
                        <div class="card__footer">
                                    <div class="user">
                                        <img src="assets/img/team/dan_blog.jpeg" alt="user__image" class="user__image" width="10%"/>
                                        <div class="user__info">
                                        <small>Daniel Aikhomogbe</small>
                                        </div>
                                    </div>
                                </div>
                        </article>

                        <article class="card card--2">
                        <div class="card__info-hover">
                            <svg class="card__like"  viewBox="0 0 24 24">
                            <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                        </svg>
                            <div class="card__clock-info">
                                <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                </svg><span class="card__time">5 min</span>
                            </div>
                            
                        </div>
                        <div class="card__img"></div>
                        <a href="/social-engagement" class="card_link">
                            <div class="card__img--hover"></div>
                        </a>
                        <div class="card__info">
                            <span class="card__category">Social Trust </span>
                            <h4 class="card__title"><a href='/social-engagement'>Social Media Engagement</a></h4>
                            <span class="card__by">Over the years, with the advent and improvement of technology, 
                            Social media has grown to be the heart of the consumer-Brand business relationship. 
                            Marketers are aware that social media is one of the best platform for connecting ...</span>
                            
                        </div>
                        <div class="card__footer">
                                    <div class="user">
                                        <img src="assets/img/team/tunmise_blog.jpeg" alt="user__image" class="user__image" width="10%"/>
                                        <div class="user__info">
                                        <small>Sobande Oluwatunmise</small>
                                        </div>
                                    </div>
                                </div>
                        </article>
                        
                        </section>
                        <section class="cards mt-5">
                             
                        <article class="card card--3">
                        <div class="card__info-hover">
                            <svg class="card__like"  viewBox="0 0 24 24">
                            <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                        </svg>
                            <div class="card__clock-info">
                                <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                </svg><span class="card__time">6 min</span>
                            </div>
                            
                        </div>
                        <div class="card__img"></div>
                        <a href="/social-marketing" class="card_link">
                            <div class="card__img--hover"></div>
                        </a>
                        <div class="card__info">
                            <span class="card__category">Media</span>
                            <h4 class="card__title"><a href='/social-marketing'>Social Media Marketing</a> </h4>
                            <span class="card__by">Social media has become an integral part of our daily lives, 
                            and it's no surprise that it has also become a critical component of any successful marketing strategy. 
                            In today's digital age, businesses cannot afford to ignore the power of social media ...</span>
                        </div>
                        <div class="card__footer">
                                    <div class="user">
                                        <img src="assets/img/team/tunmise_blog.jpeg" alt="user__image" class="user__image" width="10%"/>
                                        <div class="user__info">
                                        <small>Sobande Oluwatunmise</small>
                                        </div>
                                    </div>
                                </div>
                        </article>
                        </section>
                        
                    
                    </div>
                    <div className="filter-blogs col-3">
                        <div class="all-filter-backgroud">
                        <div className="all-filter">
                        <form>
                            <input type="search" name="" id="" placeholder="Search..." class="filter-search"/>
                        </form>
                        <h5>Recent Posts</h5>
                        <div>
                            <a href="/the-quality-of-a-good-brand'"><p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
                                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
                                </svg>THE QUALITIES OF A GOOD BRAND</p></a>
                        </div>
                        <div>
                            <a href="/five-pitfalls-in-social-media-marketing"><p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
                                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
                                </svg>5 PITFALL IN SOCIAL MEDIA MARKETING</p></a>
                        </div>
                        <div>
                            <a href="/ways-a-digital-marketing-agency-can-boost-your-business"><p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
                                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
                                </svg>HOW A DIGITAL MARKETING AGENCY CAN BOOST YOUR BUSINESS</p></a>
                        </div>
                        <div>
                            <a href="/reason-your-business-should-have-a-digital-marketing-strategy"><p class="line"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
                                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
                                </svg>3 Reason your business should have a digital marketing strategy</p></a>
                                <p ></p>
                        </div>
                        <div>
                            <a href="tips-forp-romoting-your-business-on-instagram-using-the-hashtag-filter"><p class="line"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
                                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
                                </svg>Tips for Promoting Your Business on Instagram Using the Hashtag Filter</p></a>
                        </div>
                        <div>
                        <a href="how-to-use-instagram-hashtag-filter-to-promote-your-brand"><p class="line"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
                                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
                                </svg>How to Use Instagram’s Hashtag Filter to Promote Your Brand</p></a>
                        </div>
                        <div>
                            <a href="how-to-make-your-brand-standout-online"><p class="line"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
                                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
                                </svg>How to make your brand standout online</p></a>
                        </div>
                        <br />
                        </div>
                        </div>
                        <div class="connect">
                            <h5>Connect With Us</h5>
                            <div class="allicons">
                               <a href="https://www.facebook.com/search/top?q=lagosmart" class="iconback"><i class="fa fa-facebook"></i></a>
                               <a href="https://twitter.com/lagoSmartng" class="iconback"><i class="fa fa-twitter"></i></a>
                               <a href="https://www.instagram.com/lagosmartng/" class="iconback"><i class="fa fa-instagram"></i></a>
                               <a href="https://www.linkedin.com/company/lagosmart-digital-marketing-agency/mycompany/?viewAsMember=true" class="iconback"><i class="fa fa-linkedin"></i></a>
                            </div>
                        </div>
                    </div>
                    
                </div>
                </div>


                {/* mobilr view */}
                <div class="blogs-group mobile">
                    <div className="blogs-all">
                    <section class="cards">
                            
                            <article class="card card--10">
                                <div class="card__info-hover">
                                    <svg class="card__like" viewBox="0 0 24 24">
                                        <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                                    </svg>
                                    <div class="card__clock-info">
                                        <svg class="card__clock" viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                        </svg><span class="card__time">5 min</span>
                                    </div>
    
                                </div>
                                <div class="card__img"></div>
                                <a href="/reasons-your-business-should-have-a-digital-marketing-strategy" class="card_link">
                                    <div class="card__img--hover"></div>
                                </a>
                                <div class="card__info">
                                    <span class="card__category">Audiences </span>
                                    <h4 class="card__title"><a href='/the-quality-of-a-good-brand'>THE QUALITIES OF A GOOD BRAND</a></h4>
                                    <span class="card__by">Branding your business is the process of marking your identity to your clients. Every small business has something to offer 
                                    which is unique. Branding builds trust because it creates consistency and businesses that are solely based online often don’t take the time to properly 
                                    brand their products and style..</span>
    
                                </div>
                                <div class="card__footer">
                                    <div class="user">
                                        <img src="assets/img/team/tunmise_blog.jpeg" alt="user__image" class="user__image" width="10%"/>
                                        <div class="user__info">
                                        <small>Sobande Oluwatunmise</small>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="card card--9">
                            <div class="card__info-hover">
                                <svg class="card__like"  viewBox="0 0 24 24">
                                <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,
                                6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,
                                3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,
                                15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                            </svg>
                                <div class="card__clock-info">
                                    <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,
                                    1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,
                                    4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                    </svg><span class="card__time">15 min</span>
                                </div>
                                
                            </div>
                            <div class="card__img"></div>
                            <a href="/five-pitfalls-in-social-media-marketing" class="card_link">
                                <div class="card__img--hover"></div>
                            </a>
                            <div class="card__info">
                                <span class="card__category">Audiences</span>
                                <h4 class="card__title"><a href='/five-pitfalls-in-social-media-marketing'>5 PITFALL IN SOCIAL MEDIA MARKETING</a></h4>
                                <span class="card__by">Social media marketing can be an effective way to promote your business, build brand awareness, and engage with your
                                 audience. However, there are several pitfalls that businesses can fall into if they don't have a solid strategy in place...</span>
                            </div>
                            <div class="card__footer">
                                    <div class="user">
                                        <img src="assets/img/team/dan_blog.jpeg" alt="user__image" class="user__image" width="10%"/>
                                        <div class="user__info">
                                        <small>Daniel Aikhomogbe</small>
                                        </div>
                                    </div>
                                </div>
                            </article>
    
                            <article class="card card--8">
                            <div class="card__info-hover">
                                <svg class="card__like"  viewBox="0 0 24 24">
                                <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,
                                5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,
                                3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,
                                21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                            </svg>
                                <div class="card__clock-info">
                                    <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,
                                    13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,
                                    0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                    </svg><span class="card__time">2 min</span>
                                </div>
                                
                            </div>
                            <div class="card__img"></div>
                            <a href="/ways-a-digital-marketing-agency-can-boost-your-business" class="card_link">
                                <div class="card__img--hover"></div>
                            </a>
                            <div class="card__info">
                                <span class="card__category">Social Trust </span>
                                <h4 class="card__title"><a href='/ways-a-digital-marketing-agency-can-boost-your-business'>HOW A DIGITAL MARKETING AGENCY CAN BOOST YOUR BUSINESS</a></h4>
                                <span class="card__by">Partnering with a digital marketing agency can be a game-changer for your business. From strategic planning and branding. A digital marketing agency is a team of 
                                experts who specialize in creating and executing digital marketing strategies tailored to the unique needs and goals of a business....</span>
                                
                            </div>
                            <div class="card__footer">
                                    <div class="user">
                                        <img src="assets/img/team/dan_blog.jpeg" alt="user__image" class="user__image" width="10%"/>
                                        <div class="user__info">
                                        <small>Daniel Aikhomogbe</small>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            
                            </section>
                        <section class="cards">
                        <article class="card card--4">
                        <div class="card__info-hover">
                            <svg class="card__like"  viewBox="0 0 24 24">
                            <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,
                            6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,
                            3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,
                            15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                        </svg>
                            <div class="card__clock-info">
                                <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,
                                1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,
                                4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                </svg><span class="card__time">15 min</span>
                            </div>
                            
                        </div>
                        <div class="card__img"></div>
                        <a href="how-to-use-instagram-hashtag-filter-to-promote-your-brand" class="card_link">
                            <div class="card__img--hover"></div>
                        </a>
                        <div class="card__info">
                            <span class="card__category">Audiences</span>
                            <h4 class="card__title"><a href='/how-to-use-instagram-hashtag-filter-to-promote-your-brand'>How to Use Instagram’s Hashtag Filter to Promote Your Brand</a></h4>
                            <span class="card__by">       Instagram hashtags are a powerful tool to promote your brand, increase your reach, 
                            and engage with your audience. Using Instagram’s hashtag filter can help you find the right hashtags for your content, 
                            making it more discoverable to people who are interested in your brand.  ...</span>
                        </div>
                        </article>

                        <article class="card card--5">
                        <div class="card__info-hover">
                            <svg class="card__like"  viewBox="0 0 24 24">
                            <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,
                            5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,
                            3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,
                            21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                        </svg>
                            <div class="card__clock-info">
                                <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,
                                13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,
                                0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                </svg><span class="card__time">2 min</span>
                            </div>
                            
                        </div>
                        <div class="card__img"></div>
                        <a href="/tips-forp-romoting-your-business-on-instagram-using-the-hashtag-filter" class="card_link">
                            <div class="card__img--hover"></div>
                        </a>
                        <div class="card__info">
                            <span class="card__category">Social Trust </span>
                            <h4 class="card__title"><a href='/tips-forp-romoting-your-business-on-instagram-using-the-hashtag-filter'>Tips for Promoting Your Business on Instagram Using the Hashtag Filter</a></h4>
                            <span class="card__by">Create a unique branded hashtag for your business and encourage your 
                            followers to use it when they post about your products or services. This will help you increase 
                            brand awareness and user-generated content ...</span>
                            
                        </div>
                        </article> 
                        <article class="card card--6">
                        <div class="card__info-hover">
                            <svg class="card__like"  viewBox="0 0 24 24">
                            <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,
                            5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,
                            14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,
                            15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                        </svg>
                            <div class="card__clock-info">
                                <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,
                                1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,
                                4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                </svg><span class="card__time">6 min</span>
                            </div>
                            
                        </div>
                        <div class="card__img"></div>
                        <a href="/how-to-make-your-brand-standout-online" class="card_link">
                            <div class="card__img--hover"></div>
                        </a>
                        <div class="card__info">
                            <span class="card__category">Media</span>
                            <h4 class="card__title"><a href='/how-to-make-your-brand-standout-online'>How to make your brand standout online</a> </h4>
                            <span class="card__by">As a business owner, you will need to think about your online assets. 
                            Online assets are things you own, like websites, apps, and social media platforms that you use 
                            to communicate with your customers ...</span>
                        </div>
                        </article>
                        
                        </section>
                        <section class="cards mt-5">
                        <article class="card card--1">
                        <div class="card__info-hover">
                            <svg class="card__like"  viewBox="0 0 24 24">
                            <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                        </svg>
                            <div class="card__clock-info">
                                <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                </svg><span class="card__time">15 min</span>
                            </div>
                            
                        </div>
                        <div class="card__img"></div>
                        <a href="/social-management" class="card_link">
                            <div class="card__img--hover"></div>
                        </a>
                        <div class="card__info">
                            <span class="card__category">Audiences</span>
                            <h4 class="card__title"><a href='/social-management'>Social Media Management</a></h4>
                            <span class="card__by">Businesses increase their reach to specific audiences to 
                            promote their brands and increase sales by using social media platforms strategically. 
                            Social media is used by more individuals to make purchases ...</span>
                        </div>
                        </article>

                        <article class="card card--2">
                        <div class="card__info-hover">
                            <svg class="card__like"  viewBox="0 0 24 24">
                            <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                        </svg>
                            <div class="card__clock-info">
                                <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                </svg><span class="card__time">5 min</span>
                            </div>
                            
                        </div>
                        <div class="card__img"></div>
                        <a href="/social-engagement" class="card_link">
                            <div class="card__img--hover"></div>
                        </a>
                        <div class="card__info">
                            <span class="card__category">Social Trust </span>
                            <h4 class="card__title"><a href='/social-engagement'>Social Media Engagement</a></h4>
                            <span class="card__by">Over the years, with the advent and improvement of technology, 
                            Social media has grown to be the heart of the consumer-Brand business relationship. 
                            Marketers are aware that social media is one of the best platform for connecting ...</span>
                            
                        </div>
                        </article> 
                        <article class="card card--3">
                        <div class="card__info-hover">
                            <svg class="card__like"  viewBox="0 0 24 24">
                            <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
                        </svg>
                            <div class="card__clock-info">
                                <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                                </svg><span class="card__time">6 min</span>
                            </div>
                            
                        </div>
                        <div class="card__img"></div>
                        <a href="/social-marketing" class="card_link">
                            <div class="card__img--hover"></div>
                        </a>
                        <div class="card__info">
                            <span class="card__category">Media</span>
                            <h4 class="card__title"><a href='/social-marketing'>Social Media Marketing</a> </h4>
                            <span class="card__by">Social media has become an integral part of our daily lives, 
                            and it's no surprise that it has also become a critical component of any successful marketing strategy. 
                            In today's digital age, businesses cannot afford to ignore the power of social media ...</span>
                        </div>
                        </article>
                        
                        </section>
                    
                    </div>
                    <div className="filter-blogs">
                        <div className="all-filter">
                        {/* <form>
                            <input type="search" name="" id="" placeholder="search..." class="filter-search"/>
                        </form> */}
                        <h5>Recent Posts</h5>
                        <div>
                            <a href="/the-quality-of-a-good-brand'"><p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
                                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
                                </svg>THE QUALITIES OF A GOOD BRAND</p></a>
                        </div>
                        <div>
                            <a href="/five-pitfalls-in-social-media-marketing"><p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
                                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
                                </svg>5 PITFALL IN SOCIAL MEDIA MARKETING</p></a>
                        </div>
                        <div>
                            <a href="/ways-a-digital-marketing-agency-can-boost-your-business"><p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
                                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
                                </svg>HOW A DIGITAL MARKETING AGENCY CAN BOOST YOUR BUSINESS</p></a>
                        </div>
                        <div>
                            <a href="tips-forp-romoting-your-business-on-instagram-using-the-hashtag-filter"><p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
                                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
                                </svg>Tips for Promoting Your Business on Instagram Using the Hashtag Filter</p></a>
                        </div>
                        <div>
                        <a href="how-to-use-instagram-hashtag-filter-to-promote-your-brand"><p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
                                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
                                </svg>How to Use Instagram’s Hashtag Filter to Promote Your Brand</p></a>
                        </div>
                        <div>
                            <a href="how-to-make-your-brand-standout-online"><p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
                                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
                                </svg>How to make your brand standout online</p></a>
                        </div>
                        
                        </div>
                    </div>
                    
                </div>
            </div>
            <Footer/>
        </div>
    )
}
export default Blogs;