import React from 'react'

function Footer() {
  return (
      <div>
          
    <div className="home page-template-default page page-id-185
    gdlr-core-body infinite-body infinite-body-front infinite-full
    infinite-with-sticky-navigation infinite-blockquote-style-1
    gdlr-core-link-to-lightbox" data-home-url="https://lagosmart.com/">
    <div class="infinite-body-outer-wrapper">
    <div class="infinite-body-wrapper clearfix infinite-with-frame">
       
    
      <footer>
                <div class="infinite-footer-wrapper
                            infinite-with-column-divider">
                    <div class="infinite-footer-container
                                infinite-container clearfix">
                        <div class="infinite-footer-column
                                    infinite-item-pdlr infinite-column-20">
                            <div id="block-5" class="widget widget_block
                                        infinite-widget">
                                <p><img decoding="async" src="assets/wp-content/uploads/2022/07/LagoSmart-Logo.png"
                                        class="foot-logo" alt=""/></p>
                                <p>
                                    lagoSmart Is A Business Solutions
                                    Company Providing Web Based
                                    Services. lagosmart Is Supported By
                                    Industry Experts And Having Its
                                    Expertise In Ecommerce Solutions,
                                    Server Management, Web Based
                                    Solution And Services, Digital
                                    Marketing Services – SEO, SEM, SMO,
                                    Social Media Marketing, Brand
                                    Building Etc.
                                </p>
                            </div>
                        </div>
                        <div class="infinite-footer-column
                                    infinite-item-pdlr infinite-column-20">
                            <div id="nav_menu-2" class="widget
                                        widget_nav_menu infinite-widget">
                                <h3 class="infinite-widget-title"><span class="infinite-widget-head-text">QUICK
                                        LINKS</span><span class="infinite-widget-head-divider"></span></h3><span
                                    class="clear"></span>
                                <div class="menu-footer-navigation-container">
                                    <ul id="menu-footer-navigation" class="menu">
                                        <li class="menu-item
                                                    menu-item-type-post_type
                                                    menu-item-object-page
                                                    menu-item-home
                                                    current-menu-item page_item
                                                    page-item-185
                                                    current_page_item
                                                    menu-item-290"><a href="/" aria-current="page">Home</a>
                                        </li>
                                        <li class="menu-item
                                                    menu-item-type-post_type
                                                    menu-item-object-page
                                                    menu-item-291"><a href="/about">About
                                                Us</a></li>
                                        <li class="menu-item
                                                    menu-item-type-post_type
                                                    menu-item-object-page
                                                    menu-item-294"><a href="/blogs">Blogs</a>
                                        </li>
                                        <li class="menu-item
                                                    menu-item-type-post_type
                                                    menu-item-object-page
                                                    menu-item-295"><a href="/Vhrms">VHRMS</a></li>
                                        <li class="menu-item
                                                    menu-item-type-post_type
                                                    menu-item-object-page
                                                    menu-item-296"><a href="/Vpay">VPay</a></li>
                                        <li class="menu-item
                                                    menu-item-type-post_type
                                                    menu-item-object-page
                                                    menu-item-293"><a href="/contact">Contact
                                                Us</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="infinite-footer-column
                                    infinite-item-pdlr infinite-column-20">
                            <div id="gdlr-core-newsletter-widget-2" class="widget
                                        widget_gdlr-core-newsletter-widget
                                        infinite-widget">
                                <h3 class="infinite-widget-title"><span class="infinite-widget-head-text">Subscribe
                                        Us</span><span class="infinite-widget-head-divider"></span></h3><span
                                    class="clear"></span>
                                <div class="gdlr-core-with-fa-send-o-button
                                            tnp
                                            tnp-subscription gdlr-core-style-2">
                                    <form method="post" action="https://lagosmart.com/?na=s" onsubmit="return
                                                newsletter_check(this)">

                                        <input type="hidden" name="nlang" value=""/>
                                        <div class="tnp-field
                                                    tnp-field-email">
                                            <input class="tnp-email" type="email" name="ne" placeholder="Enter
                                                        Your Email Address" required/>
                                        </div>
                                        <div class="tnp-field
                                                    tnp-field-button" style={{
                                                        color: '#fff'
                                                    }}><input class="tnp-submit"
                                                type="submit" value="Subscribe" style={{
                                                    backgroundColor:'#1b56b5'
                                                }}/></div>
                                    </form>
                                </div>
                            </div>
                            <div id="custom_html-2" class="widget_text widget
                                        widget_custom_html infinite-widget">
                                <div class="textwidget
                                            custom-html-widget">
                                    <div class="address-foot">
                                        <ul>
                                            <li><i class="fa
                                                            fa-map-marker"></i>1 Morrison Crescent, Off Kudirat Abiola way, Oregun, Ikeja, Lagos Nigeria.
                                            </li>
                                            <li><i class="fa
                                                            fa-envelope"></i>
                                                <a href="mailto:digital@lagosmart.net">digital@lagoSmart.net</a>
                                            </li>
                                            <li><i class="fa fa-mobile"></i><a href="tel:+2348157353535">+234
                                                    815 735 3535</a>
                                            </li>
                                            <li><i class="fa
                                                            fa-whatsapp"></i> <a href="https://wa.me/+2348157353535"
                                                    class="bt-whatsapp">+234
                                                    815
                                                    735 3535</a></li>
                                        </ul>
                                    </div>
                                    <ul class="social-links">
                                        <li><a href="https://www.facebook.com/search/top?q=lagosmart"><i
                                                    class="social_facebook"></i></a></li>
                                        <li><a href="https://twitter.com/lagoSmartng"><i class="social_twitter"></i></a></li>
                                        <li><a href="https://www.instagram.com/lagosmartng/"><i class="social_instagram"></i></a></li>
                                        <li><a href="https://www.linkedin.com/company/lagosmart-digital-marketing-agency/mycompany/?viewAsMember=true"><i class="social_linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="infinite-copyright-wrapper">
                    <div class="infinite-copyright-container
                                infinite-container">
                        <div class="infinite-copyright-text
                                    infinite-item-pdlr">Copyright © 2023
                             VIRDI NIGERIA LIMITED. All rights
                            reserved.</div>
                    </div>
                </div>
            </footer>
            
            </div>
            </div>
            </div>
            </div>
   
  )
}

export default Footer;