import React from "react";
import Footer from "../../partial/footer";
import NavBar from "../../partial/navbar";
import './social-engagement.css'

function QualityBrand(){
    return(
        <div>
            <NavBar/>
            <div class="infinite-page-wrapper" id="infinite-page-wrapper">
           <div class="infinite-blog-title-wrap  infinite-style-small">
                    <div class="infinite-header-transparent-substitute"></div>
                    <div class="infinite-blog-title-top-overlay"></div>
                    <div class="infinite-blog-title-overlay"></div>
                    <div class="infinite-blog-title-bottom-overlay"></div>
                    <div class="infinite-blog-title-container infinite-container">
                        <div class="infinite-blog-title-content infinite-item-pdlr">
                            <header class="infinite-single-article-head infinite-single-blog-title-style-1 clearfix">
                                <div class="infinite-single-article-date-wrapper post-date updated">
                                    <div class="infinite-single-article-date-day">24</div>
                                    <div class="infinite-single-article-date-month">May</div>
                                </div>
                                <div class="infinite-single-article-head-right">
                                    <h1 class="infinite-single-article-title">THE QUALITIES OF A GOOD BRAND</h1>
                                    <div class="infinite-blog-info-wrapper">
                                        <div
                                            class="infinite-blog-info infinite-blog-info-font infinite-blog-info-author vcard author post-author ">
                                            <span class="infinite-head"><i class="icon_documents_alt"></i></span><span
                                                class="fn"><a href="" title="Posts by admin"
                                                    rel="author">Sobande Oluwatunmise</a></span></div>
                                        <div
                                            class="infinite-blog-info infinite-blog-info-font infinite-blog-info-category ">
                                            <span class="infinite-head"><i class="icon_folder-alt"></i></span><a
                                                href="/digital-marketing" rel="tag">Digital
                                                Media Services</a></div>
                                        <div
                                            class="infinite-blog-info infinite-blog-info-font infinite-blog-info-comment-number ">
                                            <span class="infinite-head"><i class="icon_comment_alt"></i></span>0 </div>
                                    </div>
                                </div>
                            </header>
                        </div>
                    </div>
                </div>
                </div>
            <div class="container">
                
                <p> Branding your business is the process of marking your identity to your clients. Every small business 
                    has something to offer which is unique. Branding builds trust because it creates consistency and businesses 
                    that are solely based online often don’t take the time to properly brand their products and style. Having a 
                    strong visual identity is an important marketing tool and it all begins with branding yourself. A good brand 
                    has a clear focus, knows their target audience, has a defined mission, knows their competition and USP, can 
                    identify their key values, tell their story and many more
                </p>

                <h3>Good branding should have all of these qualities</h3>
                <h4>A good design</h4>
                <p> There is a sea of competition out there and if you want to get noticed from the crowd, you are going to need a 
                    killer design that is also unique and can stand on its own through all medias – social, print and online. Your 
                    brand helps you stand out from the competition by expressing your character
                </p>
                <h4>A lot of personality</h4>
                <p> Your brand must connect with the right target to be effective. Essentially, your brand is your business’ personality. 
                    Building a unique and strong brand for your business allows you to connect with your targeted audience and build emotions 
                    that are memorable and powerful. People make decisions based on emotions and a good brand sends the right message and gains 
                    your business or service trust and recognition
                </p>
                <h4>A plan of action</h4>
                <p> When a potential client looks through your website and marketing materials they should gain a strong sense of trust about 
                    your business. Planning a strong and cohesive visual strategy will pay off with repeat and long-term customer loyalty. If 
                    you wait around too long you may find that your competitors have remarkably similar branding to your own business. Take a 
                    long look at your objectives as a business and as audience and set some clear expectations about what you want to accomplish 
                    so you will be sure to stay on your toes and never appear out-of-date
                </p>
            </div>
            <Footer/>
        </div>
    )
}
export default QualityBrand;