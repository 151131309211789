import React from "react";
import Footer from "../../partial/footer";
import NavBar from "../../partial/navbar";
import './socail-marketing.css'

function SocialManagement(){
    return(
       <div>
           <NavBar/>
           <div class="infinite-page-wrapper" id="infinite-page-wrapper">
           <div class="infinite-blog-title-wrap  infinite-style-small">
                    <div class="infinite-header-transparent-substitute"></div>
                    <div class="infinite-blog-title-top-overlay"></div>
                    <div class="infinite-blog-title-overlay"></div>
                    <div class="infinite-blog-title-bottom-overlay"></div>
                    <div class="infinite-blog-title-container infinite-container">
                        <div class="infinite-blog-title-content infinite-item-pdlr">
                            <header class="infinite-single-article-head infinite-single-blog-title-style-1 clearfix">
                                <div class="infinite-single-article-date-wrapper post-date updated">
                                    <div class="infinite-single-article-date-day">28</div>
                                    <div class="infinite-single-article-date-month">March</div>
                                </div>
                                <div class="infinite-single-article-head-right">
                                    <h1 class="infinite-single-article-title">Social Media Management</h1>
                                    <div class="infinite-blog-info-wrapper">
                                        <div
                                            class="infinite-blog-info infinite-blog-info-font infinite-blog-info-author vcard author post-author ">
                                            <span class="infinite-head"><i class="icon_documents_alt"></i></span><span
                                                class="fn"><a href="" title="Posts by admin"
                                                    rel="author">admin</a></span></div>
                                        <div
                                            class="infinite-blog-info infinite-blog-info-font infinite-blog-info-category ">
                                            <span class="infinite-head"><i class="icon_folder-alt"></i></span><a
                                                href="/digital-marketing" rel="tag">Digital
                                                Media Services</a></div>
                                        <div
                                            class="infinite-blog-info infinite-blog-info-font infinite-blog-info-comment-number ">
                                            <span class="infinite-head"><i class="icon_comment_alt"></i></span>0 </div>
                                    </div>
                                </div>
                            </header>
                        </div>
                    </div>
                </div>
                </div>
           <div class="container">
               <p>
               Businesses increase their reach to specific audiences to promote their 
               brands and increase sales by using social media platforms strategically. Social media is used by more individuals to make purchases, 
               In Fact, <u>more than 3.78 billion people use social media globally, and 54% of those consumers utilize those platforms to conduct product 
               
               research through reviews and suggestions</u>.
                With the best social media management and marketing strategies, leading companies and marketing firms have many options to contact audiences. 
                Below are additional details on the distinctions between social media marketing and management and how marketing firms use them to expand 
                their client base.
            </p>

            <table class="table table-hover">
                
                <tr>
                    <th style={{marginRight:'50px'}}>Social Media Marketing </th>
                    <th>Social Media Management</th>
                </tr>
                <tbody style={{textAlign:'left'}}>
                <tr class="col-6">
                    <td>Social media marketing is about getting content in front of your target<br/> audience for a specific purpose	social media management is how you manage your online presence to create brand awareness</td>
                    <td>social media management is how you manage your online presence to create brand awareness</td>
                    
                </tr>
                <tr>
                    <td>generate leads</td>
                    <td>generate leads	building your brand identity</td>
                </tr>
                <tr>
                    <td> boost business sales</td>
                    <td>growing your audience</td>
                </tr>
                <tr>
                    <td>increase your followers count </td>
                    <td>engaging with them and trying to build relationships with them</td>
                </tr>
                </tbody>
            </table>

           </div>
           <Footer/>
       </div>
    )
}
export default SocialManagement;